import Enums from "./enums/enums";
import ServerErrors from "./errors/server-errors";

export default {
    translations: {

        /******** vuetify component translations *********/

        $vuetify: {
            noDataText: 'No data',
            dataFooter: {
                itemsPerPageAll: 'Show all',
                pageText: 'Navigate:'
            },
            dataTable: {
                sortBy: 'Sort by',
                ariaLabel: {
                    sortNone: 'sortNone'
                },
                itemsPerPageText: 'Per page:'
            },
            timepicker: {
                am: 'am',
                pm: 'pm'
            },
            dataIterator: {
                loadingText: 'Loading data'
            },
            badge: 'badge',
            datePicker: {
                prevMonthAriaLabel: 'Previous month',
                nextMonthAriaLabel: 'Next month'
            }
        },


        /******** translation-by-component *******/ //TODO - czy moze byc tak?

        view: {
            login: {
                footer: {
                    z1: 'Organizational regulations',
                    z2: 'Regulations for the provision of electronic services',
                    z3: 'Neurosphera Privacy Policy',
                    z4: 'Neuroterminal Privacy Policy'
                },
                login: {
                    loginTitle: 'Login and make an appointment',
                    registerLinkMsg: 'Register',
                    loginBtnLbl: 'Log in to Neurosphera',
                    resetPassMsg: 'Forgot password?',
                    passwordFieldLabel: 'Password',
                    loginEmailLabel: 'E-mail',
                    registerMsg: 'Don\'t have an account yet?'
                },
                reset: {
                    title: 'Forgot password?',
                    subTitle: 'To the address entered below we will send e-mail with link for setting new password',
                    content1: 'We have sent link for password change to:',
                    content2: 'Check your email inbox and click on the link for password chance',
                    form: {
                        email: 'E-mail',
                        send: 'Confirm'
                    },
                    changePassword: 'Change password',
                    recoverPassword: 'Recover password',
                    response1: 'A message has been sent to the given e-mail address with reset confirmation link.',
                    response2: 'Please close this browser window and click on the link received by email.',
                    sendAgainPrefix: "You have not received an email:",
                    sendAgain: "Send again!",
                    contactReception: "or contact reception:",
                    resendBlocked: "In case you have not received email with link for password change please contact customer service."
                },
                resetConfirm: {
                    title: 'Confirm password change',
                    form: {
                        password: 'New password',
                        passwordConfirm: 'Confirm password',
                        send: 'Confirm'
                    },
                    response: 'Password changed.',
                    loginBtnLbl: 'Log in'
                },
                register: {
                    title: "Welcome to Neuroterminal",
                    subtitle1:"Create an account and make an appointment",
                    firstName: "First Name",
                    lastName: "Last name",
                    email: "E-mail",
                    password: "Password",
                    passwordConfirm: "Confirm password",
                    selectAll: "Select all",
                    isApprove1: 'I have read the rules for the processing of my personal data set out in',
                    isApprove2: 'Neurosphera sp. z o.o. Privacy Policy',
                    isApprove3: 'and',
                    isApprove4: 'Neuroterminal S.A. Privacy Policy',
                    maptitle: "How Neuroterminal works?",
                    mapSubtitle: "It's very simple",
                    cards: [
                        "Fill out the registration form to create account in Neurotermianl aplication.",
                        "Receive an activation email to confirm your credentials",
                        "Log into Neuroterminal and fill out the information in the patient profile.",
                        "Choose the doctor and date of the first visit (visit ZERO)",
                        "You will pay for it with ease using PayU (bank transfer, BLIK or credit card)",
                        "For your convenience we will remind you about your visits by sending you SMS",
                        "Each subsequent visit, no matter if it's remote or stationary, you will do it directly from Neuroterminal."
                    ],
                    passwordRequirement: {
                        length: "8 signs",
                        upperCase: "upper case",
                        lowerCase: "lower case",
                        digit: "digit",
                        sign: "special sign ( ! @ $ \\ - _ . * )"
                    },
                    resendMessage: 'Activation link has been sent again'
                },
                confirm: {
                    registerSuccessMsg: 'Registration finished successfully',
                    passwordChangeSuccessMsg: 'Password changed successfully',
                    goToLoginMsg: 'Click the button below to log in to Neuroterminal, portal for patients of Neurosphera',
                    outcomeFailureMsg: 'There was a problem with registration confirmation',
                    outcomeFailureDetMsg: 'Please try again clicking the link in the email or contact the Neuroterminal team',
                    gotTologinBtn: 'Go to log in page',
                    passwordChangeFailedMsg: 'The link to set your password has expired'
                }
            },

            admin: {
                settings: {
                    ewusPasswordChanged: 'EWUŚ password changed',
                    save: 'Save',
                    ewusPasswordTitle: 'EWUŚ password change',
                    ewusPasswordLabel: 'New password',
                    removeAccount: 'Remove account',
                    registrationDate: 'Registration date',
                    registrationSource: 'Registered by',
                    registeredBy: {
                        PATIENT: "Patient",
                        DOCPLANNER: "Znany lekarz",
                        APPOINTING_WIDGET: "Widget"
                    },
                    deleteAccount: "Remove account",
                    accountNotForDeletion: "This account cannot be removed",
                    accountDeletionConfirmation: "Are you sure you want to remove this account?",
                    yes: "Yes",
                    no: "No",
                    accountRemovedMsg: "Patient's account has been removed"
                },
                notes: {
                    title: 'Notes',
                    current: 'Current',
                    archive: 'Archive',
                    mine: 'Mine'
                },
                noteDetails: {
                    added: "Creation time",
                    patient: "Patient",
                    pesel: "Pesel",
                    address: "Address",
                    phone: "Phone",
                    content: "Content",
                    sharedBy: "Shared by",
                    moveToArchive: "Move to archive",
                    save: "Save",
                    reply: "Reply",
                    initialNote: "Doctor's note",
                    yes: "Yes",
                    no: "No",
                    addedFiles: "Added files"
                },
                reassignment: {
                    noReplacementAvailable: "No alternative doctor at the same price",
                    backToVisitToMoveTable: "Back to the list of appointments to be replaced",
                    visitNotFoundRedirection: "Nie znaleziono wizyty, powrócisz do strony głównej",
                    reassignmentSummary: "Summary",
                    visitNotToMove: "The visit cannot be moved because its status has already been updated.",
                    title: "Doctor change",
                    currentVisitDate: "Visit date",
                    currentVisitHeader: "Change for visit",
                    currentVisitFromPackageHeader: "Change for visit from package",
                    additionalInfo: "Additional information",
                    upcomingVisit: "Upcoming patient visit",
                    patient: "Patient",
                    doctor: "Doctor",
                    cannotReassignDueToRelatedVisit: "The visit may not be reassigned due to relation to another visit that has already being done"
                },
                patients: {
                    selectPatient: "Select patient",
                    name: "Name",
                    email: "Email",
                    pesel: "Pesel"
                },
                certificates: {
                    issuingDoctor: "Doctor issuing certificate",
                    changeLbl: "Change",
                    selectDoctorLbl: "Select doctor",
                    hide: "Hide"
                }
            },
            visits: {
                DoctorVisits: {
                    titleLbl: 'My visits',
                    returnLbl: 'return',
                    noDataLbl: 'No visits',
                    noResultsLbl: 'No visits found',
                    filters: {
                        visitTime: {
                            all: 'All',
                            future: 'Future',
                            past: 'Past'
                        },
                        searchPatientLbl: 'Search for patient',
                        advancedFiltersLbl: 'Advanced search',
                        advancedFilters: {
                            from: 'Date from',
                            betweenDatesLbl: 'do',
                            till: 'Date till',
                            type: 'Visit type',
                            typeAll: 'All',
                            clearBtn: 'clear',
                            searchBtn: 'search'
                        }
                    },
                    table: {
                        headers: {
                            state: 'State',
                            lastName: 'Last name',
                            firstName: 'First name',
                            pesel: 'PESEL',
                            age: 'Age',
                            visitType: 'Visit type',
                            visitCategory: 'Category',
                            visitStatus: 'Status',
                            dateFrom: 'Visit date',
                            sinceLast: 'Since last'
                        },
                        actions: {
                            summary: 'Visit summary',
                            start: 'Visit'
                        }
                    }
                }
            },
            patients: {
                DoctorPatients: {
                    titleLbl: 'Patients',
                    returnLbl: 'return',
                    noDataLbl: 'No patients',
                    noResultsLbl: 'No visits found',
                    filters: {
                        tabs: {
                            personal: 'Personal data',
                            address: 'Address details',
                            biomedic: 'Biomedical data',
                            medic: 'Medical data'
                        },
                        scope: {
                            all: 'All patients',
                            my: 'My patients'
                        },
                        search: 'Search',
                        advancedFiltersLbl: 'Advanced search',
                        from: 'from',
                        birthDate: 'Date of birth',
                        till: 'to',
                        clearBtn: 'clear',
                        searchBtn: 'search'
                    },
                    table: {
                        headers: {
                            lastName: 'Last name',
                            firstName: 'First name',
                            medicalHistory: 'Medical history',
                            pesel: 'PESEL',
                            email: 'Email',
                            age: 'Age',
                            sex: 'Sex',
                            leadDoctor: 'Lead doctor',
                            personalData: 'Personal data',
                            phone: 'Phone number',
                            city: 'City',
                            zipCode: 'Zip code',
                            country: 'Country',
                            bloodType: 'Blood type',
                            rh: 'Rh',
                            weight: 'Weight',
                            height: 'Height',
                            hasEpilepsy: 'Has epilepsy?',
                            mainIcd10: 'Main diagnosis',
                            comorbidities: 'Comorbidities',
                            vns: 'Implanted VNS',
                            activeSubstance: 'Active substance',
                            product: 'Medicine',
                            therapyType: 'Therapy type'
                        }
                    }
                }
            },
            leadDoctor: {
                noDoctor: 'The patient has no lead doctor',
                change: 'Change',
                choose: 'Choose doctor',
                dialogTitle: 'Choose lead doctor for the patient',
                genericError: 'Error has occurred',
                assign: 'Assign doctor',
                patientsNumber: 'Number of patients',
                assignNoteTitle: "You have chosen {name} as lead doctor for patient. The selected doctor will receive following note",
                assignNoteContent: "Doctor {name} assigned the patient to your care. Review the patient's medical history.",
                assignNoteComment: "If you want you can add an additional message. If you add it, the note will also be shared with the admin",
                assignNoteDialogTitle: "Note for selected doctor"
            },
            visit: {
                Visit: {
                    titleLbl: 'Visit',
                    returnLbl: 'return',
                    printLbl: 'print',
                    survey: 'Survey 0',
                    documentation: 'Visit documentation',
                    visitDocumentation: 'Documentation from visit',
                    epicrisis: 'Epicrisis',
                    calendar: 'Event calendar',
                    eDiagnosisBtn: 'e-diagnosis',
                    eTherapyBtn: 'e-therapy',
                    medHistoryBtn: 'medical history',
                    messHistoryBtn: 'Messages',
                    notesBtn: 'notes',
                    observations: 'Observations',
                    additionalObservations: 'Additional observations',
                    noteZeroObservations: 'Note 0 observations',
                    editNoteZeroObservations: 'Edit note 0 observations',
                    saveObservationLbl: 'Save note 0 observations',
                    notMandatoryLbl: 'not mandatory field',
                    observationsPlaceholder: 'Observations fill in automatically when filling in visit section',
                    additionalObservationsPlaceholder: 'Fill in additional observations',
                    addObservationLbl: 'Save observations',
                    diagnosis: 'diagnosis (comorbidities)',
                    seizureDiagnosis: 'diagnosis (seizures)',
                    medication: 'medication',
                    recommendations: 'recommendations',
                    prescriptionBtn: 'prescription',
                    certificateBtn: 'certificate',
                    referralBtn: 'referral',
                    changeTherapyBtn: 'change therapy',
                    cancel: 'cancel',
                    save: 'save',
                    end: 'finish',
                    downloadTestRecommendations: 'Download trial recommendations',
                    leadDoctorHeader: 'Lead doctor',
                    visitLeaveConfirmMsg: 'Are you sure you want to leave the visit?',
                    visitSaveConfirmMsg: 'Are you sure you want to save and leave the visit?',
                    visitEndConfirmMsg: 'Do you want to end the visit? After ending it any further changes to it will not be possible',
                    dataSavedMsg: 'Visit data saved',
                    visitEndedMsg: 'Visit was ended',
                    dataSaveErrorMsg: 'Error while saving visit data',
                    messageHistoryEmpty: 'There are no messages with this patient',
                    drugsImported: 'Drug therapies have been initialized',
                    drugTherapiesInEditionMode: 'Drug therapies are in edition mode. Visit cannot be saved.',
                    emptyRecommendations: 'Recomendations are not provided. Visit cannot be ended.',
                    absentConsultationInfo: 'Absent consultation takes place without the participation of the patient',
                    mainDoctor: 'Main doctor',
                    supervisors: 'Supervisors',
                    referrals: {
                        title: 'referrals',
                        removeDialogTitle: 'Are you sure you want to remove this referral?',
                        addReferral: 'Referral',
                        addDialogTitle: 'New referral',
                        noAvailableServices: 'There are no available services for this patient',
                        consultationService: 'Consultation type',
                        doctor: 'Doctor',
                        description: 'Description',
                        cancel: 'Cancel',
                        empty: 'No referral issued for this visit',
                        scheduled1Month: '1 month',
                        scheduled2Months: '2 months',
                        scheduled3Months: '3 months',
                        scheduled6Months: '6 months',
                        scheduled1Year: '1 year',
                        scheduledDate: 'Scheduled date',
                        doctorPackage: 'Doctor package',
                        doctorPackageQuestion: 'Referral for doctor package?',
                        availableFree: {
                            title: 'You have not issued referrals for free visits',
                            subtitle: 'After this visit, the patient is entitled to 1 free visit for one of the services',
                            subtitle2: 'Go back to your care plan and issue the appropriate referral!',
                            back: 'Return to the visit to issue a referral',
                            endWithoutReferral: 'Finish without referral',
                            visitDescription: 'After this visit, the patient is entitled to a free visit to following services',
                            visitAddTitle: 'referral for a free visit'
                        }
                    },
                    prescriptions: {
                        title: 'prescriptions',
                        addPrescription: 'Prescription',
                        empty: 'No prescription issued for this visit'
                    },
                    certificates: {
                        title: 'certificates',
                        addCertificate: 'Certificate',
                        empty: 'No certificate issued for this visit'
                    },
                    ended: {
                        titleLbl: 'Teleconference has ended',
                        questionMsg: 'Would you like to end this visit and return to the main screen?',
                        confirmationMsg: 'Thank you for attending the visit'
                    },
                    saving: {
                        warningMsg: 'Visit will save automatically in {no} minutes',
                        autoSaveMsg: 'Visit has been automatically saved. Please end visit immediately'
                    },
                    icd10: {
                        title: 'diagnosis'
                    },
                    leaveDialog: {
                        leave: 'Leave without save',
                        saveAndLeave: 'Save and leave',
                        endAndLeave: 'End and leave'
                    },
                    wrongDataDialog: {
                        title: 'Your data cannot be saved at this moment.',
                        description: 'Please contact Neurosphera Epilepsy Therapy Center\'s customer office.'
                    },
                    documentationTabs: {
                        visitSurvey: 'Visit survey',
                        therapyArrangement: 'Therapy arrangement',
                        healthCare: 'Care plan',
                        recommendations: 'Recommendations',
                        certificates: 'Certificates',
                        appoint: 'appoint',
                        skip: 'do not appoint',
                        referralsSaved: 'Referrals has been saved',
                        carePlan: {
                            addNext: 'Add next',
                            remove: 'Remove',
                            loading: 'Loading data...',
                            referralListEmpty: 'Referral list is empty',
                            message: {
                                name: 'Message to the doctor',
                                title: 'message visit - making an appointment',
                                date: 'Visit date'
                            },
                            epilepsy: {
                                name: 'Epilepsy visit',
                                title: 'next epileptic visit - making an appointment',
                                date: 'Visit date'
                            },
                            eeg: {
                                name: 'EEG examination',
                                title: 'EEG examination - making an appointment',
                                date: 'Eeg date',
                                service: 'Eeg type'
                            },
                            consultation: {
                                name: 'Consultation',
                                title: 'NSPH consultation - making an appointment',
                                date: 'Consultation date',
                                service: 'Consultation type'
                            },
                            other: {
                                name: 'Other',
                                title: 'External referrals',
                                emptyList: 'The list of issued external referrals for this visit is empty',
                                addNextEReferral: 'Add e-referral',
                                isEReferral: 'E-referral',
                                referralCode: 'Referral code',
                                noDataLbl: 'Referral list is empty'
                            }
                        }
                    },
                    recommendationTemplate: {
                        title: 'recommendation template',
                        examinationNames: 'Examination names',
                        template: {
                            therapy: 'Drugs according to the scheme in drug therapy and on the patient\'s account in the Neuroterminal system in the therapy tab',
                            urgentContact: 'In case of urgent contact with a doctor or unforeseen circumstances, please call the reception desk +48 609 463 191',
                            noTherapy: 'Without pharmacological treatment',
                            calendar: 'Please maintain a calendar of events on the patient portal in the Neuroterminal system',
                            headMr: 'Please do an MRI of the head',
                            medicalExaminations: 'Please perform additional tests and attach the results to the medical documentation in the Neuroterminal system'
                        }
                    }
                },
                ictal: {
                    diagnosis: {
                        title: 'Dear Madam, dear Sir',
                        subtitle1: 'Remember that our',
                        subtitle2: 'AI robot – Ictal®',
                        subtitle3: '- is a innovative tool, that will help you and will constantly learn to aid you even better, but',
                        subtitle4: 'it is not a medical device and cannot be used to assess the real clinical conditions of your patients.',
                        subtitle5: 'The entire epilepsy ontology library has been developed to accurately recognize the type of epileptic seizure according to the latest MLPP classification, so the tool can be used to assess hypothetical clinical conditions for educational or training purposes.',
                        suggestedDiagnoseMsg: 'Potential diagnosis for',
                        fillSurveyMsg: 'No seizures defined yet. Fill in the Survey 0',
                        ictalDiagnose: 'Diagnose with Ictall',
                        ictalDiagnoseOneMoreTime: 'Diagnose one more time',
                        patientSeizureType: 'Selected seizure type (defined by patient)'
                    },
                    outcome: {
                        approveLbl: 'I agree',
                        rejectLbl: 'I do not agree',
                        saveLbl: 'Save diagnosis',
                        fixCritical: 'Fix critical questions',
                        reasonLbl: 'Reason for choosing this diagnosis',
                        selectDiagnosisMsg: 'Select diagnosis',
                        calcDiagnosisMsg: 'Generating diagnosis..',
                        otherDiagnosis1Lbl: 'Other',
                        otherDiagnosis2Lbl: 'diagnosis',
                        updatedByLbl: 'According to the diagnosis of'
                    },
                    therapy: {
                        title: 'Dear Madam, dear Sir',
                        subtitle1: 'Remember that our',
                        subtitle2: 'AI robot – Ictal®',
                        subtitle3: '- is a innovative tool, that will help you and will constantly learn to aid you even better, but',
                        subtitle4: 'it is not a medical device and cannot be used to assess the real clinical conditions of your patients.',
                        subtitle5: 'The entire epilepsy therapy process was developed by a team of experts and based on the guidelines of the leading Medical Scientific Societies dealing with epilepsy, so the tool can be used to assess hypothetical clinical conditions for educational or training purposes.',
                        ictalCheck: 'Check',
                        seizureType: 'Seizure type',
                        proposal: 'Possible therapy options',
                        type: {
                            0: 'ICTAL suggestion',
                            1: 'I Monotherapy',
                            2: 'II Monotherapy',
                            3: 'III Monotherapy',
                            4: 'I Politherapy',
                            5: 'II Politherapy',
                            6: 'III Politherapy',
                            7: 'Further Politherapy: '
                        },
                        drug: {
                            description: {
                                lastUsed: 'Last used:',
                                color: {
                                    undefined: 'The drug has not been used before. ',
                                    orange: 'The drug has been used before: no effect / weak effect.',
                                    red: 'The drug was used before: negative effect, the patient\'s condition worsened.',
                                    green: 'The drug was used before: satisfactory effect.'
                                }
                            }
                        },
                        filter: {
                            availableInPL: 'Drug available in Poland',
                            refundedInPL: 'Drug reimbursed in Poland',
                            reproductiveAgeOptional: 'Reproductive age'
                        },
                        notFound: 'ICTAL has failed to tailor treatments for the patient.',
                        apply: 'Apply therapy'
                    }
                },
                doctorNotes: {
                    title: 'Notes',
                    subtitle: 'It is a place where you can store your private notes about this patient',
                    addNote: 'Add note',
                    save: 'Save changes',
                    saveSuccess: 'Note has been saved',
                    modified: 'Last modified',
                    editCancel: 'Discard changes',
                    edit: 'Edit note',
                    delete: 'Delete note',
                    askDelete: 'Are you sure you want to delete this note?',
                    deleteSuccess: 'Note has been deleted',
                    publicVisible: 'Visible to all doctors',
                    limitedShare: 'Visible to selected doctors',
                    sharedBy: 'Shared by',
                    forAdmin: 'Visible for admin',
                    repliedTo: 'In reply to',
                    sharedByPatient: "Shared by patient for absent consultation",
                    absentConsultation: "Absent consultation",
                    addFiles: "Add file"
                },
                diagnosis: {
                    confirmTitle: 'Diagnosis change',
                    confirmText: 'Are you sure that you wan\'t to change the diagnosis?',
                    updatedMsg: 'Updated diagnosis for this seizure type'
                },
                patientMessageHistory: {
                    title: 'Patient messages history',
                    emptyList: 'Patient messages history is empty'
                }
            },
            landing: {
                common: {
                    menu: {
                        observe: 'Observe us',
                        link: {
                            database: 'NT - Database',
                            doctors: 'For doctors',
                            contact: 'Contact',
                            login: 'Log in',
                            register: 'Register'
                        },
                        terms: {
                            title: 'Terms of use',
                            z1: 'Organizational regulations',
                            z2: 'Regulations for the provision of electronic services',
                            z3: 'Neurosphera Privacy Policy',
                            z4: 'Neuroterminal Privacy Policy',
                            payuInstallments: 'PayU Installments - FAQ'
                        }
                    },
                    contact: {
                        title: 'Contact us',
                        media: {
                            visit: 'Visit us'
                        },
                        dataTitle: 'Contact us',
                        formTitle: 'Contact form',
                        form: {
                            email: 'Send an e-mail',
                            phone: 'Call us',
                            message: "Message",
                            info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in',
                            send: 'Send Message',
                            ApproveDataProcessing: 'I agree voluntarily to have my personal data processed by Neuroterminal S.A. in order to service my application. Detailed information about the principles of personal data protection and your rights can be found in',
                            policy: 'Privacy policy',
                            feedback: {
                                header: 'Thank you for sending us your message!',
                                content: 'We will contact you shortly\nby phone or e-mail'
                            }
                        }
                    },
                    help: {
                        title: 'Got questions?'
                    }
                },
                main: {
                    title1: 'Modern epilepsy',
                    title2: 'treatment system',
                    bullet1: 'Convenient telemedicine consultations\nfrom 99 PLN',
                    bullet2: 'Access to the best\nepileptologists',
                    bullet3: 'Comprehensive medical care\nwithout leaving home',
                    arrangeConsultation: 'Make an appointment',
                    features: {
                        consultations: {
                            title: 'Telemedicine\nconsultations',
                            description: 'Conduct online visits\nvia video conferencing'
                        },
                        emergency: {
                            title: 'Emergency\ncases',
                            description: '24 hour SOS call\nwith doctors'
                        },
                        panel: {
                            title: 'Patient\npanel',
                            description: 'Medical history,\nmedical documents\nand recommendations\nin one place'
                        },
                        edocuments: {
                            title: 'Electronic prescriptions,\nsick leaves',
                            description: 'and referrals\nwithout leaving home'
                        },
                        doctors: {
                            title: '\nDoctors',
                            description: 'Access to the best epileptologists\nwithout queues'
                        },
                        quickAccess: {
                            title: 'Quick\naccess',
                            description: 'Consultations with doctors\nwithout queues'
                        }
                    },
                    subHeaderBullet1: 'Stay in touch with your doctor,\nwho uses the artificial intelligence module\nto monitor the effectiveness of your treatment.',
                    subHeaderBullet2: 'Get access to the best neurologists in Poland\nwithout leaving home.',
                    subHeaderBullet3: 'Join the Neuroterminal for free\nand start using innovative solutions\nto treat epilepsy!',
                    howItWorks: 'How it works?',
                    steps: {
                        choose: {
                            title: 'Patient choose\na doctor',
                            description: 'from a database of specialists'
                        },
                        appoint: {
                            title: 'Makes\nan appointment',
                            description: 'for a telemedicine visit\nat a convenient time'
                        },
                        diagnose: {
                            title: 'The doctor diagnoses',
                            description: 'and chooses therapy.\nThe iCtal artificial intelligence system\nhelps him with this'
                        },
                        documents: {
                            title: 'Adds medical\ndocuments',
                            description: ''
                        },
                        contact: {
                            title: 'Doctor stays\nin touch',
                            description: 'with patient,\nregularly monitors the progress\nof the implemented treatment'
                        }
                    },
                    opinions: 'Users\' opinions',
                    register: 'Create an account to make an appointment'
                },
                database: {
                    epilepsy: {
                        title: 'What is epilepsy?',
                        subheader1: 'Epilepsy is a medical condition\ncharacterized by recurrent seizures.',
                        subheader2: 'Seizure is the result\nof a temporarily changed\nbrain function\ncaused by\nabnormal or excessive\nelectrical discharges\ninside the brain cells.'
                    },
                    seizure: {
                        title: 'How does seizure\nlook like?',
                        subheader: 'Seizure symptoms can vary widely.',
                        bullet1: 'Some people with epilepsy\nsimply \'lose contact\'\nfor a few seconds during a seizure',
                        bullet2: 'Other people fall to the floor\nwith convulsions of the limbs',
                        footer: 'Classification of the type of seizure is very important\nand helps the doctor plan the treatment needed.\nA single seizure does not mean that you have epilepsy.'
                    },
                    factors: {
                        title: 'Provoking factors',
                        subheader: 'Seizures can also be caused\nby known and reversible factors,\nsuch as',
                        bullet1: 'No sleep',
                        bullet2: 'Alcohol\nwithdrawal',
                        bullet3: 'Low sugar',
                        seizureRecognition: 'At least two unprovoked seizures\nare usually required to diagnose epilepsy.',
                        seizuresRelatedTo: 'Seizures can be associated with',
                        relatedToBullet1: 'Brain damage',
                        relatedToBullet2: 'Family tendency',
                        relatedToBullet3: 'Often their cause\nis completely unknown'
                    },
                    whoCanSick: {
                        title: 'Who can get sick?',
                        subheader: 'Epilepsy can affect anyone,\nregardless of gender and age.',
                        bullet1: 'Most seizures occur in children\nup to 1 year of age,\nwhile older people usually have an increase\nin the incidence after 65 years.',
                        bullet2: 'It is estimated that up to 1% of people\nsuffer from epilepsy (about 400,000 people in Poland).'
                    },
                    treatment: {
                        title: 'How is epilepsy treated?',
                        bullet1: 'Drugs can effectively control seizures\nin approximately 70%\nof patients with epilepsy',
                        bullet1Header: 'Medicines',
                        bullet2: 'For patients with uncontrolled epilepsy,\nneurosurgery can provide a "cure"\nby eliminating the source\nof seizures and epilepsy.',
                        bullet2Header: 'Surgery',
                        footer: 'Drug treatment or sometimes surgery\ncan control seizures in most people with epilepsy.\nSome people require lifelong treatment to control seizures,\nbut others will eventually stop. Some children with epilepsy\nmay grow out of the seizures with age.'
                    },
                    impact: {
                        title: 'How epilepsy affects\neveryday life?',
                        subheader: 'Seizures and epilepsy can affect',
                        bullet1: 'Safety',
                        bullet2: 'Relations',
                        bullet3: 'Job',
                        bullet4: 'Driving a car',
                        bullet5: 'And much much more',
                        footer: 'Public perception and treatment of people with epilepsy\nare often bigger problems than actual seizures.'
                    },
                    help: {
                        title: 'The neuroterminal will\nhelp you heal epilepsy',
                        bullet1Header: 'Comfort',
                        bullet1: 'You will arrange\na consultation with a doctor\nwithout leaving home.',
                        bullet2Header: 'Innovation',
                        bullet2: 'We use artificial\nintelligence systems\nand modern analytical tools\nto treat epilepsy.',
                        bullet3Header: 'Safety',
                        bullet3: 'You will gain access\nto a 24-hour neurological shift\nwhich will provide you with\nimmediate help.',
                        makeAnAppointment: 'Make an appointment'
                    }
                },
                doctors: {
                    title1: 'Become a doctor',
                    titleIn: 'in',
                    title2: 'NEUROTERMINAL',
                    subtitle: 'Consult patients online\nusing the latest\ntechnologies.',
                    register: 'Sign up',
                    features: {
                        consultations: {
                            title: 'Telemedicine\nconsultations',
                            description: 'Conduct visits online\nthrough video conferencing\nor chat'
                        },
                        digitalOffice: {
                            title: 'Digital\noffice',
                            description: 'All visits and medical data\nof your patients\nin one place'
                        },
                        eDocuments: {
                            title: '\nE-documents',
                            description: 'Create prescriptions,\nsick leaves and referrals\nonline'
                        },
                        flexibleSchedule: {
                            title: 'Flexible\nschedule',
                            description: 'Schedule consultations\nat dates convenient for you\n7 days a week'
                        },
                        system: {
                            title: 'System\nNeuroterminal',
                            description: 'Use an innovative\nartificial intelligence system\nto diagnose patients'
                        },
                        patients: {
                            title: '\nPatients',
                            description: 'Lead your patients\nand quickly gain new ones'
                        }
                    },
                    steps: {
                        register: {
                            title: 'Sign up',
                            description: ''
                        },
                        training: {
                            title: 'Go through training',
                            description: 'how to use the Neuroterminal\nplatform and conduct\ntelemedicine consultations'
                        },
                        schedule: {
                            title: 'Set consultations\nschedule',
                            description: 'freely planning\nyour availability'
                        },
                        pricing: {
                            title: 'Specify the price list',
                            description: 'your pay\nfor consultation'
                        },
                        consultations: {
                            title: 'Consult',
                            description: 'telemedicine online through\nthe Neuroterminal platform\nvia video or chat'
                        },
                        monitor: {
                            title: 'Monitor\npatients',
                            description: 'and their treatment progress'
                        }
                    },
                    firstSteps: 'First steps'
                },
                patients: {},
                removePatient: {
                    title: 'Confirm account removal',
                    subTitle1: 'Account registered at',
                    subTitle2: 'will be removed',
                    why: 'Why do you want to remove the account',
                    whyPrompt: 'Your answer is very important to us. It will help us to improve our service.',
                    removeBtn: 'Remove your account from Neuroterminal',
                    accountRemoved: 'Your account has been removed',
                    noAccount: "The account does not exists or cannot be removed",
                    accountRemovedDescription: 'Remember you can register at Neuroterminal and book a visit in Neurosphera at any time.'
                }
            },
            ictal: {
                lab: {
                    title: 'IctalLab',
                    subtitle: 'Enter vector or load patient to start automatic diagnosis.',
                    diagnose: 'Diagnose',
                    clear: 'Clear',
                    inputVector: 'Input vector',
                    loadPatient: 'Select patient',
                    changePatient: 'Change patient',
                    selectedPatient: 'Selected patient',
                    survey: 'Survey',
                    diagnosis: 'Diagnosis',
                    factorsConfiguration: 'Factors configuration',
                    sum: 'Sum',
                    therapy: 'Therapy',
                    check: 'Check',
                    selectTherapy: 'Select therapy type',
                    selectedTherapy: 'Selected therapy type',
                    selectSeizureType: 'Select seizures types',
                    selectedSeizureType: 'Selected seizures types',
                    drugAvailableInPl: 'Drug available in Poland',
                    drugRefunded: 'Drug Refunded',
                    patientIsPregnant: 'Patient is pregnant',
                    patientWithSomaticDiseases: 'Patient with somatic diseases',
                    patientInOlderAge: 'Patient in older age',
                    patientOfChildbearingAge: 'Patient of childbearing age',
                    patientAge: 'Patient age',
                    recommendedTherapy: 'Recommended therapy',
                    errors: {
                        factorsError: 'The sum of the coefficients should be 100',
                        vectorLengthError: 'Incorrect input vector length',
                        vectorInvalidError: 'Incorrect input vector'
                    },
                    factors: {
                        nn: 'NN',
                        knn: 'KNN',
                        jacc: 'Jaccard',
                        jaro: 'Jaro',
                        sodi: 'Sørensen–Dice',
                        over: 'Overlap',
                        tver: 'Tversky: α=1, β=1'
                    },
                    surveyHeaders: {
                        name: 'Question',
                        always: 'Always',
                        veryOften: 'Very often',
                        often: 'Often',
                        sometimes: 'Sometimes',
                        rarely: 'Rarely',
                        veryRarely: 'Very rarely',
                        never: 'Never',
                        veryLong: 'Very long',
                        long: 'Long',
                        bitLong: 'Bit long',
                        variable: 'Variable',
                        short: 'Short',
                        veryShort: 'Very short',
                        ultraShort: 'Ultra short'
                    },
                    seizuresTableHeaders: {
                        seizureType: 'Seizure type',
                        probability: 'Probability [ % ]'
                    },
                    selectUserDialog: {
                        title: 'Select patient',
                        noPatientsFound: 'No patients found.'
                    },
                    configuration: {
                        subtitle: 'Configuration',
                        drugCatalog: {
                            title: 'Drug catalog',
                            add: 'Add new drug'
                        },
                        factors: {
                            save: 'Save factors',
                            coefModifiedMsg: 'Coefficients saved'
                        }
                    },
                    drugs: {
                        deleteConfirmationDialogText: "Deleting the medication will also delete the Ictal engine training data. All data associated with this medication will be deleted. Do you wish to continue?",
                        updateDrugDialogTitle: "Update drug",
                        createDrugDialogTitle: "Add drug",
                        save: "Save",
                        cancel: "Cancel",
                        validation: {
                            minAgeRequired: "Min age is required",
                            minAgeRange: "Min age must be an number in the range 0 - 120",
                            weightRequired: "Weight is required",
                            weightRange: "Weight must be an number in the range -3 - 3"
                        },
                        legend: {
                            title: "Drugs - legend",
                            column: "Column",
                            value: "Value",
                            thrAbove: "Age threshold reached; age above the set threshold",
                            thrBelow: "Age threshold not reached; age below the set threshold",
                            drugAvailable: "Drug available in PL",
                            drugNotAvailable: "Drug not available in PL",
                            drugRefunded: "Drug refunded in PL",
                            drugNotRefunded: "Drug not refunded in PL",
                            usageForbidden: "Forbidden",
                            usageVeryRare: "Very rarely used",
                            usageRare: "Rarely used",
                            usageAmbivalent: "Ambivalent",
                            usageSometimes: "Sometimes used",
                            usageOften: "Often used",
                            usageRecommended: "Recommended"
                        }
                    },
                    usedDrugs: 'Drugs to be filtered out (implied - extreme deterioration)',
                    drugProposal: 'Drug proposal',
                    wageSum: 'Wage sum (higher - better)',
                    patientSex: {
                        label: 'Sex',
                        type: {
                            MALE: 'Male',
                            FEMALE: 'Female'
                        }
                    },
                    experts: {
                        titleLbl: 'Experts doctors',
                        addExpertMsg: 'Add expert',
                        editExpertMsg: 'Edit expert',
                        deleteExpertTitleMsg: 'Remove expert',
                        deleteExpertDescMsg: 'Removing the expert will result in the removal of all Ictal engine training data associated with this expert. Do you want to continue?',
                        saveMsg: 'Experts data modified',
                        save: "Save",
                        cancel: "Cancel",
                        validation: {
                            weightRange: "Weight must be an number in the range 0 - 1"
                        }
                    },
                    recommendations: {
                        titleLbl: 'Drugs recommended by experts',
                        addRecommendationLbl: 'Add recommendation',
                        saveMsg: 'Drug recommendation vector saved',
                        save: "Save",
                        cancel: "Cancel",
                        dialog: {
                            titleLbl: 'Recommended drugs',
                            expertLbl: 'Expert',
                            therapyLbl: 'Therapy'
                        }
                    },
                    epilepsies: {
                        titleLbl: 'Epilepsy types',
                        addMsg: 'Add epilepsy type',
                        editMsg: 'Edit epilepsy type',
                        deleteTitleMsg: 'Remove epilepsy type',
                        deleteDescMsg: 'Removing the epilepsy type will result in the removal of all Ictal engine training data associated with this epilepsy type. Do you want to continue?',
                        saveMsg: 'Epilepsy types data modified',
                        save: "Save",
                        cancel: "Cancel"
                    },
                    ediagnosis: {
                        titleLbl: 'E-Diagnosis',
                        headers: {
                            eventsTrailer: 'Long before seizure (hrs, days)',
                            eventsBefore: 'Just before seizure (seconds)',
                            eventsStart: 'Seizure beginning - first symptom',
                            eventsNext: 'Next seizure symptoms',
                            eventsEnd: 'End of seizure',
                            eventsAfter: 'After the seizure',
                            eventsFeature: 'Overall seizure characteristic'
                        },
                        dialog: {
                            titleLbl: 'E-Diagnosis vector',
                            epilepsyLbl: 'Epilepsy type',
                            save: "Save",
                            cancel: "Cancel",
                            saveMsg: 'E-Diagnosis vector modified'
                        },
                        events: {
                            trailerPrecise: 'Prediction precise (trailing)',
                            trailerUnspecified: 'Prediction unspecified (trailing)',
                            beforeAura: 'Aura (before)',
                            beforeSpecificStimuli: 'Specific stimuli (before)',
                            beforeNystagmus: 'Nystagmus (before)',
                            beforeImmobility: 'Immobility (before)',
                            startFaint: 'Fainting (start)',
                            startLossConsciousness: 'Loss of consciousness (start)',
                            startBodyConvulsions: 'Body convulsions (start)',
                            startBodyPartConvulsions: 'Body part convulsions (start)',
                            startFaceConvulsions: 'Face convulsions (start)',
                            startBodyStiffness: 'Body stiffness (start)',
                            startLimbEjection: 'Limb ejection (start)',
                            startEyeMovementDisorders: 'Eye movement disorders (start)',
                            startEyeTurnPernament: 'Eye turn permanent (start)',
                            startBodyTurn: 'Body turn (start)',
                            startGroundFall: 'Ground fall (start)',
                            startBitTongue: 'Biting tongue (start)',
                            startShout: 'Shouting (start)',
                            startSounds: 'Sounds (start)',
                            startMotorAutomatisms: 'Motor automatism (start)',
                            startImmobility: 'Immobility (start)',
                            startLaugh: 'Laughing (start)',
                            startChuck: 'Chucking (start)',
                            startCry: 'Crying (start)',
                            startEpigastricSymptoms: 'Epigastric symptoms (start)',
                            startDejaVu: 'Deja vu (start)',
                            startVisualHallucinations: 'Visual hallucinations (start)',
                            startAuditoryHallucinations: 'Auditory hallucinations (start)',
                            startEyelidsMyoclonus: 'Eyelids myoclonus (start)',
                            startMyoclonus: 'Myoclonus (start)',
                            nextFaint: 'Fainting (next)',
                            nextLossConsciousness: 'Loss of consciousness (next)',
                            nextBodyConvulsions: 'Body convulsions (next)',
                            nextBodyPartConvulsions: 'Body part convulsions (next)',
                            nextFaceConvulsions: 'Face convulsions (next)',
                            nextBodyStiffness: 'Body stiffness (next)',
                            nextEyeMovementDisorders: 'Eye movement disorders (next)',
                            nextEyeTurnPernament: 'Eye turn permanent (next)',
                            nextBodyTurn: 'Body turn (next)',
                            nextGroundFall: 'Ground fall (next)',
                            nextBitTongue: 'Biting tongue (next)',
                            nextUrinating: 'Urination (next)',
                            nextDefecation: 'Defecation (next)',
                            nextShout: 'Shouting (next)',
                            nextSounds: 'Sounds (next)',
                            nextAggression: 'Aggression (next)',
                            nextMotorAutomatisms: 'Motor automatism (next)',
                            nextImmobility: 'Immobility (next)',
                            nextLaugh: 'Laughing (next)',
                            nextChuck: 'Chucking (next)',
                            nextCry: 'Crying (next)',
                            nextEpigastricSymptoms: 'Epigastric symptoms (next)',
                            nextDejaVu: 'Deja vu (next)',
                            nextVisualHallucinations: 'Visual hallucinations (next)',
                            nextAuditoryHallucinations: 'Auditory hallucinations (next)',
                            nextEyelidsMyoclonus: 'Eyelids myoclonus (next)',
                            endAnotherSeizure: 'Another seizure (end)',
                            endBodyConvulsions: 'Body convulsions (end)',
                            endBodyPartConvulsions: 'Body part convulsions (end)',
                            endFaceConvulsions: 'Face convulsions (end)',
                            endEyeMovementDisorders: 'Eye movement disorders (end)',
                            endEyeTurnPernament: 'Eye turn permanent (end)',
                            endBodyTurn: 'Body turn (end)',
                            endGroundFall: 'Ground fall (end)',
                            endUrinating: 'Urination (end)',
                            endDefecation: 'Defecation (end)',
                            endSounds: 'Sounds (end)',
                            endAggression: 'Aggression (end)',
                            endMotorAutomatisms: 'Motor automatism (end)',
                            endImmobility: 'Immobility (end)',
                            endLaugh: 'Laughing (end)',
                            endChuck: 'Chucking (end)',
                            endCry: 'Crying (end)',
                            endVomit: 'Vomiting (end)',
                            afterEyeMovementDisorders: 'Eye movement disorders (after)',
                            afterEyeTurnPernament: 'Eye turn permanent (after)',
                            afterUrinating: 'Urination (after)',
                            afterDefecation: 'Defecation (after)',
                            afterConfusion: 'Confusion (after)',
                            afterSleep: 'Sleep (after)',
                            afterAggression: 'Aggression (after)',
                            afterMotorAutomatisms: 'Motor automatism (after)',
                            afterVomit: 'Vomiting (after)',
                            afterHighTemperature: 'High temperature (after)',
                            featureMany: 'Many (feature)',
                            featureDayOccurrence: 'Day occurrence (feature)',
                            featureNightOccurrence: 'Night occurrence (feature)',
                            featureAwakeningOccurrence: 'Awakening occurrence (feature)',
                            featureMorningOccurrence: 'Morning occurrence (feature)',
                            featureDuration: 'Duration (feature)'
                        }
                    },
                    model: {
                        search: 'Search',
                        manage: 'Manage',
                        drug: {
                            general: "General data",
                            weights: "Weights",
                            shortName: "Short name",
                            name: "Name",
                            minAge: "Min age",
                            plAvailability: "Available in PL",
                            plRefunded: "Refunded in PL",
                            reproductiveAgeWeight: "Reproductive age",
                            pregnantWeight: "Pregnant",
                            oldAgeWeight: "Old age",
                            somaticDiseaseWeight: "Somatic disease"
                        },
                        expert: {
                            description: "Expert",
                            factor: "Factor"
                        },
                        epilepsy: {
                            name: "Name"
                        }
                    },
                    algorithmSteps: {
                        filterName: 'Filter',
                        order: 'Order',
                        usedFilters: 'Used filters',
                        filters: {
                            SeizureDrugFilter: 'Durg selection by seizures',
                            UsedDrugFilter: 'Removal of used drugs',
                            TherapyDrugFilter: 'Drug order by expoerts recomendation (weight)',
                            WeightDrugFilter: 'Weights calculation'
                        },
                        tableModel: {
                            drugName: 'Name',
                            drugShortName: 'Short name',
                            minAge: 'Min age',
                            oldAgeWeight: 'Older age',
                            pregnantWeight: 'Pregnancy',
                            reproductiveAgeWeight: 'Reproductive age',
                            somaticDiseaseWeight: 'Somatic diseases',
                            weightSum: 'Weight sum'
                        }
                    },
                    furtherPoliOffset: "Further Politherapy number (starts at 0)"
                }
            },
            duties: {
                Duties: {
                    duty: 'Planned duty',
                    availableDuty: 'Available duty',
                    notAvailableDuty: 'Duty unavalable',
                    selectedDuty: 'Selected',
                    blockedDuty: 'Not available',
                    cancelledDuty: 'Selected for cancellation',
                    dutyToChange: 'Request to take over the duty ',
                    changes: {
                        approve: {
                            text: 'Save changes',
                            tooltipInstruction: 'Choose duties from those available and save by clicking this button'
                        },
                        discard: {
                            text: 'Discard changes'
                        }
                    }
                },
                confirmationDialog: {
                    text: 'Your changes are not saved. Do you want to discard them?'
                },
                youAreNotAllowedDialog: {
                    title: 'Permissions required',
                    text: 'You do not have permission to register duties. Contact us for more information.'
                },
                obligatoryDutyDialog: {
                    title: 'Operation not allowed',
                    subtitle: 'According to the rules of terms and conditions, the selected duty cannot be cancelled.',
                    text: 'For more information check our ',
                    termsAndConditions: 'Duties Terms and Conditions',
                    close: 'Close'
                },
                dutiesLimitAchievedDialog: {
                    title: 'Operation not allowed',
                    subtitle: 'You\'ve reached your duty limit for this week.',
                    text: 'For more information check our ',
                    termsAndConditions: 'Duties Terms and Conditions',
                    close: 'Close'
                }
            },
            medicalHistory: {
                MedicalHistory: {
                    title: 'Medical history',
                    return: 'Return',
                    show: 'Show',
                    psychomotorDevelopment: 'Psychomotor development',
                    comorbidities: 'Comorbidities',
                    notDiagnosedLbl: 'Not diagnosed',
                    bloodType: 'Blood type',
                    height: 'Height',
                    weight: 'Weight',
                    bmi: 'BMI',
                    tabs: {
                        psychomotorDevelopment: 'Psychomotor development',
                        seizuresHistory: 'Seizure history',
                        seizuresCharacteristics: 'Characteristics of seizures',
                        comorbidities: 'Comorbidities',
                        treatment: 'Treatment'
                    },
                    treatment: {
                        title: 'Treatment and visit history',
                        currentTherapy: 'Current therapy',
                        biomedicalData: 'Biomedical data',
                        comorbiditiesData: 'Comorbidities data',
                        previousTherapies: 'Therapies history',
                        number: 'No',
                        medicine: 'Medicine',
                        dosage: 'Dosage',
                        effect: 'Effect',
                        noCurrentTherapy: 'No current therapy',
                        start: 'Start',
                        end: 'End',
                        noPreviousTherapies: 'Therapies history is empty',
                        treat: 'treatment',
                        visits: 'visits'
                    },
                    survey: {
                        title: 'Survey 0'
                    },
                    epicrisis: {
                        title: 'Epicrisis'
                    },
                    calendar: {
                        title: 'Events calendar',
                        visitCalendarLabel: 'Visits calendar'
                    },
                    visitCalendar: {
                        title: "Doctor's and patient's opinion",
                        opinionTypeHeader: "Opinions type",
                        seizureEvaluation: "Seizures severity",
                        wellBeing: "Well-being",
                        treatmentRating: "Treatment efficiency",
                        sideEffects: "Side effects",
                        treatmentSafety: "Treatment safety",
                        patientOpinion: "Patient's opinion",
                        doctorOpinion: "Doctor's opinion",
                        noData: "No data from surveys",
                        noVisits: "No visits"
                    },
                    prescriptions: {
                        title: 'E-prescriptions'
                    },
                    consultations: {
                        title: 'Visits',
                        noConsultations: 'No consultations',
                        observations: 'Observations',
                        recomendation: 'Recommendation',
                        doctor: 'Doctor',
                        date: 'Date',
                        type: 'Typ',
                        category: 'Category',
                        none: 'None'
                    },
                    icd10: {
                        title: 'Diagnosis',
                        current: 'Current diagnosis',
                        history: 'History',
                        from: 'From',
                        added: 'Added',
                        removed: 'Removed',
                        noCurrentIcd10: 'No current diagnosis',
                        noIcd10History: 'Diagnosis history is empty'
                    },
                    bioMedInfo: {
                        edit: "Edit"
                    }
                },
                healthSurvey: {
                    title: 'Survey 0',
                    readOnlyAccess: 'Read only preview. Survey can be filled by doctor during first visit',
                    noAnswer: 'No answer',
                    allDisabled: 'Survey 0 cannot be modified after first visit',
                    partCompleted: 'This survey part has been completed',
                    currentTabDisabled: 'This part of health survey is completed and cannot be modified after first visit',
                    emptyFilterSet: 'There are only questions without an answer displayed on the list.',
                    surveyUpdatedByDoctor: 'Doctor has just updated a survey in  \'{sectionName}\' part',
                    filters: {
                        show: 'Show',
                        all: 'All',
                        empty: 'Empty'
                    },
                    hintPopup: {
                        title: 'Don\'t know how to answer?',
                        content: 'Do not worry! Leave a difficult question unanswered. The doctor will help you fill in the missing parts of the survey during the first conference call.'
                    },
                    SeizureHistory: {
                        introTitle: 'We would like to know how long and what kind of seizures You have.',
                        introSubtitle: 'It is very important. Please note: this is your medical history that can only be determined this way, there is no other way to describe it, so try to get accurate facts and dates!',
                        next: 'Next',
                        edit: 'Edit',
                        back: 'Previous',
                        historyCanBeModified: 'Your seizure history has been already initialized. You can modify it before first visit.',
                        historyCannotBeModified: 'Your seizure history cannot be modified after first visit.',
                        return: 'Return',
                        outroTitle: 'Congratulations, you have answered all questions about your seizure history.',
                        outroSubtitle: 'Thank You!',
                        save: 'Save',
                        generatingInProgress: 'Generating of seizure history in progress. Please wait...',
                        saveSuccess: 'Seizure history was saved successfully',
                        generateSuccess: 'Seizure history was generated successfully',
                        stages: {
                            firstSeizure: 'First seizure',
                            quantityAndRegularity: 'Quantity and regularity',
                            frequency: 'Frequency',
                            breaks: 'Breaks'
                        },
                        seizureTypesForm: {
                            title: 'Determine the number of types of your seizures and name them (maximum 3)',
                            modifyWarning: 'Attention! After seizure types modification seizure history and seizure characteristics have to be redefined',
                            modifyDisabled: 'Seizure types cannot be modified because seizures ',
                            typeName: 'Seizure type name',
                            addType: 'Add seizure type'
                        },
                        firstSeizureForm: {
                            title: 'Enter date of first seizure',
                            subtitle1: 'Go back and remember any first seizure.',
                            subtitle2: 'Determine the date of your first seizure as accurately as possible.',
                            subtitle3: 'We call it seizure {name} (we will define its type later together with your lead doctor).',
                            sickSince: 'Ok, so you\'re sick for:'
                        },
                        quantityAndRegularity: {
                            title: 'Seizures like the first continue to this day, indicate whether:',
                            knowsAllDates: 'Do you know dates of all seizures?',
                            quantityYes: 'From this first seizure to this day they have appeared',
                            quantityNo: 'Seizure like first one did not occur again',
                            quantityUndefined: 'There were so many that it is impossible to give the exact number',
                            seizuresQuantity: 'Number of seizures',
                            yes: 'Yes',
                            no: 'No',
                            regularOccurrence: 'Have episodes happen regularly?'
                        },
                        knowsAllDates: {
                            subtitle: 'Fill dates of following seizures',
                            date: 'Date',
                            remove: 'Remove',
                            add: 'Add date'
                        },
                        frequency: {
                            title: 'Specify frequency - enter how many seizures usually occur in a given period of time:',
                            in: 'In'
                        },
                        breaks: {
                            title: 'Were there breaks when no seizures occurred?',
                            subtitle: 'Identify breaks when no seizures occurred',
                            date: 'Date',
                            to: 'to',
                            remove: 'Remove',
                            add: 'Add period'
                        }
                    },
                    Intro: {
                        title1: 'The information you provide here will be shared with your doctor. They will help correctly diagnose and treat you.',
                        fillSurvey: 'Complete the health survey',
                        inVisit: {
                            title1: 'Teleconsultation is ongoing.',
                            title2: 'It is time to complete the questionnaire together with your doctor.',
                            title3: 'Your doctor will help you answer unclear questions.',
                            next: 'Next'
                        }
                    },
                    PsychomotorDevelopment: {
                        introTitle: 'Psychomotor development',
                        introSubtitle1: 'At this point, we want to determine what your general psycho-physical state is.',
                        introSubtitle2: 'Thanks to your answers, the doctor will select the optimal therapy.',
                        introSubtitle3: 'Remember, if any question is unclear, you can answer it with your doctor during the first conference call.',
                        stages: {
                            psychoPhysicalState: 'Physical and mental condition',
                            senses: 'Senses',
                            drugs: 'Drugs'
                        },
                        psychoPhysicalState: {
                            overall: 'Determine psychomotor development',
                            retardation: 'Determine psychomotor retardation (disability)',
                            iq: 'Determine the patient\'s IQ',
                            notTested: 'Not tested',
                            tested: 'Tested',
                            iqValue: 'Enter the IQ value',
                            speech: 'Active and passive speech. (Speaking and understanding speech)',
                            mentalDisorders: 'History of psychiatric diseases',
                            neurologicalSyndrome: 'Confirmed neurological syndrome',
                            walk: 'Walk',
                            sex: 'Sex'
                        },
                        senses: {
                            nystagmus: 'Does the patient have nystagmus?',
                            vision: 'Vision',
                            hearing: 'Hearing'
                        },
                        drugs: {
                            smoking: 'Smoking',
                            alcohol: 'Alcohol',
                            narcotics: 'Narcotics'
                        },
                        mentalDisorders: {
                            psychosis: 'Psychosis currently or in the past',
                            depression: 'Depression currently or in the past',
                            autism: 'Autism diagnosed',
                            adhd: 'ADHD and the like',
                            mentalDisordersNotKnown: 'Missing or not known'
                        },
                        neurologicalSyndrome: {
                            piramidalDeficit: 'Piramidal deficit',
                            brainDeficit: 'Brain',
                            sensoryDisturbance: 'Sensory disturbance',
                            peripharalParesis: 'Peripharal paresis',
                            noNeurologicalSyndrome: 'Not or not known'
                        }
                    },
                    SeizuresCharacteristics: {
                        introTitle: 'Seizure Characteristics',
                        introSubtitle1: 'Remember that you are describing a typical seizure or seizure. When describing one seizure, you can give exactly what happened. In the case of multiple seizures, some of them may be slightly different from each other, so you list their accidental characteristics. You will describe the pre-seizure states here: trailers, immediate events and stages of the seizure (start, continuation, end and post-seizure event). You will also provide general information about the seizure.',
                        introSubtitle2: 'Every piece of information is important and may have an impact on the doctor\'s substitution of the diagnosis, try to be as accurate and precise as possible.',
                        question: 'Question',
                        seizure: 'Seizure type',
                        emptySeizureTypesInfo: 'You have to define seizure types in tab \'Seizure history\' first',
                        stages: {
                            trailers: 'Seizure\nprophecies',
                            before: 'Pre-seizure\nphase',
                            start: 'First seizure\nsymptom',
                            next: 'Next seizure\nsymptom',
                            end: 'Last symptom\nof a seizure',
                            after: 'After\nseizure',
                            feature: 'Seizure\nfeatures',
                            premonition: 'Premonition'
                        },
                        trailers: {
                            trailerPrecise: 'Are there (or did there occur if there was only 1 seizure) specific harbingers of a seizure?',
                            trailerPreciseDescription: 'A prediction is a more or less precise premonition of a seizure. By an explicit trailer, we mean a specific feeling that can be described: anxiety, sadness, excitement, etc. Sometimes the trailer is undefined, difficult to describe by the patient. It is a long phenomenon, it occurs hours (less often days) before a seizure, which distinguishes it from the aura that immediately precedes the seizure. Some patients only develop the ability to sense a seizure after some time. ',
                            trailerUnspecified: 'Are there (or has there been only 1 seizure) unspecified trailers for a seizure?',
                            trailerUnspecifiedDescription: ''
                        },
                        before: {
                            beforeAura: 'Pre-seizure aura',
                            beforeAuraDescription: 'A sense of an incipient seizure immediately before (usually seconds)',
                            beforeNystagmus: 'Nystagmus/pathological eye movements',
                            beforeNystagmusDescription: 'Was there nystagmus or eyeball movement disorder just before the seizure?',
                            beforeImmobility: 'Partial immobility or motionlessness',
                            beforeImmobilityDescription: 'Was there partial immobility (or mobility impairment) or complete immobility just before the seizure?'
                        },
                        start: {
                            startFaint: 'Loss of consciousness',
                            startFaintDescription: 'Was the loss of consciousness the first sign of a seizure?',
                            startLossConsciousness: 'Full loss of awareness',
                            startLossConsciousnessDescription: 'Was the full loss of awareness the first sign of a seizure?',
                            startLossConsciousnessPartially: 'Partial loss of awareness',
                            startLossConsciousnessPartiallyDescription: 'Was the partial loss of awareness the first sign of a seizure? Disturbed any area of awareness',
                            startBodyConvulsions: 'Whole body convulsions (generalized)',
                            startBodyConvulsionsDescription: 'Were the whole-body convulsion the first sign of a seizure?',
                            startBodyPartConvulsions: 'Convulsions of body parts (focal)',
                            startBodyPartConvulsionsDescription: 'Were the convulsions of body parts the first sign of a seizure?',
                            startFaceConvulsions: 'Facial twitches',
                            startFaceConvulsionsDescription: 'Were the facial twitches (generalized or focal) the first symptom of a seizure ?',
                            startRhythmicConvulsions: 'Rhythmic convulsions',
                            startRhythmicConvulsionsDescription: 'Were these convulsions as the first symptom of a seizure rhythmic?',
                            startBodyStiffness: 'Whole-body stiffening',
                            startBodyStiffnessDescription: 'Was the first symptom of a seizure a tonic stiffening of the entire body?',
                            startLimbEjection: 'Epileptic spasm',
                            startLimbEjectionDescription: 'Was the first symptom of the seizure a West-type epileptic seizure?',
                            startHyperkineticSymptoms: 'Hyperkinetic symptoms',
                            startHyperkineticSymptomsDescription: 'Was the first symptom of the seizure the onset of major hyperkinetic symptoms: pedaling, multidirectional pelvic thrusts and similar?',
                            startEyeMovementDisorders: 'Nystagmus or pathological eye movements',
                            startEyeMovementDisordersDescription: 'Was the first symptom of the seizure nystagmus or pathological eye movements?',
                            startEyeTurnPernament: 'Deviation/rotation of eyeballs',
                            startEyeTurnPernamentDescription: 'Was the first symptom of the seizure the turning of the eyeballs to one of the 4 sides?',
                            startBodyTurn: 'Turning the body and/or head to one side',
                            startBodyTurnDescription: 'Was the first symptom of the seizure a turning of the body and/or head to one side?',
                            startGroundFall: 'Fall',
                            startGroundFallDescription: 'Was the first symptom of the seizure a fall to the ground or a partial fall to the ground (e.g., on your knees)?',
                            startAtony: 'Atonia or astatic seizure of all or part of the body',
                            startAtonyDescription: 'Was the first symptom of the seizure muscular atonia or an astatic seizure involving the whole body or part of it (e.g., just the head)?',
                            startBitTongue: 'Biting the tongue or elements of the mouth',
                            startBitTongueDescription: 'Are the first symptom of a seizure the biting of the tongue or elements of the mouth?',
                            startShout: 'Scream',
                            startShoutDescription: 'Was the screaming the first symptom of the seizure?',
                            startSounds: 'Verbal and non-verbal vocalizations',
                            startSoundsDescription: 'Was the first symptom of the seizure any verbal or non-verbal vocalizations?',
                            startSpeechStop: 'Arrested speech',
                            startSpeechStopDescription: 'Was the arrested speech the first symptom of the seizure?',
                            startMotorAutomatisms: 'Facial and oral automatisms',
                            startMotorAutomatismsDescription: 'Are the first symptom of a seizure the occurrence of facial and oral automatisms: chewing, swallowing, clicking, blinking?',
                            startOtherAutomatisms: 'Other automatisms',
                            startOtherAutomatismsDescription: 'Were the first symptom of the seizure other automatisms: whole body, limbs, vocal perseverations, sexual automatisms, other complex automatisms?',
                            startImmobility: 'Significant reduction in mobility or immobility',
                            startImmobilityDescription: 'Was the first symptom of the seizure a significant reduction/inhibition of mobility or complete immobility?',
                            startLaugh: 'Paroxysmal laughter',
                            startLaughDescription: 'Was the first symptom of the seizure paroxysmal laughter (gelastic seizure)?',
                            startChuck: 'Smacking and slurping',
                            startChuckDescription: 'Are the first symptom smacking and slurping?',
                            startCry: 'Paroxysmal cry',
                            startCryDescription: 'Was the first symptom of the seizure paroxysmal crying?',
                            startEpigastricSymptoms: 'Paroxysmal epigastric symptoms, salivation, change in taste',
                            startEpigastricSymptomsDescription: 'Was the first symptom of the seizure epigastric symptoms, salivation or change in taste?',
                            startHeartSymptoms: 'Cardiac autonomic symptoms: palpitations/tachy/brady/asystole',
                            startHeartSymptomsDescription: 'Were the first symptom of the seizure cardiovascular autonomic symptoms: palpitations, tachycardia or bradycardia, or asystole?',
                            startAutonomicSymptoms: 'Additional autonomic symptoms: pallor, flushing, respiratory distress, abnormal pupil movement, urinary/fecal urge',
                            startAutonomicSymptomsDescription: 'Was the first symptom of the seizure additional autonomic symptoms: countenance, flushing, respiratory distress, pupil movement disturbance, urge to urinate/feces?',
                            startLanguageIssues: 'Dysphasia/Aphasia, Dyslexia/Alexia and/or lack of understanding of speech/language',
                            startLanguageIssuesDescription: 'Were the first symptoms of the seizure speech disorders such as dysphasia/aphasia, dyslexia/alexia, and/or lack of understanding of speech/language?',
                            startMemoryDisorders: 'Memory disturbances during seizure (other senses normal)',
                            startMemoryDisordersDescription: 'Were the first symptom of the seizure memory disturbances during the seizure, with full preservation of the other senses?',
                            startDejaVu: 'Delusions: deja vu, deja vecu, and similar',
                            startDejaVuDescription: 'Were the first symptom of the seizure delusions like déjà vu, déjà vecu or similar?',
                            startVisualHallucinations: 'Visual hallucinations: multicolored shapes (light circles, flashes, loss of part of the visual field or blindness (amaurosis). And/or bilateral black-out or white-out loss of visual field.',
                            startVisualHallucinationsDescription: 'Whether the first symptom of the seizure were visual hallucinations: multicolored shapes (light circles, flashes, loss of part of the visual field or blindness (amaurosis)? And/or bilateral black-out or white-out loss of visual field?',
                            startAuditoryHallucinations: 'Hallucinations and or similar auditory symptoms',
                            startAuditoryHallucinationsDescription: 'Were the first symptoms of the seizure hallucinations and/or similar auditory symptoms?',
                            startFeelingFearOrAnxiety: 'Paroxysmal anxiety or fear',
                            startFeelingFearOrAnxietyDescription: 'Was the first symptom of the seizure paroxysmal anxiety or fear?',
                            startSomatosensorySymptoms: 'Focal somatosensory symptoms, most commonly paresthesias with tingling and/or numbness, stinging, tickling, crawling or sensations of electric shock.',
                            startSomatosensorySymptomsDescription: 'Were the first symptoms of the seizure focal somatosensory symptoms, most commonly paresthesias with tingling and/or numbness, stinging, tickling, crawling or a sensation of electric shock?',
                            startBodyDistortion: 'Distorted body image with sensation of movement (e.g., floating) or altered posture (e.g., twisting motion) in a stationary limb',
                            startBodyDistortionDescription: 'Was the first symptoms of a seizure a distorted body image with a sensation of movement (e.g., floating) or altered posture (e.g., twisting motion) in a stationary limb?',
                            startEyelidsMyoclonus: 'Eyelid myoclonic seizures',
                            startEyelidsMyoclonusDescription: 'Were the first symptoms of the seizure eyelid myoclonic seizures?',
                            startMyoclonus: 'Generalized myoclonic seizures',
                            startMyoclonusDescription: 'Were the first symptoms of the seizure generalized myoclonic seizures?'
                        },
                        next: {
                            nextFaint: 'Loss of consciousness',
                            nextFaintDescription: 'Was the loss of consciousness the next sign of a seizure?',
                            nextLossConsciousness: 'Full loss of awareness',
                            nextLossConsciousnessDescription: 'Was the full loss of awareness the next sign of a seizure?',
                            nextLossConsciousnessPartially: 'Partial loss of awareness',
                            nextLossConsciousnessPartiallyDescription: 'Was the partial loss of awareness the next sign of a seizure? Disturbed any area of awareness',
                            nextBodyConvulsions: 'Whole body convulsions (generalized)',
                            nextBodyConvulsionsDescription: 'Were the whole-body convulsion the next sign of a seizure?',
                            nextBodyPartConvulsions: 'Convulsions of body parts (focal)',
                            nextBodyPartConvulsionsDescription: 'Were the convulsions of body parts the next sign of a seizure?',
                            nextFaceConvulsions: 'Facial twitches',
                            nextFaceConvulsionsDescription: 'Were the facial twitches (generalized or focal) the next symptom of a seizure?',
                            nextRhythmicConvulsions: 'Rhythmic convulsions',
                            nextRhythmicConvulsionsDescription: 'Were these convulsions as the next symptom of a seizure rhythmic?',
                            nextBodyStiffness: 'Whole-body stiffening',
                            nextBodyStiffnessDescription: 'Was the next symptom of a seizure a tonic stiffening of the entire body?',
                            nextHyperkineticSymptoms: 'Hyperkinetic symptoms',
                            nextHyperkineticSymptomsDescription: 'Was the next symptom of the seizure the onset of major hyperkinetic symptoms: pedaling, multidirectional pelvic thrusts and similar?',
                            nextEyeMovementDisorders: 'Nystagmus or pathological eye movements',
                            nextEyeMovementDisordersDescription: 'Was the next symptom of the seizure nystagmus or pathological eye movements?',
                            nextEyeTurnPernament: 'Deviation/rotation of eyeballs',
                            nextEyeTurnPernamentDescription: 'Was the next symptom of the seizure the turning of the eyeballs to one of the 4 sides?',
                            nextBodyTurn: 'Turning the body and/or head to one side',
                            nextBodyTurnDescription: 'Was the next symptom of the seizure a turning of the body and/or head to one side?',
                            nextGroundFall: 'Fall',
                            nextGroundFallDescription: 'Was another symptom of the seizure a fall to the ground or a partial fall to the ground (e.g., to the knees)? If the fall was the 1st symptom, did the patient continue to lie down after the fall?',
                            nextBitTongue: 'Biting the tongue or elements of the mouth',
                            nextBitTongueDescription: 'Are the next symptom of a seizure the biting of the tongue or elements of the mouth?',
                            nextUrinating: 'Urge to or urinating',
                            nextUrinatingDescription: 'Was the next symptom of the seizure a urge or urination?',
                            nextShout: 'Scream',
                            nextShoutDescription: 'Was the screaming the next symptom of the seizure?',
                            nextSounds: 'Verbal and non-verbal vocalizations',
                            nextSoundsDescription: 'Was the next symptom of the seizure any verbal or non-verbal vocalizations?',
                            nextMotorAutomatisms: 'Facial and oral automatisms',
                            nextMotorAutomatismsDescription: 'Are the next symptom of a seizure the occurrence of facial and oral automatisms: chewing, swallowing, clicking, blinking?',
                            nextOtherAutomatisms: 'Other automatisms',
                            nextOtherAutomatismsDescription: 'Were the next symptom of the seizure other automatisms: whole body, limbs, vocal perseverations, sexual automatisms, other complex automatisms?',
                            nextImmobility: 'Significant reduction in mobility or immobility',
                            nextImmobilityDescription: 'Was the next symptom of the seizure a significant reduction/inhibition of mobility or complete immobility?',
                            nextChuck: 'Smacking and slurping',
                            nextChuckDescription: 'Are the next symptom smacking and slurping?',
                            nextEpigastricSymptoms: 'Paroxysmal epigastric symptoms, salivation, change in taste',
                            nextEpigastricSymptomsDescription: 'Was the next symptom of the seizure epigastric symptoms, salivation or change in taste?',
                            nextHeartSymptoms: 'Cardiac autonomic symptoms: palpitations/tachy/brady/asystole',
                            nextHeartSymptomsDescription: 'Were the next symptom of the seizure cardiovascular autonomic symptoms: palpitations, tachycardia or bradycardia, or asystole?',
                            nextAutonomicSymptoms: 'Additional autonomic symptoms: pallor, flushing, respiratory distress, abnormal pupil movement, urinary/fecal urge',
                            nextAutonomicSymptomsDescription: 'Was the next symptom of the seizure additional autonomic symptoms: countenance, flushing, respiratory distress, pupil movement disturbance, urge to urinate/feces?',
                            nextLanguageIssues: 'Dysphasia/Aphasia, Dyslexia/Alexia and/or lack of understanding of speech/language',
                            nextLanguageIssuesDescription: 'Were the next symptoms of the seizure speech disorders such as dysphasia/aphasia, dyslexia/alexia, and/or lack of understanding of speech/language?',
                            nextMemoryDisorders: 'Memory disturbances during seizure (other senses normal)',
                            nextMemoryDisordersDescription: 'Were the next symptom of the seizure memory disturbances during the seizure, with full preservation of the other senses?',
                            nextDejaVu: 'Delusions: deja vu, deja vecu, and similar',
                            nextDejaVuDescription: 'Were the next symptom of the seizure delusions like déjà vu, déjà vecu or similar?',
                            nextVisualHallucinations: 'Visual hallucinations: multicolored shapes (light circles, flashes, loss of part of the visual field or blindness (amaurosis). And/or bilateral black-out or white-out loss of visual field. ',
                            nextVisualHallucinationsDescription: 'Whether the next symptom of the seizure were visual hallucinations: multicolored shapes (light circles, flashes, loss of part of the visual field or blindness (amaurosis)? And/or bilateral black-out or white-out loss of visual field?',
                            nextAuditoryHallucinations: 'Hallucinations and or similar auditory symptoms',
                            nextAuditoryHallucinationsDescription: 'Were the next symptoms of the seizure hallucinations and/or similar auditory symptoms?',
                            nextSomatosensorySymptoms: 'Focal somatosensory symptoms, most commonly paresthesias with tingling and/or numbness, stinging, tickling, crawling or sensations of electric shock.',
                            nextSomatosensorySymptomsDescription: 'Were the next symptoms of the seizure focal somatosensory symptoms, most commonly paresthesias with tingling and/or numbness, stinging, tickling, crawling or a sensation of electric shock?',
                            nextBodyDistortion: 'Distorted body image with sensation of movement (e.g., floating) or altered posture (e.g., twisting motion) in a stationary limb',
                            nextBodyDistortionDescription: 'Was the next symptoms of a seizure a distorted body image with a sensation of movement (e.g., floating) or altered posture (e.g., twisting motion) in a stationary limb?'
                        },
                        end: {
                            endAnotherSeizure: 'Can this seizure transform into another seizure',
                            endAnotherSeizureDescription: 'Can this seizure at the end transition into another seizure? (Not another one of the same type, but of a different type).',
                            endBodyConvulsions: 'Whole body convulsions (generalized)',
                            endBodyConvulsionsDescription: 'Were the whole-body convulsion the last sign of a seizure?',
                            endBodyPartConvulsions: 'Convulsions of body parts (focal)',
                            endBodyPartConvulsionsDescription: 'Were the convulsions of body parts the last sign of a seizure?',
                            endFaceConvulsions: 'Facial twitches',
                            endFaceConvulsionsDescription: 'Were the facial twitches (generalized or focal) the last symptom of a seizure?',
                            endEyeTurnPernament: 'Deviation/rotation of eyeballs',
                            endEyeTurnPernamentDescription: 'Was the last symptom of the seizure the turning of the eyeballs to one of the 4 sides?',
                            endBodyTurn: 'Turning the body and/or head to one side',
                            endBodyTurnDescription: 'Was the last symptom of the seizure a turning of the body and/or head to one side?',
                            endGroundFall: 'Fall',
                            endGroundFallDescription: 'Was the last symptom of the seizure a fall to the ground or a partial fall to the ground (e.g., on the knees)? Or if the patient has been lying down up to this point, is he still lying down?',
                            endUrinating: 'Urge to or urinating',
                            endUrinatingDescription: 'Was the last symptom of the seizure a urge or urination?',
                            endSounds: 'Verbal and non-verbal vocalizations',
                            endSoundsDescription: 'Was the last symptom of the seizure any verbal or non-verbal vocalizations?',
                            endImmobility: 'Significant reduction in mobility or immobility',
                            endImmobilityDescription: 'Was the last symptom of the seizure a significant reduction/inhibition of mobility or complete immobility?',
                            endChuck: 'Smacking and slurping',
                            endChuckDescription: 'Are the last symptom smacking and slurping?',
                            endVomit: 'Vomiting',
                            endVomitDescription: 'Was the vomiting the last symptom of the seizure?'
                        },
                        after: {
                            afterParesisOrParalysis: 'Postictal paresis/palsy',
                            afterParesisOrParalysisDescription: 'Was the postictal paresis or paralysis of Todd-type present after the seizure?',
                            afterUrinating: 'Urge to or urinating',
                            afterUrinatingDescription: 'Was there any urge to urination or urination after the seizure?',
                            afterConfusion: 'Post-ictal confusion and/or retrograde amnesia',
                            afterConfusionDescription: 'Was any post-seizure confusion and/or retrograde amnesia present after the seizure?',
                            afterSleep: 'Post-ictal sleep',
                            afterSleepDescription: 'Did post-ictal sleep occur after the seizure?',
                            afterSalivaOrBloodySalivaFoam: 'Foam on the lips / bloody foam',
                            afterSalivaOrBloodySalivaFoamDescription: 'Was there froth or bloody foam on the lips after the seizure?',
                            afterHyperventilation: 'Hyperventilation',
                            afterHyperventilationDescription: 'Did hyperventilation occur after the seizure?',
                            afterVomit: 'Vomiting',
                            afterVomitDescription: 'Did vomiting occur after the seizure?'
                        },
                        feature: {
                            featureDuration: 'Duration of seizure',
                            featureDurationDescription: 'How long does such an attack last on average?',
                            featureMorningOccurrence: 'Morning seizures',
                            featureMorningOccurrenceDescription: 'Do seizures occur in the morning hours?',
                            featureAwakeningOccurrence: 'Seizures before/after waking up',
                            featureAwakeningOccurrenceDescription: 'Do seizures occur just before or just after waking up?',
                            featureNightOccurrence: 'Nocturnal seizures',
                            featureNightOccurrenceDescription: 'Do seizures occur during nighttime sleep?',
                            featureDayOccurrence: 'Daytime seizures',
                            featureDayOccurrenceDescription: 'Do seizures occur during the day?',
                            featureMany: 'Cluster seizures',
                            featureManyDescription: 'Do seizures occur in series? Series with or without interruption (cluster seizures or status epilepticus)',
                            beforeSpecificStimuli: 'Seizure triggered by a specific stimulus',
                            beforeSpecificStimuliDescription: 'Can the seizure be triggered by a specific stimulus or condition (FS, sounds, lack of sleep, emotions, stress, etc.)?',
                            formChanges: 'Variable and polymorphic seizure',
                            formChangesDescription: 'Does this seizure change in form and duration? Not to be confused with other types of seizures if they are simultaneous!'
                        },
                        fixCriticals: {
                            title: 'Improving the vector for a seizure',
                            subtitle: 'Confirmed seizure',
                            description1: 'Patient responses that differ greatly from the selected seizure reference vector are displayed below.',
                            description2: 'Ask the patient carefully for the answer, if it does not agree with the answer from the survey 0 correct it.',
                            oldAnswer: 'Survey 0 answer',
                            newAnswer: 'New answer',
                            saveConfirm: 'Are you sure you want to override this part of survey 0?',
                            saveSuccess: 'Changes have been saved successfully',
                            vectorChangedBy: 'Vector changed by'
                        },
                        premonition: {
                            trailer: 'Seizure premonition',
                            trailerDescription: 'Is the seizure preceded by a premonition - a feeling of an impending seizure in the minutes/hours before the seizure? Feeling/annunciation conscious or unconscious.'
                        }
                    },
                    Comorbidities: {
                        introTitle: 'Comorbidities',
                        introSubtitle1: 'Here we want to determine if you have comorbidities.',
                        introSubtitle2: 'Remember that diseases can affect the course of epilepsy.',
                        dataSavedMsg: 'Comorbidities information has been saved',
                        hasHeadaches: 'Headaches',
                        answerNo: 'None',
                        answerYes: 'Yes, there are',
                        answerYesInt: 'Yes',
                        disease: 'Disease',
                        typeDisease: 'Enter disease code or name',
                        hasPregnancies: 'Pregnancy',
                        pregnancies: 'Number of pregnancies',
                        hasChildbirths: 'Childbirths',
                        childbirths: 'Number of childbirths',
                        hasPsychiatricDiseases: 'Psychiatric diseases',
                        hasNeurologicalDiseases: 'Neurological diseases',
                        hasInfectiousDiseases: 'Inflammatory and infectious diseases',
                        hasCancerDiseases: 'Cancer diseases',
                        hasImmuneDiseases: 'Blood and immune diseases',
                        hasEndocrineDiseases: 'Endocrine and metabolic diseases',
                        hasEyeDiseases: 'Eye diseases',
                        hasLaryngologicalDiseases: 'Laryngological diseases',
                        hasHeartDiseases: 'Diseases of the heart and circulatory system',
                        hasRespiratoryDiseases: 'Respiratory diseases',
                        hasDigestiveDiseases: 'Digestive system diseases',
                        hasSkinDiseases: 'Skin diseases',
                        hasBoneDiseases: 'Bone diseases',
                        hasUrogenitalDiseases: 'Genitourinary diseases',
                        hasDevelopmentalDefects: 'Developmental, birth defects and chromosomal aberrations',
                        hasInjuriesOrPoisoning: 'Injuries and poisoning',
                        somaticDiseases: 'Patient has diseases that affect the treatment of epilepsy',
                        headaches: 'Patient has headaches'
                    },
                    DrugsHistory: {
                        introTitle: 'Treatment',
                        introSubtitle1: "This is the history of your treatment until today.",
                        introSubtitle2: 'Enter the medicines you have used in the past and what the effect was. Each medicine has the name of the active substance in full and abbreviated form. If you do not remember it, do not worry. ',
                        introSubtitle3: 'It is important that you choose the correct drug name.',
                        drug: 'Medicine',
                        drugEffects: 'Effects of taking the drug',
                        addDrug: 'Add medicine',
                        removeDrug: 'Remove medicine',
                        typeDrug: 'Enter medicine name',
                        dataSavedMsg: 'Treatment history has been saved'
                    }
                },
                visitSurvey: {
                    stages: {
                        patientState: "Patient's state",
                        patientOpinion: "Patient's opinion",
                        doctorOpinion: "Doctor's opinion"
                    },
                    patientState: {
                        lastSeizureDate: "Date of the last seizure before this visit",
                        wellBeing: "General well-being since the last visit",
                        allSeizureRegistered: "I have entered / not entered my seizures in the Event Calendar since my last visit",
                        additionalIncidents: "The following events have happened since the last visit - apart from the events listed in the Events Calendar"
                    },
                    patientOpinion: {
                        seizureCountByPatient: "Subjectively, were there number of seizures the same?",
                        seizureEvaluationByPatient: "Subjectively how bad were the seizures",
                        currentTreatment: "My current treatment is",
                        sideEffects: "Side effects of current treatment"
                    },
                    doctorOpinion: {
                        seizureCountByDoctor: "Number of seizures according to the doctor",
                        seizureEvaluationByDoctor: "The quality of seizures in doctor's opinion",
                        treatmentEfficiency: "The effectiveness of treatment in the opinion of the doctor",
                        treatmentSafety: "Safety of treatment in the opinion of the doctor"
                    },
                    date: "Date",
                    fromDate: "From",
                    toDate: "To",
                    seizure: "Seizure",
                    save: "Save",
                    addSeizure: "Add seizure",
                    noEpisodeAdded: "No seizure was registered",
                    episodeTypeLbl: "Seizure types",
                    incidentDescription: "Seizure description",
                    noSeizure: "No seizure",
                    next: "Next",
                    howManySeizures: "How many seizure since last visit",
                    countableLbl: "There were",
                    unCountableLbl: "There were so many, that it is not possible to give exact number",
                    seizuresMandatoryError: "Add seizure dates",
                    add: "Add",
                    close: "Close",
                    seizuresNotAddedSummary: "Seizures not added since last visit",
                    seizuresDates: "Seizures dates",
                    seizuresSinceLastVisit: "Seizure count since last visit",
                    per: "per",
                    addNewSeizureType: 'Add new seizure type',
                    addSeizureTypeDialog: {
                        title: "Adding new seizure type",
                        name: "Seizure name",
                        success: "New seizure type has been added"
                    },
                    seizurePeriod: "Specify period",
                    addPeriodicSeizure: "Add period",
                    deletePeriodicSeizure: "Remove period",
                    until: "Until",
                    seizuresInPeriod: "Episodes count"
                },
                visitCertificates: {
                    create: "Create certificate",
                    doNotCreate: "Do not create certificate",
                    certificateType: "Certificate type",
                    additionalRemarks: "Additional remarks",
                    additionalRemarksPlaceholder: "Enter additional remarks",
                    generate: "Create",
                    certificateContent: "Certificate content:",
                    pickDate: 'Pick a date',
                    threeMonths: '3 months',
                    sixMonths: '6 months',
                    oneYear: '1 year',
                    date: 'Date',
                    enterProcedureName: 'Enter procedure name',
                    enterActivityName: 'Enter activity name',
                    validationError: 'Mandatory field',
                    addNext: 'Add next certificate',
                    otherDescriptionLbl: 'Enter certificate description',
                    endOfYear: 'End of year',
                    addCertificate: 'Add certificate',
                    noCertificates: 'List of certificates is empty'
                },
                sickLeaves: {
                    title: 'Sick leaves',
                    number: 'Number',
                    dateFrom: 'From',
                    dateTo: 'To',
                    type: 'Type',
                    status: 'Status',
                    add: 'Add sick leave',
                    addPopup: {
                        title: 'Issuing a new sick leave',
                        addBtn: 'Issue a sick leave',
                        codes: {
                            a: 'incapacity for work occurred after a break not exceeding 60 days - caused by the same disease that caused the incapacity to work before the break',
                            b: 'incapacity for work falling during pregnancy',
                            c: 'incapacity for work due to alcohol abuse',
                            d: 'incapacity for work due to tuberculosis',
                            e: 'incapacity for work caused by an illness that arose not later than within 3 months from the date of termination of the sickness insurance coverage - in the case of an infectious disease, the incubation period of which exceeds 14 days, or another disease whose symptoms become apparent after the period longer than 14 days from the date of the onset of the disease '
                        },
                        insuredPersonTitle: 'Data of the insured person for whom the ZUS ZLA is issued',
                        type: 'Reason for inability',
                        pesel: 'Pesel',
                        fetchData: 'Get data',
                        insuredIn: 'Place of insurance',
                        change: 'Change',
                        payersTitle: 'Selection of payers for whom the certificates will be created',
                        sickLeavesTitle: 'List of the insured person\'s previous ZUS ZLA',
                        detailsTitle: 'Information about incapacity for work',
                        inabilityFrom: 'Inability from',
                        inabilityTo: 'Inability to',
                        daysNumber: 'Number of days',
                        reason: 'Reason',
                        careForData: 'Data of the person being cared for',
                        relationship: 'Kinship',
                        relationship1: 'baby',
                        relationship2: 'spouse, parents, stepfather, parent of the child, grandparents, in-laws, grandchildren, stepmother',
                        relationship3: 'other person',
                        letterCode: 'Letter code',
                        indication: 'Medical indication',
                        addressesTitle: 'Address of the insured person\'s stay during the period of incapacity for work',
                        payerName: 'Name',
                        payerNip: 'NIP',
                        hasPue: 'Does she/he have a PUE profile?',
                        address: 'Address',
                        inabilityRange: 'Period of inability',
                        disease: 'Disease',
                        number: 'Series and number',
                        status: 'Status',
                        successToast: 'Sick leave has been issued',
                        addPayerTitle: 'Adding new payer',
                        search: 'Search',
                        payerNotFound: 'Payer not found',
                        addNewPayer: 'Add new payer',
                        confirmIssueSickLeave: 'Are you sure you want to issue a sick leave?',
                        addNewAddress: 'Add new address',
                        workPlace: 'Work place',
                        icd10Note: 'Note! Main icd 10 code will be passed to ZUS'
                    },
                    cancelPopup: {
                        title: 'Cancelling a sick leave',
                        reason: 'Cancellation reason',
                        confirmBtn: 'Cancel a sick leave',
                        confirmCancelSickLeave: 'Are you sure you want to cancel this sick leave?',
                        successToast: 'Sick leave has been cancelled'
                    }
                }
            },
            patientVisits: {
                nextVisit: {
                    title: 'Visits',
                    nextTitle: 'Subsequent visits',
                    changeDate: 'Change date',
                    join: 'Start visit',
                    forego: 'Cancel',
                    payFor: 'Pay for visit'
                },
                lastRecommendation: {
                    title: 'Recommendation from previous visit',
                    pdf: 'Download summary',
                    drug: 'Drug',
                    dosages: 'Dosages',
                    careLbl: 'Take care',
                    from: 'from',
                    to: 'to'
                },
                visitList: {
                    title: 'Visits archive',
                    subtitle: 'past visits',
                    pdf: 'Download PDF',
                    date: 'Date',
                    type: 'Type',
                    category: 'Category',
                    doctor: 'Doctor',
                    summary: 'Recommendations',
                    invoice: 'Invoice'
                }
            },
            password: {
                reset: {
                    title: 'Password recovery',
                    subtitle1: 'Go back to',
                    subtitle2: 'login screen',
                    form: {
                        email: 'E-mail address',
                        send: 'Confirm'
                    },
                    response1: 'A message has been sent to the given e-mail address with reset confirmation link.',
                    response2: 'Please close this browser window and click on the sent link.',
                    resendMessage: 'Link for password change has been sent again'
                },
                resetConfirm: {
                    title: 'Confirm password change',
                    form: {
                        password: 'New password',
                        passwordConfirm: 'Confirm password',
                        send: 'Confirm'
                    },
                    response: 'Password changed.',
                    loginBtnLbl: 'Log in'
                }
            },
            patient: {
                account: {
                    register: {
                        confirm: {
                            outcomeSuccesMsg: 'Registration successfully completed',
                            outcomeSuccesDetMsg: 'Click the button below to go to the login page',
                            outcomeFailureMsg: 'A problem occurred while trying to confirm registration',
                            outcomeFailureDetMsg: 'Please try again clicking on the link sent in the email, or contact the Neuroterminal team',
                            saveBtn: 'Log in'
                        }
                    },
                    settings: {
                        title: 'Settings',
                        saveLbl: 'Save',
                        succesMsg: 'Your settings have been saved'
                    }
                },
                therapy: {
                    prescriptions: {
                        newLbl: 'new prescriptions',
                        archiveLbl: 'prescription archive',
                        dateFromLbl: 'Issued from',
                        dateToLbl: 'Issued to',
                        noDataLbl: 'No prescriptions',
                        previewLbl: 'Preview',
                        prescriptionLbl: 'Prescription',
                        headers: {
                            dateLbl: 'Date',
                            doctorLbl: 'Doctor',
                            numberLbl: 'Prescription code',
                            actionsLbl: 'Prescription'
                        },
                        dialog: {
                            downloadLbl: 'Download',
                            closeLbl: 'Close'
                        }
                    }
                }
            },
            doctor: {
                account: {
                    activation: {
                        title: 'Account activation in the system',
                        alreadySent: 'The documents were already sent',
                        alreadyFinished: 'Account activation is done',
                        fillInFormMsg: 'Please fill in data below and click \'Send\'',
                        formAgreementScanLbl: 'Agreement scan',
                        formInsuranceScanLbl: 'Insurance scan',
                        acceptTerms1: 'I accept',
                        acceptTerms2: 'terms of use',
                        acceptPolicy1: 'I accept',
                        acceptPolicy2: 'privacy policy',
                        sendLbl: 'Send',
                        submitMsg1: 'Thank you, we are verifying yor data.',
                        submitMsg2: 'We will send a confirmation in an email.'
                    },
                    settings: {
                        title: 'Settings',
                        saveLbl: 'Save',
                        successMsg: 'Settings saved successfully'
                    }
                }
            },
            prescriptions: {
                home: {
                    titleLbl: 'E-prescriptions',
                    text1Lbl: 'Request for prescription',
                    text2Lbl: 'No requests for prescriptions'
                },
                list: {
                    currentLbl: 'Current',
                    archiveLbl: 'Archived',
                    showLbl: 'Show',
                    sortNewestLbl: 'Newest',
                    sortDeadlingLbl: 'Most urgent'
                },
                details: {
                    medHistLbl: 'Medical history',
                    pleadLbl: 'asks for a prescription',
                    peselLbl: 'Pesel',
                    copyPeselLbl: 'Copy pesel',
                    checkEwusLbl: 'Check in EWUŚ',
                    addressLbl: 'Address',
                    phoneLbl: 'Phone number',
                    issueDateLbl: 'Last prescription issue date',
                    viewLbl: 'Prescription preview',
                    drugsLbl: 'Requested drugs',
                    commentLbl: 'Commentary for the doctor',
                    openLbl: 'Open e-prescription creator',
                    denyInfoLbl: 'In case you decide, that this request is invalid you can deny issuing an e-prescription',
                    denyLbl: 'Deny',
                    prescriptionLbl: 'Prescription',
                    confirmInfo1Lbl: 'Deny prescription request',
                    confirmInfo2Lbl: 'Are you sure you want to deny issuing this prescription?',
                    confirmYesLl: 'Yes, deny',
                    confirmNoLbl: 'No',
                    rejectInfoLbl: 'Prescription request denied',
                    forwardInfoLbl: 'Information was sent to admin',
                    rejectBtn: 'Reject request',
                    rejectDlgTitle: 'Why do you want to reject the request?',
                    rejectReasonPlaceholder: 'Reason for rejection',
                    forwardPrescription: "If you cannot issue an e-prescription to the patient and you want the reception to handle the matter",
                    forwardBtn: "Reject e-prescription",
                    forwardDlgTitle: "Enter a note that will be sent to the reception (admin)",
                    rejectCommentDisclaimer: "The reason for rejection will be sent to the patient",
                    characterCount: "Character count:",
                    infoForAdmin: "Doctor refused to issue an e-prescription:"
                },
                creator: {
                    typeLbl: 'Issue an e-prescription through',
                    returnLbl: 'Return',
                    peselLbl: 'Pesel',
                    copyPeselLbl: 'Copy pesel',
                    checkEwusLbl: 'Check in EWUŚ',
                    issueDateLbl: 'Last prescription issue date',
                    viewLbl: 'Prescription preview',
                    drugsLbl: 'Requested drugs',
                    commentLbl: 'Commentary for the doctor',
                    addProductLbl: 'Add another product',
                    govText1Lb: 'Go to page',
                    govText2Lb: 'and issue a prescription',
                    pinLbl: 'Enter 4-digit prescription pin',
                    fileLbl: 'Prescription PDF file (optional)',
                    fileChooseLbl: 'Select to choose file',
                    issueLbl: 'Issue e-prescription',
                    prescriptionLbl: 'Prescription',
                    notValidMsg: 'The prescription form content is invalid',
                    successMsg: 'Prescription was issued',
                    prescriptionList: "Issued prescription have following codes"
                },
                product: {
                    drugLbl: 'Drug',
                    removeLbl: 'Remove',
                    packageLbl: 'Package',
                    refundLbl: 'Refund',
                    packNumLbl: 'Number of packages',
                    dosageLbl: 'Current dosage',
                    refundCauseLbl: 'Payment',
                    recieptType: 'Receipt type',
                    permissions: 'Additional permissions',
                    description: 'Custom description dosage',
                    dosage: 'Dosage'
                },
                ewus: {
                    checkingLbl: 'Checking pesel in EWUŚ',
                    notFoundLbl: 'Could not find the patient in EWUŚ',
                    insuredLbl: 'The patient is insured',
                    notInsuredLbl: 'The patient is not insured',
                    closeLbl: 'Close'
                },
                doctorPass: {
                    titleLbl: 'ZUS certificate password',
                    cancelLbl: 'Cancel',
                    confirmLbl: 'Confirm'
                }
            },
            eReferrals: {
                creator: {
                    title: 'New e-referral',
                    type: 'Referral type',
                    destination: 'Clinic / laboratory / hospital ward',
                    icd10: 'Diagnosis',
                    selectedIcd9: 'Selected medical procedures',
                    selectIcd9: 'Choose at least one medical procedure',
                    icd9: 'Medical procedure',
                    comment: 'Doctor\'s comment',
                    issueReferral: 'Issue e-referral',
                    icdNoResults: 'No search results',
                    successMsg: 'E-referral has been issued successfully',
                    issueDate: 'Issue date'
                }
            },
            header: {
                labelP1: "Patient",
                labelP2: "Portal"
            },
            epicrisis: {
                treatmentStart: "The patient has been under the care of the Neurosphera since",
                ictalDiagnosis: "ICTAL diagnosis",
                mainDiagnosis: "ICD10 diagnosis",
                additionalDiagnosis: "Additional ICD10 diagnosis",
                note0Header: "Note 0",
                note0Label: "Initial patient observations - completed on Survey 0 or on the patient's first visit to Neurosphera",
                epicrisisEntryHeader: "Entries in epicrisis",
                epicrisisEntryLabel: "This is a place with important information about the patient's disease",
                addEntryLabel: "Add entry",
                entrySavedMsg: "Entry has been saved",
                createdBy: "Created by",
                addedToObservation: "Added to epicrisis",
                migration: "migration"
            }
        },

        component: {
            consultation: {
                diagnosisSelection: {
                    searchIcd10: 'Search for diagnosis',
                    searchIcd10NoResult: 'No results found',
                    empty: 'Patient diagnosis list is empty',
                    main: 'Main',
                    markAsMain: 'Mark as main',
                    icd10: 'ICD 10'
                },
                seizureDiagnosisSelection: {
                    first: 'Seizures first type',
                    second: 'Seizures second type',
                    third: 'Seizures third type'
                },
                drugDosageDialog: {
                    standardTimes: {
                        morning: 'Morning',
                        noon: 'Noon',
                        evening: 'Evening'
                    },
                    standardDose: {
                        morning: 'Morning dosage',
                        noon: 'Noon dosage',
                        evening: 'Evening dosage'
                    },
                    addCustomDose: 'Add dosage at specified time',
                    cancel: 'Cancel',
                    save: 'Save'
                },
                drugDosageEdition: {
                    drugs: 'Medication',
                    stage: 'Step',
                    searchDrug: 'Add medication',
                    searchDrugNoResult: 'No results found',
                    dosage: 'Choose dosage',
                    cancelTherapy: 'cancel',
                    saveTherapy: 'save therapy',
                    addTherapy: 'add next step',
                    previousEndDateError: 'No end date defined for previous step',
                    previousEndDateBeforeCurrentError: 'Therapy end date must be greater then current',
                    activeSubstanceSearch: 'Searching for medicines with active substance',
                    removeManyTitle: 'Automatically remove the drug from subsequent therapies?',
                    addManyTitle: 'Automatically add the drug to subsequent therapies?',
                    yesSameDosage: 'Yes, with the same dosage',
                    yesDifferentDosage: 'Yes, but with the same dosage',
                    refreshOrder: 'Refresh order'
                },
                drugTherapySummary: {
                    current: 'Current drug thrapy',
                    future: 'Future drug therapy',
                    past: 'Past drug therapy',
                    from: 'From',
                    to: 'to'
                },
                drugTherapyHistory: {
                    title: 'Editing drug therapies history',
                    changeHistory: 'Past therapies',
                    noAccess: 'You do not have an access to modify history of drug therapies for this patient',
                    updateSuccess: 'Drug therapies history has been updated successfully'
                },
                medicalHistory: {
                    chart: {
                        loadingLbl: 'Loading data',
                        seizuresLbl: 'Seizures',
                        drugsLbl: 'Medication',
                        resultsLbl: 'Results',
                        visitsLbl: 'Visits',
                        eventsLbl: 'Events',
                        consultationLbl: 'Consultation',
                        seizuresNumberLbl: 'Seizures',
                        seizuresDurationLbl: 'Duration',
                        trendBtn: 'Trend line',
                        disabledLbl: 'Now inaccessible',
                        trendBetterLbl: 'Trend better',
                        trendWorseLbl: 'Trend worse',
                        rangeLbl: 'Data range'
                    }
                },
                patientSummary: {
                    personalInfo:{
                        personalData: 'Personal data'
                    }
                }
            },
            doctor: {
                registration: {
                    form: {
                        firstName: 'Name',
                        lastName: 'Last name',
                        pesel: 'Pesel',
                        pwz: 'PWZ number',
                        email: 'E-mail address',
                        password: 'Password',
                        passwordConfirm: 'Confirm password',
                        phone: 'Phone',
                        diploma: 'Add scaned diploma',
                        isSpecialization: 'I have a specialization in neurology',
                        isPractise: 'I run private medical practice',
                        practiceInfoName: 'Practise name',
                        practiceInfoNipNr: 'NIP',
                        practiceInfoRpwdlNr: 'RPWDL number',
                        practiceInfoRegonNr: 'REGON',
                        practiceAddressStreet: 'Street',
                        practiceAddressHouseAndApartmentNr: 'House / apartment no',
                        practiceAddressHouseNr: 'House no',
                        practiceAddressApartmentNr: 'Apartment no',
                        practiceAddressZipcode: 'Zip code',
                        practiceAddressCity: 'City',
                        practiceAddressCountry: 'Country',
                        isApproveDataProcessing: 'I consent to processing of my data',
                        send: 'Send request',
                        response1: 'Thank you for registering',
                        response2: 'We will contact you through e-mail',
                        gotAccountMsg1: 'Already got an account?',
                        gotAccountMsg2: 'Log in'
                    }
                },
                database: {
                    doctor: {
                        list: {
                            noMedicalHistoryMsg: 'Medical history form was not filled in yet. Please fill it in with as much information as possible before selecting a leading doctor.',
                            gotoMedicalHistoryLbl: 'Go to the form',
                            titleLbl: 'Doctor database',
                            titleDescLbl: 'If you want to change your lead doctor, click the "Choose" button in the profile of your chosen doctor, and then complete a short questionnaire.',
                            subtitleLbl: 'Doctors',
                            patientTypeLbL: 'Patients led',
                            sortTypeLbL: 'Sort by',
                            noResultsLbL: 'Could not find any doctors matching search parameters',
                            sortTypes: {
                                patientsAsc: 'Led patients ascending',
                                patientsDesc: 'Led patients descending',
                                alphabeticAsc: 'Alphabetically ascending',
                                alphabeticDesc: 'Alphabetically descending',
                                remotePriceAsc: 'Remote visit price ascending',
                                remotePriceDesc: 'Remote visit price descending',
                                stationaryPriceAsc: 'Stationary visit price ascending',
                                stationaryPriceDesc: 'Stationary visit price descending'
                            },
                            content: {
                                juniorExpLbl: 'Junior expert',
                                standardExpLbl: 'Expert',
                                seniorExpLbl: 'Senior expert',
                                leadLbL: 'lead doctor',
                                bioLbl: 'BIO',
                                pricesLbl: 'PRICE LIST',
                                servicesLbl: 'SCOPE OF SERVICES',
                                remoteLbl: 'Remote visit',
                                stationaryLbl: 'Stationary visit',
                                messageLbl: 'Message',
                                prescriptionLbl: 'Prescription'
                            }
                        },
                        selection: {
                            request: {
                                alert: {
                                    youHaveTimeTo: 'You need to reply until',
                                    toReplyTo: 'to the request for taking care of the patient',
                                    detailsLbl: 'Details',
                                    closeLbl: 'Close'
                                }
                            }
                        }
                    },
                    lead: {
                        titleLbl: 'Lead doctor',
                        patientsLbl: 'Patients',
                        visitsLbl: 'Visits',
                        firstVisitLbl: 'First',
                        subsequentVisitLbl: 'Subsequent',
                        bioLbl: 'Bio',
                        bookVisitLbl: 'Book a visit',

                        doctor: {
                            change: {
                                request: {
                                    dialog: {
                                        title1: 'Confirm lead doctor selection',
                                        title2: 'Are you sure you want to change your lead dcotor?',
                                        selectionConfirmMsg: 'Are you sure you want to choose this doctor?',
                                        historyCommentLbl: 'Comment regarding you disease history',
                                        reasonLbl: 'Why have you decided to change you doctor?',
                                        reasonCommentLbl: 'Change reason',
                                        evaluationLbl: 'How would you grade you current doctor?',
                                        overallCommentLbl: 'Comments about cooperation with current doctor',
                                        overallCommentInfo: 'This will enable us to control the quality of performed services and remove the under-performing doctors from the system after we verify the reasons.',
                                        confirmLbl: 'Confirm lead doctor change',
                                        cancelLbl: 'Cancel',
                                        reasons: {
                                            punctuality: 'Doctor is not punctual',
                                            result: 'No improvement',
                                            communication: 'Communication problems',
                                            other: 'Other'
                                        },
                                        evaluations: {
                                            vgood: 'very good',
                                            good: 'good',
                                            average: 'average',
                                            bad: 'bad'
                                        },
                                        confirmationLbl: 'A message was sent to the doctor',
                                        closeLbL: 'Close'
                                    }
                                },
                                response: {
                                    dialog: {
                                        title: 'Do you agree to become the leading doctor for this patient?',
                                        firstSeizureLbl: 'Firstseizure date',
                                        epilepsyDescLbl: 'Epilepsy/seizure type',
                                        drugsUsedLbl: 'Medicines used so far',
                                        patientCommentLbl: 'Patient comment',
                                        acceptLbl: 'I agree',
                                        rejectLbl: 'I disagree',
                                        rejectReasonLbl: 'Rejection reason',
                                        confirmLbl: 'Confirm',
                                        cancelLbl: 'Cancel',
                                        rejection: {
                                            reasons: {
                                                punctuality: 'Doctor is not punctual',
                                                result: 'No improvement',
                                                communication: 'Communication problems',
                                                full: 'No vacancies',
                                                seizure: 'Seizure type non-compliant with specialization',
                                                age: 'Non-compliant age profile',
                                                other: 'Other'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            patient: {
                registration: {
                    form: {
                        email: 'E-mail address',
                        password: 'Password',
                        mandatoryDescription: 'Fields marked with * are mandatory',
                        passwordConfirm: 'Confirm password',
                        isAgreeTerms1: 'I agree to',
                        isAgreeTerms2: 'the terms of use',
                        isAgreeTerms3: '',
                        isApprove1: 'I have read the rules for processing of my personal data contained in',
                        isApprove2: 'Neurosphera sp. z o.o. Privacy Policy',
                        isApprove3: 'and',
                        isApprove4: 'Neuroterminal S.A. Privacy Policy',
                        marketing1and2: 'I consent to receiving marketing communications (including commercial information), the purpose of which is to promote services and goods, including communication carried out using my personal data using e-mail address (receiving e-mail messages) and phone number (receiving text messages and incoming calls).',
                        marketing3: 'I want to receive a newsletter from Neuroterminal S.A. on behalf of Neurosphera sp. z o.o.',
                        send: 'Register account',
                        response1: 'Thank you for registering',
                        response2: 'We will send registration confirmation in an email',
                        gotoLoginLbl: 'Log into the system',
                        gotAccountMsg1: 'Already got an account?',
                        gotAccountMsg2: 'Log in',
                        consentsTitle: 'Acceptance of regulations and marketing consents',
                        consentsAccept: 'Accept selected'
                    }, confirmation: {
                        header: 'Confirm registration!',
                        content1: 'We have sent activation link to:',
                        content2: 'Check your email and click on activation link',
                        sendAgainPrefix: "You have not received an email:",
                        sendAgain: "Send again!",
                        contactReception: "or contact reception:",
                        resendBlocked: "In case you have not received email with registration link please contact customer service."
                    }
                },
                documentation: {
                    document: {
                        add: {
                            title: 'Add document',
                            nameLbL: 'Name',
                            typeLbl: 'Type',
                            resultDateLbl: 'Result date',
                            confirmLbl: 'Attach',
                            filesToLargeLbl: 'Some files were too large to add',
                            filesEmptyContentLbl: 'Some files are corrupted or have no content',
                            wrongFileType: 'Wrong file type. Allowed pdf, images or video only.',
                            unsavedFormWarningMsg1: 'There is unsaved data in the form',
                            unsavedFormWarningMsg2: 'Would you like to upload the data before leaving the screen',
                            yesLbl: 'Yes',
                            noLbl: 'No',
                            uploadingMsg1: 'File upload in progress..',
                            uploadingMsg1Templated: 'File upload in progress {filename}',
                            uploadingMsg2: 'In case of larger files this could take a while'
                        },
                        confirm: {
                            title: 'Remove document?',
                            cancelLbl: 'Cancel',
                            confirmLbl: 'Confirm',
                            successMsg: 'Document removed'
                        },
                        edit: {
                            title: 'Edit document',
                            nameLbL: 'Name',
                            typeLbl: 'Type',
                            resultDateLbl: 'Result date',
                            fileNameLbL: 'File name',
                            cancelLbl: 'Cancel',
                            confirmLbl: 'Confirm',
                            changedMsg: 'Document changed successfully'
                        },
                        viewer: {
                            closeLbl: 'Close',
                            typeNotSupported: 'This file type is not supported in viewer'
                        },
                        files: {
                            selection: {
                                title1: 'Drag and drop',
                                title2: 'files that you want to add here',
                                title3: 'or',
                                title4: 'select files',
                                title5: 'from the computer'
                            }
                        },
                        list: {
                            title1: 'Medical documentation',
                            title2: 'Here you can keep your medial documentation',
                            title3: 'By adding test results you can help the doctor to set accurate diagnosis and enhance treatment chance.',
                            noDataLbl: 'No data',
                            noResultsLbl: 'No date',
                            loadingLbl: 'Loading documentation',
                            downloadLbl: 'Download',
                            showLbl: 'Show',
                            editLbl: 'Edit',
                            removeLbl: 'Remove',
                            markCompleted: 'Mark as completed',
                            markCompletedDescription: 'If you mark the description as completed, it will be sent by the reception to the patient. If you want, you can add optional text for the reception',
                            isCompleted: 'Completed',
                            headers: {
                                type: 'Type',
                                name: 'Name',
                                fileName: 'File',
                                resultDate: 'Issued',
                                additionDate: 'Added'
                            },
                            filters: {
                                documentTypeLbl: 'Show',
                                documentTypeAllLbl: 'Show all',
                                resultDateFromLbl: 'Result date from',
                                resultDateToLbl: 'Result date to'
                            }
                        },
                        disk: {
                            space: {
                                filledLbl: 'Filled {filled} GB out of {available} GB'
                            }
                        }
                    }
                },
                nearest: {
                    visits: {
                        forthcomingLbl: 'forthcoming visits',
                        forthcomingConsultLbl: '(consultations)',
                        noVisitMsg: 'No forthcoming visits.',
                        noVisitZeroMsg: 'No forthcoming visits. Please book a zero visit.',
                        noVisitZeroDisclaimerMsg: 'During the visit zero a doctor will analyze your medical history and set your health plan',
                        sosVisitLbl: 'SOS',
                        bookVisitLbl: 'Book visit',
                        cannotBookVisitMsg: "You can't book a visit since not all fields in 'My data' section were filled",
                        editLbl: 'Edit',
                        cancelLbl: 'Cancel',
                        payLbl: 'Pay',
                        absentConsultation: 'Absent consultation - without the participation of the patient',
                        dialog: {
                            paymentPending: 'We are waiting for payment confirmation',
                            paymentError: 'Payment has been failed',
                            payTill1: 'You have to pay till',
                            payTill2: 'otherwise you wisit will be canceled.',
                            pay: 'Pay for a visit'
                        }
                    }
                },
                treatment: {
                    plan: {
                        titleLbl: 'health plan',
                        inaccessibleLbl: 'inaccessible',
                        noVisitZeroDisclaimerMsg: 'You will choose the health plan while visit zero.'
                    },
                    prescription: {
                        request: {
                            stockLbl: 'Stock for {days} days',
                            otherQuestLbl: 'Need another drug',
                            otherDrugLbl: 'Type in the name of the drug in the commentary. Remember to give the exact name of the drug.',
                            confirmTitleLbl: 'Confirmation',
                            confirmTextLbl: 'You will be redirected to the electronic payments page. Continue?',
                            cancelBtn: 'Cancel',
                            confirmBtn: 'Confirm',
                            chooseDoctor: 'Select the doctor you want to ask for a prescription',
                            askForPrescriptionNotAvailable: 'You will be able to send a request for a prescription after visiting the doctor who issues prescriptions',
                            noLeadDoctorAvailable: 'Your lead doctor is currently unavailable. You can send a request for a prescription to the doctor on duty.'
                        }
                    }
                },
                lead: {
                    doctor: {
                        titleLbl: 'lead doctor',
                        noVisitZeroDisclaimerMsg1: 'You will choose your lead doctor',
                        noVisitZeroDisclaimerMsg2: 'during visit zero',
                        changeLbl: 'Change',
                        prescriptLbl: 'Ask for prescription',
                        messageLbl: 'Write a message',
                        servicesLbl: 'Services provided',
                        services: {
                            interview: 'interview',
                            therapy: 'setting therapy',
                            commission: 'issue commission',
                            prescription: 'issue prescription',
                            documents: 'issue referral, certificate, sick leave'
                        },
                        scope: {
                            bullet1: 'Patient\'s first medical contact',
                            bullet2: 'Comprehensive patient caregiver ordering additional tests or possibly referring consultants to specialists',
                            bullet3: 'Advisor, who is the patient\'s direct support and supervises the therapeutic plan',
                            bullet4: 'Supported by the entire team, the iCtal® robot and the medical supervision and automation system'
                        }
                    }
                },
                hashtags: {
                    title: 'Hashtags',
                    subtitle: 'It is a place to mark patient with predefined hashtag',
                    label: 'Search for hashtags'
                }
            },
            finances: {
                payment: {
                    history: {
                        title: 'Settlement history',
                        headers: {
                            month: "Month",
                            sum: 'Total'
                        }
                    }
                },
                invoice: {
                    summary: {
                        text1: 'Financial summary',
                        text2: 'You will find there the data necessary for invoicing',
                        text3: 'Questions about invoices?',
                        text4: 'Contact our finance department',
                        btn_lbl: 'Download summary'
                    }
                },
                general: {
                    summary: {
                        title: 'Finance',
                        return: 'Back',
                        table: {
                            headers: {
                                label: 'Type of visit',
                                price: 'Price',
                                amount: 'Amount',
                                sum: 'Total'
                            },
                            summary: 'Total'
                        }
                    }
                }
            },
            genericDialog: {
                approve: 'Yes',
                cancel: 'No',
                close: 'Close'
            },
            videochat: {
                waitingForPatient: 'Waiting for patient to be connected',
                waitingForDoctor: 'Waiting for doctor to be connected',
                connectionError: 'Connection error occurred',
                visitEnded: 'Telekonference has been ended. Session was terminated.',
                deviceLoadError: 'Error loading devices',
                accessDenied: 'Please grant access to the microphone and camera in the browser and refresh the page if necessary',
                accessDeniedAlert: 'No access to microphone and camera',
                missingAudioInput: 'Microphone device is missing',
                missingVideoInput: 'Camera device is missing',
                accessGrantedBut: 'Permissions have been obtained, however: ',
                cannotConnectCamera: 'Cannot connect to the camera',
                cannotConnectMicrophone: 'Cannot connect to microphone',
                deviceAlreadyInUse: 'Please make sure that the device is not already in use, then try again',
                chooseDeviceDialog: {
                    chooseDevices: 'Select devices',
                    visitStartMsg1: 'The visit will begin at',
                    visitStartMsg2: 'For a smooth meeting, select the audio and video devices you want to use',
                    camera: 'Camera',
                    microphone: 'Microphone',
                    speakers: 'Speakers'
                },
                dialog: {
                    confirm: 'Confirm'
                },
                tooltip: {
                    disableAudio: 'Disable audio',
                    enableAudio: 'Enable audio',
                    disableVideo: 'Disable video',
                    enableVideo: 'Enable video',
                    terminate: 'End your online visit'
                },
                confirm: {
                    warningMsg1: 'Visit still in progress',
                    warningMsg2: 'Do you want to end a teleconference call for a visit in progress?'
                }
            },
            updateMedicalEventDialog: {
                title: 'Edit event',
                changesSaved: 'Changes saved successfully'
            },
            addMedicalEventDialog: {
                title: 'Add event',
                descriptionPlaceholder: 'Enter short description',
                patientSeizureTypePlaceholder: 'Seizure type',
                from: 'From',
                to: 'To',
                duration: 'Duration',
                cancel: 'Cancel',
                approve: 'Save',
                addNext: 'Add another',
                delete: 'Delete',
                addSuccessMsg: 'Event has been added',
                noSeizureTypes: 'To add new seizure you need to define your seizure types in health survey',
                goToHealthSurvey: 'Go to health survey',
                serialSeizures: 'Serial seizures',
                seizuresCount: 'Seizures count'
            },
            user: {
                settings: {
                    sms: {
                        title: 'SMS alerts'
                    },
                    other: {
                        title: 'Other'
                    }
                }
            },
            visitChat: {
                title: 'Visit chat',
                loading: 'Loading messages is in progress...',
                emptyList: 'Messages list is empty',
                inputLabel: 'Write a message'
            },
            payu: {
                banner: {
                    installmentPaymentAvailable: 'Installment payment is available here',
                    startsFrom: 'from',
                    decisionIn: 'decision in',
                    decisionInSeconds: '{val} seconds',
                    checkConditions: 'Check conditions'
                }
            },
            maintenance: {
                newAddress: '<b>Attention!</b> We are changing the address of the branch in Warsaw. Our new address is <b>ul. Taneczna 30, 02-829 Warsaw (Ursynów)</b>'
            }
        },

        /******** custom ui translations *********/

        ui: {
            help: 'Help',
            policy: 'Privacy policy',
            logout: 'Logout',
            navigation: {
                home: 'Home screen',
                account: {
                    name: 'About me',
                    personalData: 'My account',
                    medicalDocuments: 'Medical documents',
                    medicalHistory: 'Health survey'
                },
                messages: 'Messages',
                therapy: 'Therapy',
                visits: {
                    name: 'Visits',
                    archive: 'Archive of visits',
                    doctorDatabase: 'Doctor database'
                },
                myVisits: 'My visits',
                patients: 'Patients',
                ediagnosis: 'IctalLab',
                calendar: 'Calendar',
                workplan: 'Work Plan',
                visitsAndDuties: 'Visits and Duties',
                finances: 'Finances',
                ehealth: {
                    name: 'E-health',
                    prescriptions: 'E-prescriptions',
                    referrals: 'E-referrals'
                },
                settings: {
                    name: 'Settings',
                    notifications: 'Notifications',
                    regulations: 'Regulations',
                    payments: 'Payments'
                },
                documentation: 'Documentation',
                examinations: 'Examinations'
            },
            closeDialog: 'Close',
            visitSummary: 'visit_summary',
            invoice: 'invoice',
            correctionInvoice: 'correction_invoice',
            ieAlert: 'Sorry, we no longer support Internet Explorer. Please use a different internet browser.'
        },

        components: {
            workPlan: {
                header: {
                    TIMETABLE: 'Plan',
                    TIMETABLE_CHANGES: 'Changes calendar'
                },
                editDialog: {
                    workPosition: 'Edit work plan position',
                    unsavedWorkPosition: 'New work plan position',
                    vacation: 'Edit vacation',
                    unsavedVacation: 'New vacation',
                    dayOfWeekLabel: 'Day of the week',
                    officeLabel: 'Office',
                    visitTypeLabel: 'Visit type',
                    startTimeLabel: 'Start time',
                    endTimeLabel: 'End time',
                    description: 'Description',
                    cancel: 'Cancel',
                    approve: 'Approve',
                    visitType: 'Visit type',
                    visitPlace: 'Visit place',
                    stationaryAndRemote: 'Attention! This visit type allows patients to appoint both stationary and remote visits',
                    workPlaceMissing: 'To enable stationary visits you need to set your work place in settings page'
                },
                deleteDialog: {
                    text: 'Are you sure you want to delete this position?',
                    cancel: 'Cancel',
                    confirm: 'Delete'
                },
                visitType: {
                    unavailable: 'Duty',
                    any: 'Any type',
                    stationaryOrRemore: 'Stationary / remote'
                },
                event: {
                    duty: {
                        yourDutyToChange: "Tagged as to change",
                        state: {
                            "AVAILABLE": 'Duty available',
                            "NOT_AVAILABLE": 'Duty not available',
                            "TO_CHANGE": 'Request to take over the duty ',
                            "PLANNED": 'Your planned duty'
                        }
                    }
                },
                description: 'Plan your work - select when you are available for visits',
                selectedWorkPlan: 'Selected work plan:',
                newWorkPlan: 'New work plan:',
                untilFurtherNotice: 'until further notice',
                couldNotDeleteActiveWorkPlan: 'Could not remove active work plan',
                validFrom: 'Valid from',
                validTo: 'Valid to',
                resetChanges: 'Reset',
                saveChanges: 'Save',
                unsavedChanges: 'There are unsaved changes in the calendar',
                visits: 'Visits',
                newPlan: 'New plan',
                messages: {
                    deleteSuccess: 'Work plan has been deleted',
                    addSuccess: 'Work plan has been added',
                    updateSuccess: 'Work plan has been updated'
                }
            },
            doctor: {
                calendar: {
                    todayBtn: 'Today'
                },
                messages: {
                    title: 'Current messages',
                    show: 'Show',
                    new: 'New',
                    item: {
                        systemLbl: 'System mesage',
                        types: {
                            prescribeLbl: 'Request to issue a prescription from: {sender}',
                            prescribeLblMovedFrom: 'instead of {movedFrom}',
                            selectionLbl: 'Request to become lead doctor from: {sender}',
                            noteLbl: 'Note for patient: {patient} has been added'
                        }
                    }
                },
                visits: {
                    title: 'Day plan',
                    show: 'Show',
                    types: 'Visit type',
                    categories: 'Visit category',
                    type: {
                        remote: 'Online visits',
                        stationary: 'Stationary'
                    },
                    item: {
                        statusPayed: 'payed for',
                        statusNotPayed: 'not payed for',
                        statusDone: 'done',
                        statusToMove: 'to move',

                        visitBtnLbl: 'visit',
                        visitStartBtnLbl: 'start visit',
                        historyBtnLbl: 'medical history',

                        numberLbl: 'Number of visits',
                        causeLbl: 'Visit cause',
                        lastVisitLbl: 'Last visit:',
                        diagnosisLbl: 'Diagnosis',
                        diagnosisNoEpilepsyLbl: 'Not epileptic',
                        diagnosisNoDataLbl: 'No diagnosis',
                        drugsLbl: 'Current drugs',
                        drugsNoneLbl: 'None',
                        frequencyLbl: 'Seizure frequency',
                        moodLbl: 'Mood',
                        summaryLbl: 'Visit summary',
                        visitDuration: 'Duration:'
                    }
                },
                form: {
                    pricelist: {
                        titleInfo: 'New price list item',
                        cancelBtn: 'Cancel',
                        saveBtn: 'Confirm'
                    },
                    avatar: {
                        titleInfo: 'Choose image',
                        addImageBtn: 'Image from file system',
                        cancelBtn: 'Cancel',
                        saveBtn: 'Confirm'
                    },
                    address: {
                        streetLbl: 'Street',
                        homeBuildingLbl: 'Building no / apartment',
                        apartmentLbl: 'Apartment no',
                        buildingLbl: 'Building no',
                        cityLbl: 'City',
                        zipcodeLbl: 'Zip code',
                        countryLbl: 'Country'
                    }
                }
            },
            message: {
                history: {
                    list: 'Messages list',
                    title: 'Messages',
                    newMessage: 'New message',
                    newMessageOnDuty: 'New message to doctor on duty',
                    new: 'New',
                    search: 'Search',
                    visitEnded: 'ended',
                    filters: {
                        current: 'Current',
                        archive: 'Archive',
                        contact: 'Search'
                    },
                    noMessages: 'No messages',
                    noTherapies: 'No therapies',
                    born: 'born',
                    historyBtnLbl: 'Medical history',
                    return: 'Return',
                    send: 'Send',
                    sendAndClose: 'Send and close visit',
                    closeNoAnswer: 'Message not require reply',
                    noAnswerContent: 'Thanks for your message. Regards',
                    confirmNoAnswer: 'Are you sure you want to end the visit without answering?',
                    hoursToAnswer: 'h',
                    daysToAnswer: 'days',
                    timeIsUp: 'Time is up',
                    hoursToAnswerRich: 'You have {hours} hours to answer',
                    daysToAnswerRich: 'You have {days} days to answer',
                    timeIsUpRich: 'The declared time for an answer has already passed. Give it as soon as possible!',
                    summary: 'Summary',
                    medicalHistory: 'Medical history',
                    unidirectional: {
                        send: 'Send unidirectional message',
                        sendTitle: 'Unidirectional message to',
                        sendSubtitle: 'Patient cannot respond to this message',
                        sendSuccess: 'Unidirectional message to the patient has been sent',
                        infoForPatient: 'This is a message - information from a doctor. In this message, your doctor has provided you with additional information about your disease. You cannot reply to the message, if you want additional information, write a new message - visit your doctor'
                    },
                    sort: {
                        label: 'Show',
                        newest: 'Newest',
                        alpha: 'Alphabetically',
                        deadline: 'Most urgent'
                    },
                    sendAndCloseDialog: {
                        header: 'Are you sure you want to end the thread?',
                        title: 'If you close the visit, patient will not be able to reply to your message',
                        subtitle: 'If you want the patient to be able to reply to your message, click “Send”'
                    },
                    addToEpicrisis: "Add to epicrisis",
                    textAddedToEpicrisis: "Entry added to epicrisis"
                },
                respondeDeadlineAlert: {
                    youHaveTimeTo: 'You need to reply until',
                    toReplyTo: 'to the message from',
                    reply: 'Reply'
                },
                newPaidMessageAlert: {
                    content: 'You have paid for a message to doctor',
                    reply: 'Write a message'
                },
                unidirectionalMessageAlert: {
                    content: 'You have received a message - information from a doctor',
                    reply: 'View the message'
                },
                personalDataAcceptanceAlert: {
                    title: 'Confirmation of personal data',
                    subtitle: 'Your personal data in the Neuroterminal system is important - make sure they are up to date! Thanks to this, you can take full experience of our services.',
                    changeButton: 'Change your personal data',
                    acceptButton: 'Confirm my personal data'
                },
                patientAlerts: {
                    healthSurveyContent: 'Your health survey needs to be completed',
                    healthSurvey: 'Go to survey'
                },
                newMessageVisit: {
                    noDoctorTitle: 'You have not a lead doctor assigned',
                    title: 'Consult with your doctor - send a message',
                    descriptionLbl: 'Description',
                    description: 'Message visit is a short question sent to the doctor. Your doctor will answer it. If you want to use this form of contact, pay for your visit-message by clicking the "New message" button. If you have any concerns about your health or you need a longer consultation - please make a REGULAR appointment with your doctor.',
                    messageVisitsDisabled: 'Sorry, but your doctor does not provide this type of service.',
                    noLeadDoctorAvailable: 'Your lead doctor is currently unavailable. You can write a message to the doctor on duty.'
                },
                drugStock: {
                    lowMsg: 'Stock are running low for following drugs:',
                    notInitialized: 'Initialize your drug stock for:',
                    daysLbl: 'days'
                },
                toast: {
                    text: 'New incoming message from '
                }
            },
            utility: {
                multipleSelect: {
                    all: 'All'
                },
                confirmationDialog: {
                    title: 'Are you sure?',
                    text: 'Are you sure you want to continue?'
                },
                youAreNotAllowedDialog: {
                    title: 'Permissions required',
                    text: 'You do not have permission. Contact us for more information.',
                    goBackToMainPage: 'Back to main page'
                },
                phoneInput: {
                    phoneNumberLabel: 'Phone number',
                    legalGuardPhoneNumberLabel: 'Phone of the parent or legal representative'
                }
            },
            health: {
                state: {
                    noData: 'No data',
                    stateNoChange: 'No change',
                    stateBetter: 'Better',
                    stateWorse: 'Worse'
                }
            },
            calendar: {
                title: 'Visits and Duty',
                description: 'Your visits for current week. Click on a visit to view its details.',
                visitCancelled: 'The visit was cancelled',
                visitDateChanged: 'Visit date was changed',
                legend: {
                    duty: 'SOS duty',
                    vacation: 'Vacation'
                },
                filters: {
                    showVisits: 'Show visits',
                    showDuties: 'Show duties'
                },
                visitDetailsDialog: {
                    title: 'Visit details',
                    onDay: 'on',
                    fromHour: 'from',
                    toHour: 'to',
                    close: 'Close'
                },
                dutyDetailsDialog: {
                    title: 'Duty details',
                    fromHour: 'from',
                    toHour: 'to',
                    close: 'Close',
                    signUpForDuty: 'Sign up for duty',
                    markDutyToChange: 'Request for duty transfer',
                    toChangeRequestText: 'Asks for a duty transger. You can help this doctor by signing up for this duty for him.',
                    cantMarkDutyAsToChange: 'Unfortunately, you cannot request a transfer of this duty.',
                    dutyNotAvailable: 'This duty is already taken. Choose a different duty date. ',
                    cancelToChange: 'Undo the duty transfer request '
                },
                confirmVisitCancellationDialog: {
                    title: 'Cancellation of a visit',
                    sureToCancelVisit: 'Are you sure you want to cancel the visit of patient',
                    fromDate: ', which was to take place from',
                    fromDatePassed: ', which taken place from',
                    from: 'and continue until',
                    fromPassed: 'and continued until',
                    to: 'to',
                    description: 'The patient will be asked to choose a different date or the service will contact him.',
                    confirm: 'Cancel visit',
                    cancel: 'Cancel',
                    visitAlert: 'You\'re cancelling visit that is due to start in less than 48 hours! We do not support such practices. For more information, please see our',
                    termsAndConditions: 'terms and conditions',
                    visitError: 'This visit has already been done. Make sure you really want to cancel this visit'
                },
                visits: {
                    title: 'Doctors\' visits',
                    description: 'Selected doctors\' appointments for the day. Click on a field in the calendar to view its details.',
                    dateSelectPrefix: 'Selected day',
                    noVisits: 'No visits',
                    doctors: 'Doctors'
                }
            },
            duties: {
                applyDutyDialog: {
                    title: 'Duties edition',
                    subtitle: 'Make sure your changes are correct.',
                    addedDuties: 'Added duties:',
                    cancelledDuties: 'Cancelled duties:',
                    inHours: 'on',
                    back: 'Back to edit',
                    approve: 'Save',
                    acceptTermsAndConditions: 'I accept Duties Terms and Conditions'
                },
                alert: {
                    text: 'You have a duty at:'
                },
                description: 'Sign up for SOS duty. Select a convenient date by clicking on it in the calendar'
            },
            payment: {
                history: {
                    title: 'Payment history',
                    filters: {
                        typeAll: 'All',
                        advFiltLbl: 'Advanced search',
                        advanced: {
                            from: 'Start date',
                            till: 'till',
                            to: 'End date',
                            clearLbl: 'Clear',
                            searchLbl: 'Search'
                        }
                    },
                    noDataLbl: 'No data',
                    noResultsLbl: 'No search results found',
                    headers: {
                        date: 'Date',
                        type: 'Type',
                        amount: 'Amount',
                        invoice: 'Invoice',
                        service: 'Service name'
                    },
                    pdfLbl: 'Download',
                    invoice: 'Invoice',
                    correctionInvoice: 'Correction'
                }
            },
            dialog: {
                generic: {
                    attention: 'Uwaga',
                    confirmation: {
                        dialog: {
                            defaultCancelLbl: 'Cancel',
                            defaultConfirmLbl: 'Confirm'
                        }
                    }
                }
            },
            patient: {
                visits: {
                    next: {
                        visit: {
                            info: {}
                        }
                    },
                    operations: {
                        cancel: {
                            title: 'Do you confirm visit cancellation?',
                            warning: 'According to Terms of Use visits cancelled within 48 hours till visit date are subject to administrative fee of 50% of the visit cost. The remaining amount will be returned (return method will be discussed).',
                            warningCustom: 'According to Terms of Use visits cancelled within {days} days till visit date are subject to administrative fee of {percentage}% of the visit cost. The remaining amount will be returned (return method will be discussed).',
                            reason: 'Visit cancellation reason (optional)',
                            confirmLbl: 'Yes',
                            cancelLbl: "No"
                        },
                        modify: {
                            title: 'Select new visit date',
                            changeWarning: 'Attention! The system allows a patient to change the date / doctor for an appointment only once',
                            confirmMsg: 'New visit date',
                            modifyDisabled: 'Cannot move this visit',
                            dateBefore: 'Previous date',
                            dateCurrent: 'Current date',
                            change: 'Change date',
                            cancelLbl: "Cancel",
                            yesLbl: 'Confirm changes',
                            noLbl: 'Cancel'
                        }
                    }
                }
            },
            surveyZeroNote: {
                addButton: 'Note 0',
                title: 'Initial observations of the patient',
                addDialog: {
                    visitPurpose: 'Purpose of visit',
                    visitPurposeDescription: 'Ask the patient what the purpose of the appointment 1 is for. Ideally, the patient\'s statement should be entered.',
                    surveyCompleted: 'Has survey 0 been completed?',
                    surveyCompletedDescription: 'If survey 0 has not been completed or if some of the survey parts have been completed, please provide a reason.',
                    seizures: 'Describe the seizures in words',
                    seizuresEmpty: 'No seizure types defined',
                    chronicDiseases: 'Does the patient have other chronic diseases - leading? (e.g. autism, stroke) ',
                    chronicDiseasesDescription: 'Chronic diseases',
                    epilepsy: 'Has the patient\'s family history of epilepsy or other neurological diseases?',
                    epilepsyDescription: 'Briefly describe whose family history has epilepsy or other neurological diseases',
                    eegCompleted: 'Has the patient had an EEG test?',
                    mriCompleted: 'Has the patient had an MRI scan?',
                    ctCompleted: 'Has the patient had a CT scan?',
                    geneticResearch: 'Has the patient had a genetic test performed?',
                    relevantExaminations: 'Has the patient had any other relevant studies done?',
                    otherMedicines: 'Is the patient currently taking medications apart from the AEDs included in the questionnaire 0?',
                    otherMedicinesDescription: 'Describe in words what drugs / substances the patient is taking',
                    allergies: 'Does the patient have drug allergies, including antibiotics?',
                    allergiesDescription: 'Describe in words what drugs / substances the patient is allergic to',
                    notes: 'Additional Notes',
                    examinationDescription: 'Describe test results if any',
                    additionalDescription: 'Word description',
                    treatment: 'Treatment so far',
                    treatmentDescription: 'Verbal description',
                    specialDiet: 'Is there any special diet?',
                    specialDietDescription: 'Verbal description',
                    perinatalInterviewUnimportant: 'Important information',
                    birthDate: 'Date of delivery (birth)',
                    birthType: 'Type of birth',
                    pregnancyComplications: 'Complications during pregnancy',
                    birthWeight: 'Weight at birth',
                    birthLength: 'Length at birth',
                    apgar: 'Apgar points (0-10)',
                    apgarFirst: '1 min',
                    apgarNext: 'Next measurement',
                    birthAdditionalProcedures: 'Were any additional procedures performed perinatally?',
                    birthPrematureAgeCorrected: 'Was age corrected for premature babies?',
                    hasSiblings: 'Does the patient have any siblings?',
                    sibling: 'Person',
                    siblingBirthDate: 'Date of birth',
                    siblingSameParents: 'Are they the same parents?',
                    siblingHealthy: 'Is he healthy?',
                    addSibling: 'Add another person',
                    geneticPredisposition: 'Is there an environmental and genetic/hereditary predisposition and family history?',
                    developmentCorrect: 'Is the current development correct?',
                    retardationDetermined: 'Has it been determined to what extent it is retarded in relation to its age?',
                    retardationExamined: 'Was there a specialist examination in this direction?',
                    nystagmus: 'Does the patient have nystagmus?',
                    vision: 'Sight',
                    hearing: 'Hearing',
                    tuxedo: 'Smoking cigarettes',
                    alcohol: 'Alcohol',
                    narcotics: 'Drugs',
                    leadingDisease: 'What is the patient\'s leading disease?',
                    leadingDiseaseDominantSymptoms: 'Dominant symptoms of the disease',
                    leadingDiseaseLasting: 'How long has the illness been going on?',
                    accompanyingSymptoms: 'Accompanying symptoms: would the patient like to add anything?',
                    next: 'Next',
                    previous: 'Back',
                    saveAndClose: 'Save and close',
                    sections: {
                        surveyZero: 'Survey 0',
                        perinatalInterview: 'Perinatal interview',
                        development: 'Development',
                        familyInterview: 'Family Interview',
                        treatment: 'Treatment',
                        comorbidities: 'Comorbidities',
                        medicalExams: 'Medical exams',
                        others: 'Other'
                    }
                },
                preview: {
                    userCreated: 'Created by',
                    seizures: 'Description of entered seizures',
                    chronicDiseases: 'Does the patient have other major diseases?',
                    epilepsy: 'Family interview',
                    eegCompleted: 'EEG Test',
                    mriCompleted: 'MRI Exam',
                    ctCompleted: 'CT Examination',
                    geneticResearch: 'Genetic testing',
                    relevantExaminations: 'Other research',
                    otherMedicines: 'Drugs besides LPP drugs',
                    allergies: 'Drug allergies',
                    addToObservations: 'Add to observations',
                    addFragmentToObservations: 'Add selected fragment do observations',
                    addWholeToObservations: 'Add whole note to observations'
                }
            },
            medicalHistoryPrintout: {
                download: 'Download medical history',
                withObservations: 'Version with observations',
                withoutObservations: 'Version without observations',
                downloadHistory: 'Show download history',
                history: {
                    title: 'History of downloads of the patient\'s medical history',
                    timeDownload: 'Download date',
                    userDownload: 'Downloader',
                    observations: 'Contains observations'
                },
                parametersPopup: {
                    title: 'Download medical history',
                    subtitle: 'Select the period from which you want to download the medical history',
                    allHistory: 'all history from the Neuroterminal system',
                    selectedRange: 'history from a specific time',
                    download: 'Download',
                    cancel: 'Cancel'
                }
            },
            patientExaminations: {
                chooseExaminationLbl: "Select the examination for which you want to complete the description",
                eeg: "EEG examination",
                addEegDescription: "Add EEG examination description",
                eegDescriptionHeader: "EEG examination description",
                describingDoctor: "Description creator",
                months: "months",
                removeEegTitle: "Remove EEG examination description",
                removeEegContent: "Are you sure you want to remove this description",
            }
        },

        common: {
            sex: {
                man: 'Men',
                woman: 'Woman',
                nondefined: 'Not defined'
            },
            role: {
                patientLbl: 'patient',
                doctorLbl: 'doctor'
            },
            years: ' | 1 year | {n} years | {n} years',
            months: ' | 1 month | {n} months | {n} months',
            days: ' | 1 day | {n} days | {n} days',
            visit: {
                paid: 'paid',
                notPaid: 'not paid',
                additional: 'additional',
                toMove: 'to move',
                confirmed: 'confirmed',
                done: 'done',
                cancelled: 'cancelled',
                messagePaid: 'in progress'
            },
            bornAbbr: 'born',
            confirm: "Confirm",
            cancel: "Cancel",
            select: "Select"
        },

        views: {
            profile: {
                welcome: 'WELCOME !',
                choose: 'Login as:',
                doctor: 'doctor',
                patient: 'patient'
            },
            login: {
                loginFldLbl: 'E-mail',
                passwordFldLbl: 'Password',
                loginBtnLbl: 'Log in',
                loggingAs: 'You are log in as',
                wantToLoginAs: 'I want to log in as',

                registerMsg: 'Don\'t have an account yet?',
                registerLinkMsg: 'Register account',

                resetMsg: 'Don\'t remember your password?',
                resetLinkMsg: 'Reset password',

                return: 'return'
            },
            register: {
                patientLbl: 'Patient',
                doctorLbl: 'Doctor',
                loginFldLbl: 'Login',
                newPassFldLbl: 'Password',
                newPassConfFldLbl: 'Confirm password',
                firstNameFldLbl: 'First name',
                secNameFldLbl: 'Second name',
                lastNameFldLbl: 'Last name',
                emailFldLbl: 'E-mail',
                workCertNrFldLbl: 'Certificate nr',
                confirmBtnLbl: 'Confirm',
                backToLoginPageLbl: 'Back to login page'
            },
            reset: {
                loginFldLbl: 'Username',
                newPasswordLbl: 'New password',
                newPasswordConfirmLbl: 'Confirm new password',
                confirmBtnLbl: 'Confirm',
                backToLoginPageLbl: 'Back to login page'
            },
            dashboard: {
                welcomeMessage: "Logged in user: {userName}",
                enableEditable: 'Confirm view',
                disableEditable: 'Manage view'
            },
            visits: {
                titleLbl: 'My visits',
                noDataLbl: 'No visits',
                noResultsLbl: 'No visits found',
                filters: {
                    visitTime: {
                        all: 'All',
                        future: 'Future',
                        past: 'Past'
                    },
                    searchPatientLbl: 'Search for patient',
                    advancedFiltersLbl: 'Advanced search',
                    advancedFilters: {
                        from: 'Date from',
                        betweenDatesLbl: 'do',
                        till: 'Date till',
                        type: 'Visit type',
                        category: 'Visit category',
                        all: 'All',
                        clearBtn: 'clear',
                        searchBtn: 'search'
                    }
                }
            },
            account: {
                common: {
                    requiredMsg: 'Required fields are marked with an asterisk',
                    clearBtn: 'Clear',
                    editBtn: 'Edit',
                    saveBtn: 'Confirm',
                    yes: 'Yes',
                    no: 'No',
                    woman: 'woman',
                    man: 'man',
                    nondef: 'not defined'
                },
                home: {
                    myInfoLbl: 'My info',
                    generalInfoLbl: 'General info',
                    accountInfoLbl: 'Practise info',
                    pricelistInfoLbl: 'Financial data',
                    incompleteFormWarningMsg1: 'Not all fields have been filled',
                    incompleteFormWarningMsg2: 'Are you sure you want to save an incomplete form?'
                },
                general: {
                    basicInfoLbl: 'Basic info',
                    avatarLbl: 'Avatar',
                    nameLbl: 'Name',
                    lastNameLbl: 'Last name',
                    sexLbl: 'Sex',
                    emailLbl: 'E-mail',
                    phoneLbl: 'Phone number',
                    pwzLbl: 'PWZ number',
                    dataSavedMsg: 'General info saved'
                },
                practise: {
                    basicInfoLbl: 'Permissions, services provided, scientific carrier',
                    type: 'Level',
                    consultationType: 'Consultation type',
                    academicTitle: 'Academic title',
                    specialization: 'Specialization',
                    workPlace: 'Work place',
                    epileptologyCert: 'Epileptology certification',
                    nfzContract: 'NFZ contract',
                    testResDescriptions: 'Test result descriptions',
                    consultations: 'Consultations',
                    scientificWork: 'Scientific work',
                    scientificSocieties: 'Scientific societies',
                    www: 'WWW page',
                    bio: 'Bio',
                    certificate: 'ZUS certificate',
                    chooseFile: 'Choose file',
                    patientsInfoLbl: 'Patients',
                    newPatients: {
                        label: 'New patients',
                        accept: 'Admits',
                        noaccept: 'Does not admit'
                    },
                    acceptedPatients: 'Accepted patients',
                    neuroscreenDiagnoses: 'Promoted diseases',
                    leadPractiseInfoLbl: 'Leading practise info',
                    workplaceName: 'Workplace name',
                    workplacePosition: 'Position',
                    communicators: 'Communicators',
                    usernameMessanger: 'Messenger user name',
                    usernameWhatsup: 'Whatsapp user name',
                    usernameSkype: 'Skype user name',
                    usernameHangouts: 'Hangouts user name',
                    dataSavedMsg: 'Practise info saved',
                    partner: 'Partner NSPH'
                },
                pricelist: {
                    services: 'Services',
                    visitsLbl: 'Visits',
                    othersLbl: 'Others',
                    systemItemsLbl: 'System items',
                    systemItemsDescription: 'This services are set globally by Neuroterminal',
                    descriptionsLbl: 'Test result descriptions',
                    table: {
                        statusLbl: 'Status',
                        activeLbl: 'Active',
                        inactiveLbl: 'Inactive',
                        nameLbl: 'Service name',
                        durationLbl: 'Duration',
                        priceLbl: 'Price',
                        paymentLbl: 'Your payment',
                        perHourLbl: 'per hour',
                        dutyLbl: 'Duty'
                    },
                    bankAccount: 'Bank account',
                    widgetVisibility: 'Visible in appointing widget',
                    practiceName: 'Private practice name',
                    nip: 'Nip',
                    regon: 'Regon',
                    dataSavedMsg: 'Price list info saved',
                    visitsDescription: 'Set rates for particular visit types.',
                    othersDescription: 'Set rates for others services not grouped by patient types like messages or medical documents',
                    choosePatientTypes: 'You need to select at least one patient type to define pricelist of visits'
                }
            },
            patientDashboard: {
                neuroscreen: 'Neuroscreen',
                yourLeadDoctor: 'Your lead doctor',
                doctorOnDuty: 'Doctor on duty',
                addEvent: 'Add event',
                showCalendar: 'Show calendar',
                filesAdded: 'Files added',
                addFiles: 'Add files',
                showAllFiles: 'See all files',
                surveyZeroDesc: 'The survey is completed by the doctor during the visit Survey 0',
                showSurveyZero: 'Show the survey',
                aboutMe: 'About me',
                myData: 'my data',
                myDataEdit: 'Edit',
                myDataFill: 'Fill',
                myDataShow: 'Show',
                medicalDocuments: 'medical documentation',
                healthQuestionnaire: 'health questionnaire',
                visitsLbl: 'Visits',
                eventCalendar: 'Event calendar',
                addEventLbl: 'Add',
                confirmDeletionTitle: 'Event deletion',
                confirmDeletionText: 'Do you want to delete that event?',
                seizure: 'Seizure',
                infection: 'Infection',
                fever: 'Fever',
                insomnia: 'Insomnia',
                menstruation: 'Menstruation',
                pregnancyStart: 'Pregnancy or pre-pregnancy period start',
                pregnancyEnd: 'Pregnancy or pre-pregnancy period end',
                chooseLeadDoctor: 'Choose lead doctor',
                noLeadDoctor: 'You will choose lead doctor after Visit zero',
                notAvailableNow: 'Not available',
                doctorCare: 'Medical care',
                alert: {
                    yourVisitWithDoctor: 'Your visit with doctor',
                    startsAt: 'starts at',
                    lastsFrom: 'lasts from',
                    join: 'Join',
                    visitToMove: {
                        first: 'Your visit at',
                        second: 'has been canceled',
                        makeNewAppointment: 'Choose a new date'
                    },
                    myData: {
                        label: 'To use the Patient\'s account, complete the personal data form.',
                        action: 'Go to the form'
                    },
                    prescriptionsToRead: {
                        label: 'The doctor issued a prescription. To view the PIN code, go to the Therapy tab.',
                        action: 'Show prescriptions'
                    },
                    selectLeadDoctor: {
                        label: 'Select your lead doctor.',
                        action: 'Select doctor'
                    },
                    doctorRejectedRequest: "{doctorName} has not agreed to become your lead doctor. Reason: {reason}"
                },
                activation: {
                    welcomeLbl: 'Welcome to Neuroterminal',
                    init1Lbl: 'Glad you are with us.',
                    init2Lbl: 'Before the first visit fill in informations about your health state.',
                    init3Lbl: 'my data',
                    init4Lbl: 'medical documentation',
                    init5Lbl: 'Then book visit zero.',
                    nextLbl: 'Next'
                },
                therapy: 'current therapy',
                noCurrentTherapies: 'No current therapy',
                drugStock: 'drug stock',
                drugStockDescription: 'Click „Update” to provide your current drug stock.',
                supplyFor: 'supply for {days} days',
                amountUndefined: 'amount is undefined',
                supplyEndsIn: 'ends in {days} days',
                medicineLbl: 'Medicine',
                runningOutStocks: 'Your stock is running low. Ask your lead doctor to issue a prescription.',
                update: 'Update',
                save: 'Save',
                askForPrescription: 'Ask for prescription',
                updateDrugStockLbl: 'Drug stock update',
                updateDrugStockLblDesc: 'Count your current drug stock and put a value to the field below.',
                drugStockUpdateHelpLbl: 'Enter drug stocks from the beginning of today. It will be updated automatically every morning with dosages from your current drug therapy.',
                drugStockUpdated: 'Drug stocks for {products} updated successfully',
                drugStockRecalculationFailed: 'Automatic drug stock update failed. You may update your drug stock manually.',
                referrals: {
                    title: 'Recommendation from doctor',
                    appoint: 'Appoint'
                },
                nonepileptic: {
                    infoMsg: 'All consultations are one-time visits conducted by the best specialists. Detailed terms of service with the list of available doctors is available within the visit booking process.',
                    consultationsLbl: 'consultations',
                    specialistsLbl: 'specialists',
                    epileptLbl: 'epileptologist',
                    neurosurLbl: 'neurosurgeon',
                    neurologLbl: 'neurologopedist',
                    psychLbl: 'psychiatrist'
                },
                dataNotFilled: {
                    title: 'Welcome in Neuroterminal application!',
                    footer: 'Choose who will be the Neurosphera patient',
                    patient: 'I am a patient',
                    patientDescription: 'For patients over 18',
                    guardian: 'The patient is a child',
                    guardianDescription: 'For patients who are under 18 or have a legal guardian'
                },
                carePlan: {
                    title: 'Care plan',
                    date: 'Date',
                    incomingVisitsAndReferrals: 'Incoming visits and referrals',
                    incomingVisitsAndReferralsDesc: 'Your incoming appointments and doctor referrals will appear here',
                    bookFirstVisit: 'Book first visit',
                    archiveVisits: 'Archive visits',
                    archiveVisitsDesc: 'Here you will see visits that have already taken place',
                    scheduledDate: 'suggested date',
                    getSummaryPdf: 'get recommendations from visit',
                    cancelReferral: 'remove referral',
                    changeDate: 'change date',
                    cancelVisit: 'cancel visit',
                    reassignVisit: 'change doctor',
                    payForVisit: 'pay for visit',
                    startingAt: 'Starting at',
                    goToVisit: 'Enter the visit',
                    showMoreArchiveVisits: 'Show all archive visits',
                    cancelReferralConfirmation: 'Are you sure you want to remove this referral?',
                    cancelReferralSuccess: 'Referral has been removed',
                    chooseDate: 'Choose date',
                    chooseDateFreeReferral: 'Choose date for free visit',
                    change: 'Change care plan',
                    suggestedDate: 'suggested date',
                    referralRemoved: 'Removed referral',
                    downloadSummary: 'Download summary',
                    description: 'Description',
                    filters: {
                        canceled: 'Show canceled',
                        visits: 'Show visits',
                        messages: 'Show messages',
                        referrals: 'Show referrals'
                    }
                }
            },
            patientAccount: {
                info: {
                    general: 'Patient data',
                    generalDesc: 'We need some of your details. This is a formal requirement. Remember, they are legally and technically protected!',
                    generalDescLegal: 'We also need our patient\'s details. This is a formal requirement, but also the ability to correctly issue a prescription, certificates or referrals.',
                    infoLbl: 'Peronal data',
                    avatarLbl: 'Avatar',
                    nameLbl: 'Name',
                    lastNameLbl: 'Last name',
                    sexLbl: 'Sex',
                    emailLbl: 'E-mail',
                    phoneLbl: 'Phone no',
                    peselLbl: 'Pesel',
                    nameLblRich: 'Patient\'s <b>name</b>',
                    lastNameLblRich: 'Patient\'s <b>surname</b>',
                    peselLblRich: 'Patient\'s <b>pesel</b>',
                    otherDocumentLbl: 'Other document',
                    documentTypeLbl: 'Document type',
                    documentNumberLbl: 'Document number',
                    birthDate: 'Birth date',
                    repLbl: 'Official representative data',
                    parentLbl: 'Parent data',
                    repLblDesc: 'We need some more information about you, dear parent, in order to be able to take care of you in accordance with the requirements of the law. Remember that your data is legally and technically protected!',
                    repLblShort: 'Legal representative',
                    repNameLbl: '<b>Name</b> of the parent or legal representative',
                    repLastNameLbl: '<b>Surname</b> of the parent or legal representative',
                    repPhoneLbl: '<b>Phone</b> of the parent or legal representative',
                    repPeselLbl: '<b>PESEL</b> of the parent or legal representative',
                    teleAddrLbl: 'Patient address',
                    teleAddrLblDesc: 'Correct contact details are essential for the physician to issue a prescription, certificate or referral.',
                    bioMedLbl: 'Biomedical data',
                    bioMedLblDesc: 'The following information is not mandatory. You can refill them later. Remember that providing biometric data helps the doctor choose the correct dose of medicines.',
                    bloodTypeLbl: 'Blood type',
                    rhTypeLbl: 'RH',
                    heightLbl: 'Hight',
                    weightLbl: 'Weight',
                    iceLbl: 'ICE (In Case of Emergency - in immediate situations)',
                    iceShortLbl: 'ICE',
                    others: 'Others',
                    othersDesc: 'The following data is not mandatory if you want to leave us information about who to notify in case of an emergency.',
                    icePers1Lbl: 'Person to call 1',
                    icePers1PhoneLbl: 'Person 1 phone no 1',
                    icePers2Lbl: 'Person to call 2',
                    icePers2PhoneLbl: 'Person 2 phone no',
                    addIceContact: 'Add another person',
                    nsphSourceQuestion: 'How did you hear about Neurosphera?',
                    nsphSourceDoctorDescription: 'Please provide a name of a doctor',
                    clearBtn: 'Clear',
                    saveBtn: 'Confirm',
                    dataSavedMsg: 'Data saved',
                    entitledStatementMsg: 'I hereby state that I have full legal rights',
                    legalGuardianMsg: 'I hereby state that I\'m a legal representation entitled to represent the Patient as:',
                    invalidPeselWarnMsg: 'Provided PESEL is incorrect, sex and birth date will not be saved',
                    fromNsph: 'Has the patient been treated at CTP Neurosphera?',
                    hasEpilepsy: 'Does the patient has epilepsy?',
                    incompleteFormWarningMsg1: 'Not all fields have been filled',
                    incompleteFormWarningMsg2: 'If you do not fill in all the required fields, your account will not be activated and you will not be able to schedule an Visit 1. This is a legal requirement that we, as a healthcare entity, are obligated to fulfill. Correct data allow our doctors to provide medical services, including, for example, issuing prescriptions.',
                    incompleteFormConfirmBtn: 'Complete the data',
                    yesLbl: 'Yes',
                    noLbl: 'No',
                    contactEmailLbl: "Additional email address for receiving system messages",
                    contactEmailDesc: "Given address will be available for the reception of the clinic. If you also want to receive notifications from the system to the given address, mark the appropriate points:",
                    contactVisitNotification: "Visits - payment, confirmation",
                    contactEmailPlaceholder: "Email address",
                    contactDescriptionPlaceholder: "Address description",
                    contactReferralNotification: "Referrals - care plan",
                    addAdditionalContactEmail: "Add next email address",
                    documentCountry: "Country",
                    partner: 'Partner NSPH',
                    invoiceBuyer: {
                        title: 'Invoice data',
                        checkboxLbl: 'I want to set different data for the invoice',
                        firm: 'Firm',
                        person: 'Private person',
                        firmLbl: 'Firm name',
                        nipLbl: 'Nip',
                        firstNameLbl: 'First name',
                        lastNameLbl: 'Last name',
                        streetLbl: 'Street',
                        buildingLbl: 'Building',
                        zipcodeLbl: 'Postal code',
                        cityLbl: 'City',
                        comment: 'Comment'
                    },
                    address: {
                        streetLbl: 'Patient\'s <b>street</b>',
                        cityLbl: 'Patient\'s <b>city</b>',
                        countryLbl: 'Patient\'s <b>country</b>'
                    },
                    leaveDialog: {
                        title: 'You did not finished entering data, are you sure you want to log out?',
                        subtitle: 'You will have to re-enter them the next time you log in',
                        footer: 'If you have any additional questions or in case of any problems with completing the application - contact us:'
                    }
                },
                bioMed: {
                    saveSuccess: "Zmiany zostały zapisane",
                    fullInfo: "Show all data"
                },
                bioMedEditDialog:{
                    title: 'Editing patient biomedical data',
                    subtitle: 'When changing the value, the data is automatically saved'
                }
            },
            awaitingPaymentNotAvailable: {
                title: 'Visit booking failed!',
                subtitle: 'Sorry for the inconvenience',
                ageDescription: 'Your chosen doctor does not accept patients of this age',
                changeDescription: 'Select another doctor and then appointment',
                action: 'Select a doctor and appointment date'
            },
            visitReservation: {
                title: 'Visit reservation',
                consultationTitle: 'Consultation reservation',
                specializationChoice: 'Specialization choice',
                visitTypeChoice: 'Visit type',
                doctorChoice: 'Doctor choice',
                slotChoice: 'Slot choice',
                summaryAndPayment: 'Summary and payment',
                noServicesAvailable: 'You will be able to appoint new epilepsy visits after visit you have already appointed',
                goToVisitsList: 'Go to visit list',
                appointConsultation: 'Appoint a consultation / EEG',
                leadDoctor: 'your lead doctor',
                next: 'Next',
                choose: 'Choose',
                expand: 'Expand',
                approve: 'Confirm',
                cancel: 'Cancel',
                fold: 'Fold',
                back: 'Back',
                doctor: 'Doctor',
                service: 'Service',
                price: 'Price',
                payment: 'Payment form',
                date: 'Date',
                pay: 'Pay',
                cancelAndChangeDate: 'Cancel / change date',
                calculateInstallments: 'Pay in installments',
                noSlotsAvailable: 'No slots available',
                doctors: 'Doctors',
                availableSlots: 'Available slots',
                selectPreviousSlots: 'Available slots will be presented after selection of previous appointment',
                summary: 'Summary',
                serviceDescription: 'Description',
                priceFrom: 'Price from',
                visitDuration: 'Duration of visit',
                durationSelectDesc1: 'The examination may last from',
                durationSelectDesc2: 'to',
                durationSelectDesc3: 'hours',
                durationSelectDesc4: 'To continue with an appointment please select the number of hours',
                durationTime: 'Time of examination',
                eegDescription: 'In the Neuroterminal system, you sign up for a standard EEG test lasting 30 minutes. The test is performed at the Neurosphera Epilepsy Therapy Center. If you want to sign up for a non-standard EEG test or an EEG modification (VEEG, DEEG, PSG or other), make an appointment by phone with the secretariat of the Neurosphera Epilepsy Therapy Center at +48 609 463 191',
                allDoctors: 'All doctors',
                allWorkplaces: 'All locations',
                allTechniciansIntro: 'The examination will be performed by one of our experienced technicians',
                paymentLinkSent: 'Payment link has been sent to the patient',
                markedAsPaid: 'Visit has been appointed and marked as paid',
                openPaymentLink: 'Open payment link',
                linkCopied: 'Link has been copied to the clipboard',
                markAsPaid: 'Mark as paid',
                stage: 'Stage',
                sum: 'Sum',
                freeAppointmentPrice: 'Free',
                appointmentSuccess: 'Wizyta została umówiona',
                sameDateAsPrevious: 'Visit should be on the same day as the previous package visit',
                refundRestrictions: 'Visit cancellation policy',
                refundRestrictionsAccept: 'I accept the cancellation policy',
                supervisorDoctor: 'visit supervisor',
                supervisorDoctorAbbr: 'Supervisor',
                doctorPackageDescription: 'The Doctors Package is a visit with the participation of 2 or more doctors. The doctor conducting the visit performs all activities related to the visit. The observing physician observes the visit and may engage in clinical discussion with the attending physician and the patient.',
                specialist: 'Specialist in',
                serviceTypeSelection: {
                    label: 'Visit type',
                    epilepsy: 'Epilepsy visit',
                    consultation: 'Consultation / EEG'
                },
                sorting: {
                    label: 'Sort',
                    alpha: 'Alphabetically',
                    teleconferencePriceAsc: 'Teleconference price: ascending',
                    teleconferencePriceDesc: 'Teleconference price: descending',
                    stationaryPriceAsc: 'Stationary visit price: ascending',
                    stationaryPriceDesc: 'Stationary visit price: descending',
                    bestMatch: 'Best match'
                },
                serviceGroupDescriptions: {
                    eeg_consultation: {
                        groupSelectionTitle: 'Available EEG/VEEG examinations',
                        title: 'Who performs the EEG test?',
                        description: 'The EEG test is carried out by a technician specializing in this field at the request of an epileptologist, neurologist or psychiatrist. Responsible for planning and carrying out research on all modalities in adults and children. Research is conducted in the wake-falling asleep-sleep. They can also be performed at the patient\'s home. The test takes approximately 30 minutes.',
                        bulletsTitle: 'When is an EEG test necessary?',
                        bullet1: 'The EEG test is the basic test in the diagnosis of epilepsy',
                        bullet2: 'EEG is often used to indicate the causes of sleep disorders',
                        bullet3: 'May be commissioned for head injuries',
                        bullet4: 'Other indications include visual impairment, stuttering, recurrent headaches, fainting or loss of consciousness'
                    }
                },
                serviceDescriptions: {
                    vns: {
                        title: 'What is a VNS visit?',
                        description: 'At a VNS visit, your doctor checks your current setting. Based on the history of recorded seizures, he or she selects the appropriate VNS therapy. The doctor at the visit changes the stimulation settings using a computer, configuration software, and a programming remote. The doctor can decrease or increase the current and increase or decrease the inactivity time of the device. The visit lasts 15 minutes.',
                        bulletsTitle: 'When to book a VNS appointment ?',
                        bullet1: 'When VNS therapy involves a gradual change in current parameters',
                        bullet2: 'When a patient notices a significant increase in the number of seizures',
                        bullet3: 'When a patient notices a significant deterioration in health and well-being',
                        bullet4: 'When a patient notices that the implanted stimulator is no longer functioning, i.e., no longer sending out pulses',
                        bullet5: 'Before undergoing any medical tests that may affect or be affected by the VNS device, such as magnetic resonance imaging (MRI)'
                    },
                    dietician: {
                        title: 'Who is a dietician?',
                        description: 'A specialist who graduated from higher education with a medical or nutritional (agricultural) profile. Has adequate knowledge to conduct diets in various groups of patients, and when making recommendations, takes into account, inter alia, sex, age, weight and height, diseases, including allergies, and patient preferences. So he creates an individual diet plan for each patient and supervises him. Depending on the test results or information obtained from the patient, it introduces the necessary modifications. Therefore, close cooperation with the patient and his doctor is extremely important.',
                        bulletsTitle: 'When to consult a dietician?',
                        bullet1: 'When the Neurosphery physician suggested treatment with a ketogenic diet',
                        bullet2: 'When you think that the ketogenic diet may be a treatment option in your case (NOTE - a visit to the doctor is also necessary, if you are a Neurospeha Patient, ask your doctor about this treatment option, if you are not already a Neurosphera Patient, make an appointment for visit ZERO)',
                        bullet3: 'When you are on a ketogenic diet and want to verify your current treatment (NOTE - a visit to the doctor is also necessary)',
                        bullet4: 'When you are on a ketogenic diet - a check-up visit (the dietician will inform you about the best time to sign up during the visit)',
                        bullet5: 'When you want to verify your current diet'
                    },
                    neurologist: {
                        title: 'Who is a neurologist?',
                        description: 'A specialist who observes the reactions of the patient\'s body, examines the functions of the nervous system, and also checks the reactions of the human body to stimuli. He deals with diseases of the peripheral and central nervous system. The neurologist focuses primarily on diseases which are caused by processes that destroy the human nervous system. He examines the basis of the emerging neurological diseases, makes a diagnosis and appropriate treatment. He deals with the treatment of diseases such as sciatica, degeneration of the spine, epilepsy, Parkinson\'s disease, Alzheimer\'s disease, Huntington\'s chorea, stroke and post-stroke conditions, migraines and other headaches.',
                        bulletsTitle: 'When to consult a neurologist?',
                        bullet1: 'When fainting and loss of consciousness occur',
                        bullet2: 'When memory deteriorates sharply and there are problems with concentration',
                        bullet3: 'When you have trouble sleeping',
                        bullet4: 'When there are problems with balance and coordination',
                        bullet5: 'If there is tingling in the extremities together with a disturbed sensation',
                        bullet6: 'If there is chronic pain associated with dizziness'
                    },
                    neurosurgeon: {
                        title: 'Who is a neurosurgeon?',
                        description: 'A surgeon who specializes in the diagnosis and surgical treatment of certain diseases of the nervous system (brain, spinal cord, peripheral nerves) for which pharmacological treatment has not worked and surgery should be performed.',
                        bulletsTitle: 'When to consult a neurosurgeon?',
                        bullet1: 'Occurrence of neurological problems for which pharmacological treatment has not worked, e.g. discopathy, chronic back pain and injuries, brain tumors and neoplastic lesions of the spine, hemangiomas, etc.',
                        bullet2: 'Strong and prolonged headaches and dizziness',
                        bullet3: 'Unknown cause numbness or tingling in extremities',
                        bullet4: 'Occurrence of frequent syncope',
                        bullet5: 'Presence of Unknown Cause Impairment, Vision, Hearing',
                        bullet6: 'Prevalence of limb paresis'
                    },
                    neuropsychologist: {
                        title: 'Who is a neuropsychologist?',
                        description: 'A specialist in clinical psychology, dealing with the relationship between the functioning of the brain and human behavior. The activities of the neuropsychologist are mainly directed at people with brain damage caused by various diseases, injuries or injuries. ',
                        bulletsTitle: 'When to consult a neuropsychologist?',
                        bullet1: 'When a patient is diagnosed with problems in normal functioning, such as head injuries, brain tumors, diseases that destroy nervous tissue. e.g. Alzheimer\'s or Parkinson\'s disease ',
                        bullet2: 'Memory impairment occurs',
                        bullet3: 'Problems Concentrating',
                        bullet4: 'Occurrence of speech disorders',
                        bullet5: 'Difficulty with orientation, finding a way',
                        bullet6: 'There is visual disturbance despite normal ophthalmic results',
                        bullet7: 'Mood changes, anxiety, delusions occur',
                        bullet8: 'In addictive states'
                    },
                    neurologopedist: {
                        title: 'Who is a neurologopedist?',
                        description: 'Specialist diagnosing and treating speech and communication disorders of neurological origin. The neurologopedist can work both with children who have delayed speech development or speech disorders on various backgrounds, as well as with adult patients. ',
                        bulletsTitle: 'When to consult a neurologopedist?',
                        bullet1: 'When a child is diagnosed with a neurological disorder, genetic syndrome or autism spectrum disorder',
                        bullet2: 'When a parent notices a psychomotor retardation in their child',
                        bullet3: 'When a child has speech difficulties - a 2-year-old or older child does not speak at all, speaks little or has a speech impediment',
                        bullet4: 'When a child is salivating excessively, he has trouble eating, won\'t close his mouth or breathe through his nose',
                        bullet5: 'When an adult has a speech disorder after a stroke or accident',
                        bullet6: 'When an adult has speech disorders as a result of senile dementia, Parkinson\'s disease, Alzheimer\'s disease or multiple sclerosis'
                    },
                    psychiatrist: {
                        title: 'Who is a psychiatrist?',
                        description: 'A doctor dealing with mental health problems - mental disorders and diseases, both in terms of diagnosis and further therapy. The psychiatrist may conduct pharmacological treatment and refer additional consultations to other specialties. ',
                        bulletsTitle: 'When to consult a psychiatrist?',
                        bullet1: 'When they are annoying for a long time: sadness, suffering, apathy, lack of energy, helplessness, pessimistic vision of the future',
                        bullet2: 'Onset of suicidal thoughts',
                        bullet3: 'In problems and sleep disorders',
                        bullet4: 'For anxiety disorders',
                        bullet5: 'The onset of hyperactivity, unnaturally elevated mood and confusion',
                        bullet6: 'Occurrence of concentration problems and memory impairment',
                        bullet7: 'Avoidance of Contact and Retirement',
                        bullet8: 'In the event of delusions affecting the perception of reality and hallucinations'
                    },
                    epileptologist: {
                        title: 'Who is an epileptologist?',
                        description: 'A neurologist specializing in the diagnosis and treatment of epilepsy. An epileptologist may specialize in the diagnosis and treatment of epilepsy in children or adults. In addition to selecting the therapy, the doctor coordinates the therapeutic process, referring the patient also to doctors of other specialties. ',
                        bulletsTitle: 'When to consult an epileptologist?',
                        bullet1: 'The occurrence of a sudden seizure, in which the patient loses consciousness and falls, and the muscles tense. Convulsions occur and the patient may stop breathing ',
                        bullet2: 'Sudden loss of contact with the environment occurs and the patient freezes motionless',
                        bullet3: 'Muscle cramps occur without unconsciousness',
                        bullet4: 'Muscle relaxation, unconsciousness, patient fall'
                    },
                    geneticist: {
                        title: 'Who is a geneticist?',
                        description: 'A clinical geneticist is a physician whose task is to determine whether a disease is hereditary and what mechanism led to the inheritance of an abnormality. The geneticist also determines the probability of the same defects or diseases in subsequent generations. Its purpose is also to identify family members of the sick person who may develop a hereditary disease. The role of genetics is also to inform the family about the prevention and treatment options for the disease and to provide them with genetic counseling. An important element is also the implementation of specialist treatment of a hereditary disease.',
                        bulletsTitle: 'When to consult a geneticist?',
                        bullet1: 'When there is an illness in your family with known or suspected genetic causes',
                        bullet2: 'When do you want to do the WES test',
                        bullet3: 'When you want to rule out a congenital disease that may be causing epilepsy',
                        bullet4: 'When you are planning a pregnancy and want to find out if your baby may also have epilepsy'
                    },
                    absent: {
                        title: 'What is an absent consultation?',
                        description: 'An absent consultation is a type of medical visit that a selected Neurosphera specialist carries out without the patient being present. Absent consultation is aimed at analyzing medical records (discharge cards, results of genetic tests and others) or an additional examination (EEG, MRI and others). The Neurosphery specialist will analyze the documentation, make an appropriate note and recommendations for the patient. The fact that it was held and its result will be stored in the patient\'s visit archive. NOTE: the absent consultation lasts 30 minutes and is performed without the patient\'s participation. ',
                        bulletsTitle: 'When to consult?',
                        bullet1: 'You will be directed to sign up for an absent consultation during visit 1 or later - if the doctor considers such consultation appropriate. Its purpose is to perform an additional clinical analysis that goes beyond the time frame or requires consultation with another doctor (example: it turns out, for example, that there is a need to consult a metabolic test with a selected specialist or several MRI examinations over a period of several years should be analyzed). ',
                        bullet2: 'Typically, during a 30-minute consultation, the clinical specialist has the opportunity to see two supporting tests with descriptions.'
                    },
                    metabolic: {
                        title: 'What is a metabolic consultation?',
                        description: 'Metabolic consultation concerns rare diseases - inborn defects of metabolism, which are genetic diseases. The diagnosis of metabolic diseases mainly includes numerous examinations and laboratory tests, often supported by specialist examinations.',
                        bulletsTitle: 'When to consult?',
                        bullet1: 'It is worthwhile to come for a metabolic consultation if there is a suspicion of the disease in genetic tests, or if the tests: tandem blood mass spectrometry (tandem MS, MS / MS) or urine organic acid profile (GC-MS test) are incorrect.',
                        bullet2: 'Some diseases associated with epilepsy may have a metabolic basis, e.g. pyruvate dehydrogenase (PDHD) deficiency, deficit of glucose transporter to the brain (GLUT-1 DS) or selected mitochondrial diseases.'
                    },
                    caseConference: {
                        title: 'What is case conference?',
                        description: 'A medical case conference between medical specialists and Neurosphery\'s medical partners is a collective meeting to discuss the patient\'s clinical case and determine the optimal treatment plan. A consultation is organized in situations where assistance or additional specialist knowledge is needed to achieve the best treatment results. It is a type of interdisciplinary medical conference during which knowledge and opinions are exchanged between specialists.'
                    },
                    visit0: {
                        bulletsTitle: 'What is Survey 0?',
                        bullet1: '<b>Precedes and prepares</b> 1 visit to the Neurosphery Clinical Specialist Doctor',
                        bullet2: 'It is <b>a detailed interview of your medical history</b>',
                        bullet3: 'It is run by a<b> person with medical qualifications </b>, whom we will select individually for you',
                        bullet4: '<b>Prepare for it.</b> Recall what medications have been taken, how often do seizures occur, how they proceed and what their nature is',
                        bullet5: 'Person conducting the survey 0 will compile digital documentation of your case in the form of a structured interview. Digital data organized in this way can be analyzed by our Ictal robot to suggest a diagnosis and therapy during the Visit 1',
                        bullet6: 'Duration <b>up to 60 minutes</b>',
                        bullet7: 'You can choose the type of visit - <b>online or stationary</b>'
                    },
                    visit1: {
                        bulletsTitle: 'What is Visit 1?',
                        bullet1: 'It\'s a visit to the Neurosphery Clinical Specialist of your choice',
                        bullet2: 'The doctor will see the results of Survey 0, the results of the analysis of our Ictal robot and the materials attached by you, which will allow him to effectively confirm the diagnosis and therapy',
                        bullet3: 'The doctor will offer you additional tests if necessary',
                        bullet4: 'The doctor will offer you a comprehensive care plan',
                        bullet5: 'Duration <b>up to 30 minutes</b>',
                        bullet6: 'You can choose the type of visit - <b> online or stationary</b>'
                    },
                    veegOnStandby: {
                        title: 'What is the VEEG examination in awake?',
                        description: 'VEEG is a real-time recording of synchronised EEG and Video. For a correct diagnosis, the doctor needs to know what is happening to the patient during the seizures. All details are important: in which direction the head turns, in which direction the eyeballs "run", where the seizures occur, etc. The videometric examination allows the doctor to see the patient at this crucial moment for the diagnosis of the disease. The examination is carried out in a specially prepared laboratory. The awake examination is performed during the day. The patient, according to the doctor\'s instructions, decides how long the examination should last. Patient has an armchair and a bed in the examination room at his/her disposal. The patient can use the toilet during the examination while being strapped to the EEG apparatus. After the examination, the patient receives a CD with a recorded recording. Description of the examination is included in the price.',
                        bulletsTitle: 'How to prepare for the examination?',
                        bullet1: 'The patient should have a clean head',
                        bullet2: 'No gel or hairspray should be applied to the hair before the examination',
                        bullet3: 'Take medication as prescribed by your doctor',
                        bullet4: 'Do not perform sleep deprivation without arrangements with the doctor - the patient comes in sleeping'
                    },
                    veegDuringSleep: {
                        title: 'What is a VEEG examination during sleep?',
                        description: 'VEEG is a real-time recording of synchronised EEG and Video. For a correct diagnosis, the doctor needs to know what is happening to the patient during the seizures. All details are important: in which direction the head turns, in which direction the eyeballs "run", where the seizures occur, etc. The videometric examination allows the doctor to see the patient at this crucial moment for the diagnosis of the disease. The examination is carried out in a specially prepared laboratory. The sleep examination is performed in the evening or at night. The patient decides how long the examination should last, according to the doctor\'s instructions. Patient has an armchair and a bed in the examination room at his/her disposal. The patient can use the toilet during the examination while being strapped to the EEG device. Large children and adults lie alone on the bed, small children with an attendant, the waiting room is at the disposal of the attendant. After the examination the patient receives a CD with the recorded recording. A description of the examination is included in the price.',
                        bulletsTitle: 'How to prepare for the examination?',
                        bullet1: 'The patient should have a clean head',
                        bullet2: 'No gel or hairspray should be applied to the hair before the examination',
                        bullet3: 'Take medication as prescribed by your doctor',
                        bullet4: 'Do not perform sleep deprivation without arrangements with the doctor - the patient comes in sleeping',
                        bullet5: 'It doesn\'t hurt to take your favourite pillow with you from home'
                    },
                    veegNight: {
                        title: 'What is a 12h VEEG test?',
                        description: 'VEEG is a real-time recording of synchronised EEG and Video. For a correct diagnosis, the doctor needs to know what is happening to the patient during the seizures. All details are important: in which direction the head turns, in which direction the eyeballs "run", where the seizures occur, etc. The videometric examination allows the doctor to see the patient at this crucial moment for the diagnosis of the disease. The examination is carried out in a specially prepared laboratory. The patient has an armchair and a bed at their disposal in the office. We start the examination from the moment the patient falls asleep, then we go through the individual phases and finally we record the moment of waking up. The patient has the possibility to use the toilet during the examination while remaining attached to the EEG device. Large children and adults lie alone on the bed, small children with their caregiver, a waiting room is available. After the examination the patient receives a CD with the recorded recording. A description of the examination is included in the price.',
                        bulletsTitle: 'How to prepare for the examination?',
                        bullet1: 'The patient should have a clean head',
                        bullet2: 'No gel or hairspray should be applied to the hair before the examination',
                        bullet3: 'Take medication as prescribed by your doctor',
                        bullet4: 'Do not perform sleep deprivation without arrangements with the doctor - the patient comes in sleeping',
                        bullet5: 'It doesn\'t hurt to take your favourite pillow with you from home'
                    },
                    veegDay: {
                        title: 'What is a 24-hour VEEG test?',
                        description: 'A VEEG test is a real-time recording of a synchronised EEG and Video study. For a correct diagnosis, the doctor needs to know what is happening to the patient during the seizures. All details are important: in which direction the head turns, in which direction the eyeballs "run", where the seizures occur, etc. The videometric examination allows the doctor to see the patient at this crucial moment for the diagnosis of the disease. The examination is carried out in a specially prepared laboratory. The patient has an armchair and a bed at their disposal in the office. Normally, the examination starts with a night-time examination and then we monitor the patient during the day. The patient has the possibility to use the toilet during the examination while remaining attached to the EEG apparatus. Meals are included in the price of the examination. Big children and adults lie alone on a bed, small children with an attendant, a waiting room is available. After the examination the patient receives a CD with the recorded recording. A description of the examination is included in the price.',
                        bulletsTitle: 'How to prepare for the examination?',
                        bullet1: 'The patient should have a clean head',
                        bullet2: 'No gel or hairspray should be applied to the hair before the examination',
                        bullet3: 'Take medication as prescribed by your doctor',
                        bullet4: 'Do not perform sleep deprivation without arrangements with the doctor - the patient comes in sleeping',
                        bullet5: 'It doesn\'t hurt to take your favourite pillow with you from home',
                        bullet6: 'Further details can be arranged with the technician by calling the surgery reception +48 609 463 191'
                    },
                    eegConsultation: {
                        title: 'What is an EEG examination?',
                        description: 'A standard EEG examination is carried out while the patient is awake. The technician selects a suitable cap and places electrodes on the patient\'s head. It is important for the quality of the examination that they adhere as closely as possible to the scalp. The examination itself takes about 20 minutes. Immediately after the examination the patient can return to normal activity. After the examination, the patient receives a CD with a recorded recording. Description of the examination is included in the price.',
                        bulletsTitle: 'How to prepare for the examination?',
                        bullet1: 'The patient should have a clean head',
                        bullet2: 'No gel or hairspray should be applied to the hair before the examination',
                        bullet3: 'Take medication as prescribed by your doctor',
                        bullet4: 'Do not perform sleep deprivation without arrangements with the doctor - the patient comes in sleeping'
                    },
                    sleepDisturbance: {
                        title: 'What is a sleep disturbance Consultation?',
                        description: 'Sleep disturbances can be primary (resulting from abnormalities in the sleep itself) as well as a symptom of diseases from other systems. Sometimes a psychiatric consultation is indicated. During the visit, a detailed interview will be conducted, covering the course of the night and the schedule of the day. It is worth preparing a sleep diary for such a visit, covering at least 5 days and recording any disturbing episodes occurring at night using home methods. After the consultation, it will be assessed whether the problem is physiological or not. If a sleep disorder is diagnosed, you will be offered treatment - treatment or referral to a specialist who deals with the underlying problem.',
                        bulletsTitle: 'When to consult?',
                        bullet1: 'If you are sleepy or irritable during the day',
                        bullet2: 'If you experience insomnia or excessive sleepiness caused by emotional factors',
                        bullet3: 'If you observe disturbances in the circadian rhythm of sleep and wake up',
                        bullet4: 'If You snore while you sleep'
                    },
                    regularVisitEegPackage: {
                        title: 'What is the regular visit + EEG package?',
                        description: 'EEG test – is a standard test to assess the bioelectrical activity of the brain, on the basis of which the correctness of its operation is assessed. The examination takes about 20 minutes. After the examination, the patient goes for a visit. REGULAR stationary visit - each subsequent visit. Lasts up to 30 minutes. During the visit, the doctor analyzes the performed EEG test, establishes the final diagnosis and analyzes the data collected in the system. The patient receives a CD with the recorded EEG test and recommendations from a regular visit.',
                        bulletsTitle: 'When to sign up for the package?',
                        bullet1: 'The EEG test is the basic test in the diagnosis of epilepsy',
                        bullet2: 'A patient suffering from epilepsy should regularly perform an EEG examination every year and visit an epileptologist on a regular basis',
                        bullet3: 'If you are planning a stationary visit to the doctor, it is worth performing an EEG to assess the bioelectrical activity of the brain',
                        bullet4: 'You feel no improvement or deterioration of your health, you want to talk to your doctor about changing your treatment'
                    },
                    adhdPackage: {
                        title: 'How is ADHD diagnosed?',
                        description: 'ADHD diagnostics consists of 2 meetings with a neuropsychologist, each lasting 1.5 hours, during which a clinical interview and neuropsychological tests are conducted. After the meetings, the psychologist gives an opinion and recommends who the patient should go to, whether to a neurologist (30 minutes) or a psychiatrist (45 minutes). During the appropriate visit, the patient will receive a diagnosis and recommendations for further treatment (pharmacological treatment or psychological therapy).',
                        bulletsTitle: 'When to sign up for the ADHD package?',
                        bullet1: 'If there are difficulties with organizing time and tasks. You find it difficult to start and finish projects, plan and keep order',
                        bullet2: 'If there are emotional difficulties, you are prone to mood swings, frustration, and difficulty controlling your emotions. You experience strong reactions to stressful situations',
                        bullet3: 'When you notice interpersonal difficulties in yourself. You are restless, impulsive, and have difficulty taking other people\'s perspectives into account',
                        bullet4: 'If you find it difficult to estimate the time needed to complete a task'
                    },
                    mmpi2Package: {
                        title: 'What is the MMPI-2 personality test?',
                        description: 'We make it possible to diagnose personality disorders using the most extensive and universal clinical assessment tool available in Poland - the Minnesota Multidimensional Personality Inventory (the full adaptation of the MMPI-2 was released in 2012). The test contains ten control scales that can be used to describe the reliability of the obtained result profile and justify their clinical interpretation. Control scales can also inform the diagnostician about the subject\'s motivation, level of adaptation and possible defense mechanisms.',
                        bulletsTitle: 'What is the MMPI-2 personality test?',
                        bullet1: 'The inventory itself can be used in the clinical description of personality problems, but it can also be useful in identifying people who are unsuited to perform a specific professional function. It can be used to diagnose adults who have achieved at least 6 years of formal education. The test must be conducted under the supervision of a psychologist. You should allocate approximately 90 minutes to conduct the test and complete it. When completing the test, you must refer to 567 statements by selecting TRUE/FALSE. Completing the test itself should be preceded by a meeting, approximately 50 minutes, to collect data from the interview and present the test to the person being tested. Within a week, after completing the visit and completing the test, the examined person will receive a report summarizing the obtained results.'
                    },
                    cbtPackage: {
                        title: 'What is CBT therapy for insomnia?',
                        description: 'Cognitive behavioral therapy (CBT) for insomnia is considered the first-line treatment for people struggling with insomnia, recommended by authorities such as the National Institutes of Health (NIH), the National Institute for Health and Care Excellence (NICE) and the American College of Physicians. CBT-I is an effective alternative to sleep medications, offering lasting solutions by changing sleep habits and thinking. The entire CBT-I process involves five sessions spread over six weeks, but improvement can usually be noticed earlier. Sessions can be held stationary or online. About 75% of people with chronic insomnia experience significant improvement in sleep, and 90% reduce or completely stop taking sleeping medications.',
                        bulletsTitle: 'CBT-I therapy is recommended for people who:',
                        bullet1: 'They experience chronic insomnia: Sleep problems occur at least three times a week for at least three months.',
                        bullet2: 'Have difficulty falling asleep: They spend more than 30 minutes trying to fall asleep at night.',
                        bullet3: 'Waking up at night: They regularly wake up in the middle of the night and have difficulty getting back to sleep for more than 30 minutes.',
                        bullet4: 'This makes them feel tired and irritable during the day: Insomnia affects their daily well-being and quality of life.',
                        bullet5: 'They are looking for an alternative to sleeping pills: They want to avoid addiction to pharmacotherapy and are looking for long-term, natural solutions.'
                    },
                    standardVisit: {
                        title: 'What is a regular visit?',
                        description: 'REGULAR visit – every subsequent visit. Lasts up to 30 minutes. During the visit, the doctor collects an interview and analyzes the patient\'s calendar of events. The doctor reviews the patient\'s medical documentation and may suggest additional tests. After each visit, the patient receives recommendations from the visit, a therapy plan and a plan for further care. The patient can choose the type of visit - online or stationary.',
                        bulletsTitle: 'What does a regular visit look like?',
                        bullet1: 'Discussing your current health condition - remember the date of the last attack and your well-being - complete the calendar of events',
                        bullet2: 'Getting to know the results of recent tests - if you have recently performed tests, make sure that all results are attached to the medical documentation',
                        bullet3: 'Discussion of further therapy',
                        bullet4: 'Establishing a comprehensive care plan',
                        bullet5: 'Issuing a prescription, referral, sick leave, certificate'
                    }
                },
                visitPackageDescriptions: {
                    startPackage1: 'As a new Neurosphera patient or patient\'s parent, you must have an Appointment 1 with a Clinical Specialist. It will be preceded by a Survey 0, which will allow us to collect information for effective diagnosis and therapy. All this at one affordable price.',
                    startPackage2: 'Select the Specialist doctor who will see Visit 1 and whether it will be online or stationary. Pay attention to the differences in prices and the location of the stationary visit. ',
                    startPackage3: 'Survey 0 precedes and prepares for Visit 1. It will be held with a medically qualified person of our choice. Choose the type of Survey 0 that is convenient for you (online or stationary) and its date. Remember that Survey 0 lasts up to 60 minutes.',
                    startPackage4: 'Making a payment allows us to book an appointment with a given doctor for you. Remember, you can always change the date for free! '
                },
                slotChoiceDescriptions: {
                    VISIT_1: 'Visit 1 takes place after Survey 0. The Neurosphery Clinical Specialist and the type of visit you have already selected in the previous step. The doctor will make a diagnosis and suggest a suitable therapy. He will also prepare an individual care plan. Remember, Visit 1 lasts up to 30 minutes.'
                },
                payments: {
                    directPayment: 'You can pay safely with {provider}:',
                    creditCard: 'Credit card',
                    blik: 'Blik',
                    onlineTransfer: 'Online transfer',
                    onlineApplication: 'Online application',
                    mobilePayments: 'Mobile payments',
                    payLater: 'Pay later'
                }
            },
            paymentOutcome: {
                title: 'Payment confirmation',
                loading: 'Loading payment data',
                status: {
                    title: 'Status',
                    types: {
                        pending: {
                            title: 'Payment processing in progress',
                            subtitle: 'Payment is not yet confirmed',
                            comment: 'Please wait a moment while the payment completes'
                        },
                        success: {
                            title: 'Service was payed for',
                            subtitle: 'Payment was confirmed',
                            comment: 'Thank you for the payment'
                        },
                        error: {
                            title: 'Service was not payed for',
                            subtitle: 'The payment was cancelled',
                            comment: 'Please repeat the payment process'
                        }
                    }
                },
                details: {
                    title: 'Details',
                    date: 'Date',
                    name: 'Name of service',
                    doctor: 'Doctor'
                }
            },
            visitPaymentOutcome: {
                title: 'Visit reservation',
                subtitle: 'confirmation',
                details: 'details',
                loading: 'Visit data is loading...',
                card: {
                    successTitle: 'Your visit has been paid',
                    successLabel1: 'Thank You for a payment',
                    successLabel1Package: 'Thank You! You are well cared for!',
                    successLabel2: 'You will be notified about a visit 24h before',
                    failTitle: 'Your visit has not been paid',
                    failLabel1: 'Error occurred in a payment process',
                    retryPayment: 'Retry payment',
                    toGoMessageView: 'Go to message view',
                    paymentPending: 'You payment has not been confirmed yet. It may take up to 1 minute',
                    paymentConfirmed: 'Payment has been confirmed!'
                },
                goHome: 'Go to main page',
                visitScope: 'Scope of the visit',
                scopeBullets: {
                    visit0Bullet1: '<b>Precedes and prepares</b> 1 visit to the Neurosphery Clinical Specialist Doctor',
                    visit0Bullet2: 'It is <b>a detailed interview of your medical history</b>',
                    visit0Bullet3: 'It is run by a<b> person with medical qualifications </b>, whom we will select individually for you',
                    visit0Bullet4: '<b>Prepare for it.</b> Recall what medications have been taken, how often do seizures occur, how they proceed and what their nature is',
                    visit0Bullet5: 'Person conducting the survey 0 will compile digital documentation of your case in the form of a structured interview. Digital data organized in this way can be analyzed by our Ictal robot to suggest a diagnosis and therapy during the Visit 1',
                    visit0Bullet6: 'Duration <b>up to 60 minutes</b>',
                    visit0Bullet7: 'You can choose the type of visit - <b>online or stationary</b>',
                    migrationVisitBullet1: 'Intended for Patients who were or are under our care before 2021',
                    migrationVisitBullet2: 'Verification of the patient\'s medical, biometric and personal data',
                    migrationVisitBullet3: 'Transition to a new care system',
                    visit1Bullet1: 'It\'s a visit to the Neurosphery Clinical Specialist of your choice',
                    visit1Bullet2: 'The doctor will see the results of Survey 0, the results of the analysis of our Ictal robot and the materials attached by you, which will allow him to effectively confirm the diagnosis and therapy',
                    visit1Bullet3: 'The doctor will offer you additional tests if necessary',
                    visit1Bullet4: 'The doctor will offer you a comprehensive care plan',
                    visit1Bullet5: 'Duration <b>up to 30 minutes</b>',
                    visit1Bullet6: 'You can choose the type of visit - <b> online or stationary</b>',
                    standardVisitBullet1: 'Duration from 30 to 45 minutes',
                    standardVisitBullet2: 'Determining the final diagnosis',
                    standardVisitBullet3: 'Constant analysis of data collected in the ICtal® system',
                    standardVisitBullet4: 'Ability to intervene in the event of no improvement or deterioration - advice from ICtal® or the council',
                    consultationBullet1: 'Duration from 45 to 60 minutes',
                    consultationBullet2: 'Visit with a doctor of other specialization',
                    consultationBullet3: 'Medical history',
                    consultationBullet4: 'Analysis of history of epilepsy and comorbidities',
                    consultationBullet5: 'Advice medical advice in the specialization of the Consultant Physician',
                    messageBullet1: 'Remember that a message is a short text question to which the doctor will answer as soon as possible during their working hours.',
                    messageBullet2: 'As part of the fee, you can choose to send only one message - a question. When the doctor answers, you have the option to ask another follow-up question to which the doctor will answer again and the thread will end automatically.',
                    messageBullet3: 'Attention! The doctor has the right to refuse to advise you as part of the message if he or she believes your inquiry qualifies for a regular visit. The message is not intended to help in emergencies or life-threatening situations!'
                }
            },
            neuroscreen: {
                main: {
                    title: 'The Neurosphera clinic creates a new quality in patient treatment!',
                    subtitle1: 'We effectively combine knowledge and many years of experience with the need for continuous development and improvement of our treatment methods!',
                    subtitle2: 'We have prepared a survey for you that will help you choose the right doctor and the right care plan at the Neurosphera clinic.',
                    subtitle3: 'By completing the survey, the doctor will read it before the visit and will know what problem you are reporting for.',
                    subtitle4: 'Completing the survey takes about 5 minutes.',
                    fillNeuroscreen: 'Complete the survey',
                    fillNeuroscreenDescription: 'I want to complete the survey and enter the patient\'s care plan',
                    goToReservation: 'Select a doctor',
                    goToReservationDescription: 'I don\'t want to fill out the survey, I want to choose a doctor right away',
                },
                survey: {
                    title: 'Neuroscreen - Neurosphera patient survey',
                    subtitle1: 'After completing the survey, we will tell you what visit and doctor you should make an appointment with.',
                    subtitle2: 'The information you provide here will be shared with your doctor and will help you properly diagnose and treat you.',
                    next: 'Next',
                    prev: 'Back',
                    save: 'Complete survey',
                    form: {
                        section: {
                            HEADACHES: 'Headaches',
                            MOVEMENT_ISSUES: 'Movement disorders',
                            MEMORY_ISSUES: 'Memory disorders',
                            EATING_ISSUES: 'Eating disorders',
                            CONSCIOUSNESS_ISSUES: 'Disturbances of consciousness or consciousness',
                            PSYCHOMOTOR_DEVELOPMENT_ISSUES: 'Psychomotor development disorders',
                            EPILEPSY: 'Epilepsy and seizures',
                            SPINE_ISSUES: 'Spine problems',
                            MUTIPLE_SCLEROSIS: 'Multiple sclerosis',
                            DIZZINESS: 'Dizziness',
                            AUTISM: 'Autism Spectrum',
                            OTHER_SYMPTOMS: 'Other symptoms'
                        },
                        adult: {
                            field: {
                                headachesGeneral: 'Are you experiencing headaches?',
                                headaches1: 'Do you have a constant, dull headache?',
                                headaches2: 'Are you experiencing throbbing pain, usually on one side of your head?',
                                headaches3: 'Are your headaches accompanied by sensitivity to light, sound, nausea or vomiting?',
                                headaches4: 'Do you experience visual disturbances, such as flashes of light or spots in front of your eyes, before the headache starts?',
                                headaches5: 'When you have a headache, do you experience eye redness, tearing or swelling of the eyelid on the side of the pain, or a stuffy nose?',
                                movementGeneral: 'Have you noticed tremors in your limbs, muscle stiffness or difficulty moving?',
                                movement1: 'Do you experience tremors in your hands or other parts of your body?',
                                movement2: 'Does tremor worsen at rest?',
                                movement3: 'Do you feel stiffness in your muscles that makes it difficult to start moving?',
                                movement4: 'Have you noticed a slowdown in your daily activities?',
                                movement5: 'Have you noticed any problems with walking, such as taking small steps or unexpected falls?',
                                memoryIssuesGeneral: 'Do you have difficulty remembering new information, finding words or spatial orientation?',
                                memoryIssues1: 'Do you have difficulty remembering recent events?',
                                memoryIssues2: 'Do you ever forget names, dates, or have trouble finding the right words when speaking?',
                                memoryIssues3: 'Do you increasingly need help with everyday activities such as operating home appliances, managing finances, planning?',
                                memoryIssues4: 'Do you have difficulty orienting yourself in places you have previously known?',
                                memoryIssues5: 'Do you notice changes in your behavior, such as social withdrawal, apathy, impulsivity or lack of tact?',
                                epilepsyGeneral: 'Have you ever experienced loss of consciousness, uncontrollable convulsions or brief moments of suspension?',
                                epilepsy1: 'Are you experiencing sudden, unexplained blackouts?',
                                epilepsy2: 'When you lose consciousness, do you feel confused or have difficulty immediately returning to normal activities?',
                                epilepsy3: 'Are blackouts accompanied by convulsions?',
                                epilepsy4: 'Do you experience any specific feelings or signals (e.g. "aura") before losing consciousness/convulsions?',
                                epilepsy5: 'Are there moments when you suddenly stop responding and "freeze" for a short time?',
                                spineIssuesGeneral: 'Do you experience chronic or acute back or neck pain?',
                                spineIssues1: 'Do you suffer from chronic back or neck pain?',
                                spineIssues2: 'Are you experiencing numbness in your arms or legs?',
                                spineIssues3: 'Have you noticed a loss of strength in your arms or legs?',
                                spineIssues4: 'Do you have difficulty controlling your bladder or bowels?',
                                spineIssues5: 'Does the pain radiate from the spine to other parts of the body?',
                                mutipleSclerosisGeneral: 'Have you noticed a feeling of tingling, numbness or other changes in sensory sensations that may occur in various parts of the body?',
                                mutipleSclerosis1: 'Are you experiencing unexpected periods of weakness in your limbs or difficulty walking?',
                                mutipleSclerosis2: 'Do you have problems with balance or coordination?',
                                mutipleSclerosis3: 'Do you experience sudden episodes of visual disturbances, such as double vision or blurred vision?',
                                mutipleSclerosis4: 'Are you experiencing unusual fatigue that is not related to exercise?',
                                mutipleSclerosis5: 'Do you experience muscle pain and cramps?',
                                dizzinessGeneral: 'Are you experiencing dizziness or balance problems?',
                                dizziness1: 'Does dizziness appear suddenly and without warning?',
                                dizziness2: 'Do you also feel nauseous or vomiting when you feel dizzy?',
                                dizziness3: 'Does dizziness become more severe when you change your body position, such as standing up or tilting your head?',
                                dizziness4: 'Is the occurrence of dizziness related to earaches or changes in hearing?',
                                dizziness5: 'Is dizziness accompanied by tinnitus or a feeling of fullness in the ears?',
                                otherSymptomsGeneral: 'Do you have any other symptoms that do not fit into the categories above?',
                                otherSymptoms1: 'Do you experience difficulty swallowing or frequent choking when eating?',
                                otherSymptoms2: 'Do you have trouble sleeping, such as insomnia or excessive daytime sleepiness?',
                                otherSymptoms3: 'Do you experience periodic difficulty concentrating or "brain fog" that prevents you from focusing on tasks?',
                                otherSymptoms4: 'Have you noticed your snoring starting or getting worse while sleeping?',
                                otherSymptoms5: 'Do you sometimes feel sudden weakness in one part of your body or on one side of your body?'
                            }
                        },
                        child: {
                            field: {
                                headachesGeneral: 'Does your child have headaches?',
                                headaches1: 'Does your child have a constant, dull headache?',
                                headaches2: 'Is it a throbbing pain, usually one-sided?',
                                headaches3: 'Is the pain accompanied by hypersensitivity to light and sound, nausea and vomiting?',
                                headaches4: 'Does the child experience visual disturbances, such as flashes of light or spots in front of the eyes, before the headache begins?',
                                headaches5: 'When you have a headache, do you experience eye redness, tearing or swelling of the eyelid on the side of the pain, or a stuffy nose?',
                                movementGeneral: 'Does your child have any movement disorders: tremors, stiffness, paresis?',
                                movement1: 'Does your child have tremors?',
                                movement2: 'Does this tremor occur at rest?',
                                movement3: 'Does this shaking occur during movement?',
                                movement4: 'Does your child have limb stiffness that is getting worse?',
                                movement5: 'Is your child\'s motor development not progressing or regressing?',
                                epilepsyGeneral: 'Does your child have seizures?',
                                epilepsy1: 'Are the seizures recurring?',
                                epilepsy2: 'Are seizures different or similar?',
                                epilepsy3: 'Do seizures occur while awake?',
                                epilepsy4: 'Do seizures occur during sleep (night or day)?',
                                epilepsy5: 'Are the seizures generalized?',
                                epilepsy6: 'Are the seizures focal, i.e. not of the whole body, e.g. one or two limbs or one half of the body?',
                                epilepsy7: 'Are the seizures rhythmic?',
                                consciousnessIssuesGeneral: 'Does the child suffer from disturbances of consciousness and/or loss of consciousness?',
                                consciousnessIssues1: 'Is the child suffering from impaired consciousness?',
                                consciousnessIssues2: 'Does the child experience complete loss of consciousness with a fall?',
                                consciousnessIssues3: 'Does your child experience hang-ups or thoughts?',
                                consciousnessIssues4: 'Do states of impaired consciousness last a few seconds?',
                                consciousnessIssues5: 'Do states of impaired consciousness last longer than 30 seconds?',
                                eatingIssuesGeneral: 'Does your child have an eating disorder?',
                                eatingIssues1: 'Does your child have a pathological lack of appetite?',
                                eatingIssues2: 'Does your child have pathological excessive appetite?',
                                autismGeneral: 'Does the child have features or a diagnosis of autism spectrum disorder?',
                                autism1: 'Does the child have autism spectrum disorder?',
                                autism2: 'Does the child suspect autism?',
                                psychomotorDevelopmentIssuesGeneral: 'Does the child have psychomotor development disorders?',
                                psychomotorDevelopmentIssues1: 'Is psychomotor development disorder diagnosed and confirmed by a doctor/medical center?',
                                psychomotorDevelopmentIssues2: 'Is psychomotor development disorder only suspected and not confirmed?'
                            }
                        }
                    }
                },
                rating: {
                    title: 'Thank you for completing the survey!',
                    subtitle: 'The Neuroterminal system will tell you the right doctor for your disease',
                    showMore: 'Show more',
                    diagnosis: {
                        HEADACHES: '<b>Headaches are a complex neurological problem,</b> which can take dozens of different forms and intensities. <b>These ailments can be a symptom of many diseases,</b> not only in the field of neurology, but also in other fields of medicine. It is crucial that the diagnosis and treatment of headaches be handled by a neurologist - a specialist in cephalology, a science devoted to this issue. <br/><b>The Neurosphera Center approaches the diagnosis and treatment of headaches individually and as a team.</b>',
                        MOVEMENT_ISSUES: '<b>Movement disorders are a group of neurological disorders that manifest themselves in various problems with movement.</b> These can be muscle weakness and paresis, as well as tremors, stiffness or spasticity. This wide range of symptoms is the result of damage or disorders in the functioning of the nervous system. <b>It is important to see a specialist if you experience any worrying symptoms,</b> because early diagnosis and appropriate treatment are key to improving the patient\'s quality of life. <br/><b>Neurosphera is able to precisely diagnose the cause of the problems and select appropriate treatment methods.</b>',
                        MEMORY_ISSUES: '<b>Memory disorders are often associated with dementia, where they are one of the main symptoms.</b> However, memory problems can occur at any age and be a signal of various diseases, from mild to serious. That is why it is so important to <b>see a specialist if you experience any worrying symptoms,</b> who will comprehensively assess memory functioning and diagnose potential causes. <br/><b>At the Neurosphera Center, the patient has the opportunity to choose a neuropsychology specialist who will perform appropriate tests and diagnose the cause of the disease, and a neurologist who will select the appropriate therapy for the patient.</b>',
                        EATING_ISSUES: '<b>Eating disorders are complex health problems that require a comprehensive approach.</b> Various specialists, such as dieticians, neurologists and psychiatrists, participate in their treatment. Although eating disorders are often a symptom of deeper mental or physical problems, <b>a properly selected diet and nutritional therapy can be an important element of treatment,</b> affecting both the physical and mental health of the patient. <br/><b>The Neurosphera Center offers the possibility of diagnosing the problem of eating disorders with a neurologist or dietetics specialist who will prepare a well-tailored diet for the patient.</b>',
                        CONSCIOUSNESS_ISSUES: '<b>Disorders of consciousness and awareness are conditions that should always arouse concern and prompt an urgent consultation with a neurologist.</b> They are often the first sign of a serious neurological disease or other serious health disorder. To precisely determine the cause of these ailments, <b>it is necessary to conduct additional tests, such as EEG (electroencephalography) and MRI (magnetic resonance imaging).</b> These diagnostic methods allow for the assessment of the brain\'s electrical activity and detailed visualization of its structures, which is crucial for making the correct diagnosis and implementing the appropriate treatment. <br/><b>The Neurosphera Center approaches diagnostics and therapy individually and as a team. The clinic offers the possibility of performing EEG tests.</b>',
                        PSYCHOMOTOR_DEVELOPMENT_ISSUES: '<b>Psychomotor development disorders in children are a complex clinical problem that requires a specialist and comprehensive approach.</b> Due to the variety of symptoms and potential causes, diagnosis and therapy should be conducted by a multidisciplinary team of specialists. <br/><b>The Neurosphera Center offers comprehensive care, enabling a wide range of tests and consultations with various specialists.</b> This allows for precise determination of the causes of disorders and implementation of an appropriate therapeutic program. Early diagnosis and therapy are crucial for the optimal development of the child.',
                        EPILEPSY: '<b>Treatment of epilepsy at the Neurosphera Center is our specialty.</b> We have 25 years of experience in the diagnosis and therapy of this disease, which makes us one of the leading centers in Poland. <b>Our team, consisting of 20 specialists, uses the latest diagnostic tools,</b> including advanced systems based on artificial intelligence, to provide patients with comprehensive care. Thanks to this, we can effectively diagnose and treat even the most complex cases of epilepsy, offering our patients the highest standard of medical care.',
                        SPINE_ISSUES: 'Spine and back pain is a problem that affects many people and can have various causes. <b>This is a condition that requires a comprehensive approach, combining the knowledge and experience of neurologists and neurosurgeons.</b> <br/><b>At the Neurosphera Center, we have a specialized team that deals with the diagnosis and treatment of this type of ailments.</b> Thanks to the extensive knowledge and experience of our specialists and the availability of modern diagnostic methods, we are able to precisely determine the cause of the pain and propose optimal treatment. <b>Our multidisciplinary approach allows for a comprehensive assessment of the patient and the development of an individual therapeutic plan.</b>',
                        MUTIPLE_SCLEROSIS: '<b>Multiple sclerosis is a chronic neurological disease, the course of which is very individual and can vary significantly between patients.</b> Diagnosing MS is often a challenge due to the variety of symptoms, which can mimic other diseases. <b>Effective treatment of multiple sclerosis requires a specialized approach and the cooperation of many specialists.</b> Therapy should be tailored to the individual needs of each patient and include both pharmacological and non-pharmacological treatment. <b>A team of experienced neurologists is essential for proper diagnosis and comprehensive therapy for people with MS.</b>',
                        DIZZINESS: '<b>Dizziness is a condition that is most often a symptom, not an independent disease.</b> It can be caused by various factors, both laryngological (e.g. balance disorders) and neurological (e.g. damage to the central nervous system). <br/><b>Patients with dizziness often report to a neurologist, and we at the Neurosphera Center are perfectly prepared to accept such patients.</b> We offer comprehensive diagnostics that allow for precise determination of the cause of the symptoms. <b>In some cases, it may be necessary to consult a neurosurgeon, especially when the dizziness is related to spinal problems.</b> Thanks to our multidisciplinary approach, we are able to provide our patients with effective help.',
                        AUTISM: '<b>Autism spectrum disorder is a complex neurodevelopmental disorder that includes difficulties with social communication, social interactions, and restricted and repetitive patterns of behavior.</b> Autism can take many forms, from mild to severe, and often co-occurs with other disorders. <b>Both typical and atypical childhood autism require a specialist diagnosis and a comprehensive therapeutic approach.</b> <br/><b>At the Neurosphera Center, we have an experienced team of specialists who deal with the diagnosis and treatment of autism and its complications.</b> We offer a wide range of services, tailored to the individual needs of each patient.',
                        OTHER_SYMPTOMS: '<b>Neurological symptoms can be extremely diverse and often ambiguous.</b> In addition to more characteristic symptoms, such as numbness or balance disorders, patients also report weakness, pain, sleep problems, irritability and difficulty concentrating. <b>This wide range of symptoms can make precise diagnosis a challenge, as many of them may indicate various neurological conditions, and sometimes even disorders with a different cause.</b> <br/><b>The Neurosphera Center has specialized equipment and an experienced team,</b> thanks to which we are able to conduct accurate diagnostics and indicate the cause of even the most vague neurological symptoms.'                    }
                }
            }
        },

        error: {
            LOGIN_CRED_MISSING: 'Login and password are required',
            LOGIN_INVALID_CRED: 'Invalid user name or password',
            PASS_AND_CONF_NOT_EQUAL: 'Password and confirmation differ',
            AUTHORIZATION: 'unauthorized access to resource, routing to login page',
            WRONG_PROFILE: 'Login failed because of wrong profile selected'
            //TODO: kody bledow z backendu
            //TODO: ogarnac obsluge defaultowego bledu, np generyczne 500
        },

        validation: {
            required: 'Field is required',
            alphanumeric: 'Must only contain letters and digits',
            email: 'Invalid e-mail address',
            invalidRange: 'Invalid range',
            nip: 'Invalid NIP number',
            regon: 'Invalid REGON number',
            pesel: 'Invalid PESEL number',
            max: 'Up to {length} characters allowed',
            min: 'At least {length} characters required',
            passNonMatching: 'Passwords do not match',
            maxFileSize: 'File should have less then {size} MB',
            pwz: 'Invalid PWZ number',
            zusCertificate: 'Allowed file extension is .pfx',
            password: {
                lowercase: 'Lowercase letter required',
                uppercase: 'Uppercase letter required',
                minimumChar: '{length} characters minimum',
                numericChar: 'One number required',
                specialChar: "Special character required, characters available are !@$\\-_.*"
            }
        },
        office: {
            phone: '+48 609 463 191',
            phoneHref: 'tel:+48609463191',
            mail: 'biuro@neurosphera.pl',
            mailHref: 'mailto:biuro@neurosphera.pl'
        },
        appointWidget: {
            appoint: {
                title: "Schedule a visit",
                visitTypeLabel: "Visit type",
                visitLocationLabel: "Location",
                doctorListDisclaimer: "Final price depends on visit type",
                visitTypeListDisclaimer: "Final price depends on visit type",
                doctorListHeader: "Choose doctor",
                visitTypeListHeader: "Choose visit type",
                visitLocationListHeader: "Choose visit location",
                doctorLabel: "Doctor",
                startingPricePrefix: "from",
                clearSelection: "Clear selection",
                visitOrder: "Is this your first visit to this specialist?",
                visitOrderFirst: "Yes",
                visitOrderNext: "No",
                appointment: {
                    selectHeader: {
                        START_PACKAGE: 'Schedule Visit 0 and Visit 1 - epilepsy',
                        ADHD_PACKAGE: 'Schedule ADHD package visits',
                        REGULAR_VISIT_EEG_PACKAGE: 'Schedule regular epilepsy visit and EEG',
                        MIGRATION_VISIT: 'Schedule migration visit',
                        STANDARD_VISIT: 'Schedule regular visit - epilepsy',
                        NEUROSURGEON_CONSULTATION: 'Schedule a neurosurgical consultation',
                        NEUROPSYCHOLOGIST_CONSULTATION: 'Schedule a neuropsychological consultation',
                        NEUROLOGOPEDIST_CONSULTATION: 'Schedule a speech therapist (neurological) consultation',
                        PSYCHIATRIST_CONSULTATION: 'Schedule a psychiatric consultation',
                        GENETICIST_CONSULTATION: 'Schedule a geneticist consultation',
                        EPILEPTOLOGIST_CONSULTATION: 'Schedule an epileptologist consultation',
                        EEG_CONSULTATION: 'Schedule EEG examination',
                        NEUROLOGIST_CONSULTATION: 'Schedule a neurological consultation',
                        DIETICIAN_CONSULTATION: 'Schedule a dietician consultation',
                        VNS_CONSULTATION: 'Schedule a VNS visit',
                        ABSENT_CONSULTATION: 'Schedule consultation in absentia',
                        METABOLIC_CONSULTATION: 'Schedule a metabolic consultation',
                        SLEEP_DISTURBANCE_CONSULTATION: 'Schedule a sleep disturbance consultation'
                    },
                    description: {
                        START_PACKAGE: 'Visit 0 is a free visit to collect a detailed interview on the patient\'s medical history, preceding Visit 1. During Visit 1, a diagnosis, therapy and a comprehensive care plan for the next year will be made. Visit 0 is approx. 1 hour, Visit 1 - 30 minutes; both can take place on-site or online.',
                        ADHD_PACKAGE: 'The package includes 2 visits with a psychologist (each lasting 1.5 hours) and a visit with a neurologist or a visit with a psychiatrist. The package is addressed to people who notice:',
                        REGULAR_VISIT_EEG_PACKAGE: 'The package includes an EEG test and a regular stationary visit. The package is addressed to current Neurosphery patients who have already completed Visit 1 or Migration Visit. Sign up for a package if:',
                        MMPI2_PACKAGE: 'We make it possible to diagnose personality disorders using the most extensive and universal clinical assessment tool available in Poland - the Minnesota Multidimensional Personality Inventory (the full adaptation of the MMPI-2 was released in 2012).',
                        CBT_PACKAGE: 'Cognitive behavioral therapy (CBT) for insomnia is considered the first-line treatment for people struggling with insomnia, recommended by authorities such as the National Institutes of Health (NIH), the National Institute for Health and Care Excellence (NICE) and the American College of Physician.',
                        MIGRATION_VISIT: 'Migration visit',
                        STANDARD_VISIT: 'The regular epileptological visit is intended for current Neurosphera patients who have already had Visit 1 or Migration Visit.',
                        NEUROSURGEON_CONSULTATION: 'Consultation is recommended in the case of chronic neurological problems, such as:',
                        NEUROPSYCHOLOGIST_CONSULTATION: 'Indicated in the case of:',
                        NEUROLOGOPEDIST_CONSULTATION: 'Speech therapist (neurological) consultation',
                        PSYCHIATRIST_CONSULTATION: 'A visit for people struggling with mental health problems, including:',
                        GENETICIST_CONSULTATION: 'Indicated in the case of:',
                        EPILEPTOLOGIST_CONSULTATION: 'Consultation - epileptologist',
                        EEG_CONSULTATION: 'Basic examination in the diagnosis of epilepsy, as well as:',
                        NEUROLOGIST_CONSULTATION: 'Indicated in the case of:',
                        DIETICIAN_CONSULTATION: 'Visit for people:',
                        VNS_CONSULTATION: 'VNS visit',
                        ABSENT_CONSULTATION: 'Consultation in absentia',
                        METABOLIC_CONSULTATION: 'Indicated in the case of inborn defects of metabolism. Some metabolic diseases can contribute to the onset of epilepsy. It is worth evaluating if there is a suspicion of the disease in genetic tests.',
                        SLEEP_DISTURBANCE_CONSULTATION: 'Indicated in the case of:'
                    },
                    bulletPoints: {
                        ADHD_PACKAGE: [
                            "Difficulty Organizing",
                            "Underestimating time",
                            "interpersonal difficulties",
                            "Emotional difficulties"
                        ],
                        REGULAR_VISIT_EEG_PACKAGE: [
                            "You haven't had an EEG done in the last year",
                            "You are planning a regular stationary visit with an Egyptologist",
                            "You feel a lack of improvement or deterioration of your health"
                        ],
                        DIETICIAN_CONSULTATION: [
                            "for whom the ketogenic diet is indicated by physician or is of interest",
                            "who are using a ketogenic diet and want to review their current treatment",
                            "they want to verify their current diet"
                        ],
                        EEG_CONSULTATION: [
                            "to indicate the causes of sleep disorders",
                            "in head injuries and recurring headaches",
                            "fainting or loss of consciousness"
                        ],
                        NEUROLOGIST_CONSULTATION: [
                            "fainting and loss of consciousness, problems with balance and coordination",
                            "chronic headaches combined with dizziness",
                            "deterioration of memory and trouble concentrating",
                            "tingling in the extremities with a disturbed sensation"
                        ],
                        PSYCHIATRIST_CONSULTATION: [
                            "depression",
                            "anxiety disorders",
                            "sleep problems",
                            "unnaturally elevated mood and hyperactivity"
                        ],
                        GENETICIST_CONSULTATION: [
                            "family history of diseases with genetic causes",
                            "the exclusion of congenital disease as a cause of epilepsy",
                            "assessment of the risk of a child having epilepsy in the event of a planned pregnancy",
                            "before performing the WES test"
                        ],
                        NEUROSURGEON_CONSULTATION: [
                            "chronic pain and injuries of the spine",
                            "brain tumors and neoplastic changes in the spine",
                            "numbness, tingling and paresis of the limbs",
                            "severe headaches and dizziness, visual and hearing disturbances",
                            "consultation of magnetic resonance imaging of the head"
                        ],
                        NEUROPSYCHOLOGIST_CONSULTATION: [
                            "problems in normal functioning that have arisen as a result of, for example, head injuries, brain tumors, diseases that destroy nervous tissue",
                            "impairment of memory or concentration",
                            "speech, orientation or wayfinding difficulties",
                            "visual disturbances despite normal ophthalmic results"
                        ],
                        SLEEP_DISTURBANCE_CONSULTATION: [
                            "daytime sleepiness or irritability",
                            "sleepiness or insomnia caused by emotional factors",
                            "disruption of the daily rhythm of sleep and waking up",
                            "snoring while sleeping"
                        ]
                    },
                    detailsHeader: {
                        VISIT_0: 'Visit 0 - epilepsy',
                        MIGRATION_VISIT: 'Migration visit',
                        VISIT_1: 'Visit 1 - epilepsy',
                        START_PACKAGE: 'Visit 0 and Visit 1',
                        ADHD_PACKAGE: 'ADHD package',
                        REGULAR_VISIT_EEG_PACKAGE: 'Regular epilepsy visit and EEG',
                        STANDARD_VISIT: 'Regular visit - epilepsy',
                        NEUROSURGEON_CONSULTATION: 'Consultation - neurosurgeon',
                        NEUROPSYCHOLOGIST_CONSULTATION: 'Consultation - neuropsychologist',
                        NEUROLOGOPEDIST_CONSULTATION: 'Consultation - speech therapist (neurologist)',
                        PSYCHIATRIST_CONSULTATION: 'Consultation - psychiatrist',
                        GENETICIST_CONSULTATION: 'Consultation - geneticist',
                        EPILEPTOLOGIST_CONSULTATION: 'Consultation - epileptologist',
                        EEG_CONSULTATION: 'Schedule an EEG test',
                        NEUROLOGIST_CONSULTATION: 'Consultation - neurologist',
                        DIETICIAN_CONSULTATION: 'Consultation - dietitian',
                        VNS_CONSULTATION: 'VNS visit',
                        ABSENT_CONSULTATION: 'Consultation in absentia',
                        METABOLIC_CONSULTATION: 'Metabolic consultation',
                        SLEEP_DISTURBANCE_CONSULTATION: 'Sleep disturbance consultation'
                    },
                    details: {
                        VISIT_0: 'Visit 0 is a preparatory visit during which an in-depth, very detailed interview is conducted. The visit lasts approx. 1 hour. Visit 0 must be conducted prior to Visit 1.',
                        MIGRATION_VISIT: '',
                        VISIT_1: 'At Visit 1, the diagnosis is confirmed and a comprehensive care plan is established. The visit lasts approx. 30 minutes.',
                        STANDARD_VISIT: 'Duration of the visit is about 30 minutes.',
                        NEUROSURGEON_CONSULTATION: 'The duration of the consultation is from 15 up to 45 minutes.',
                        NEUROPSYCHOLOGIST_CONSULTATION: 'The duration of the consultation is about 1 hour.',
                        NEUROLOGOPEDIST_CONSULTATION: 'Consultation - speech therapist (neurologist)',
                        PSYCHIATRIST_CONSULTATION: 'The duration of the consultation is approx. 45 minutes.',
                        GENETICIST_CONSULTATION: 'The duration of the consultation is approx. 45 minutes.',
                        EPILEPTOLOGIST_CONSULTATION: 'Consultation - epileptologist',
                        EEG_CONSULTATION: 'Schedule an EEG evaluation',
                        NEUROLOGIST_CONSULTATION: 'Schedule in case of:<ul><li>migraine or headaches</li><li>spine pain, sensory disturbance</li><li>loss of consciousness</li><li>stroke, memory impairment</li><li>other neurological ailment</li></ul></br>Duration of consultation is approx. 30 minutes.',
                        DIETICIAN_CONSULTATION: 'Duration of consultation is about 1 hour.',
                        VNS_CONSULTATION: 'VNS visit',
                        ABSENT_CONSULTATION: 'Consultation in absentia',
                        METABOLIC_CONSULTATION: 'Duration of consultation is approx. 30 minutes.',
                        SLEEP_DISTURBANCE_CONSULTATION: 'Duration of consultation is approx. 45 minutes.'
                    }
                },
                remoteVisitDescription: "Remote visit via Neuroterminal. A device with a camera and microphone and connected to the Internet is required",
                details: "Details",
                visitHeader: {
                    VISIT_0: 'Visit 0',
                    MIGRATION_VISIT: 'Migration visit',
                    VISIT_1: 'Visit 1',
                    START_PACKAGE: 'Visit 0 and Visit 1',
                    ADHD_PACKAGE: 'ADHD package',
                    REGULAR_VISIT_EEG_PACKAGE: 'Regular epilepsy visit and EEG',
                    STANDARD_VISIT: 'Regular visit',
                    NEUROSURGEON_CONSULTATION: 'Consultation - neurosurgeon',
                    NEUROPSYCHOLOGIST_CONSULTATION: 'Consultation - neuropsychologist',
                    NEUROLOGOPEDIST_CONSULTATION: 'Consultation - speech therapist (neurologist)',
                    PSYCHIATRIST_CONSULTATION: 'Consultation - psychiatrist',
                    GENETICIST_CONSULTATION: 'Consultation - geneticist',
                    EPILEPTOLOGIST_CONSULTATION: 'Consultation - epileptologist',
                    EEG_CONSULTATION: 'Schedule an EEG test',
                    NEUROLOGIST_CONSULTATION: 'Consultation - neurologist',
                    DIETICIAN_CONSULTATION: 'Consultation - dietitian',
                    VNS_CONSULTATION: 'VNS visit',
                    ABSENT_CONSULTATION: 'Consultation in absentia',
                    METABOLIC_CONSULTATION: 'Metabolic consultation',
                    SLEEP_DISTURBANCE_CONSULTATION: 'Sleep disturbance consultation'
                },
                instruction: {
                    START_PACKAGE: "If you do not see the date that is right for you - try to choose a different doctor, type of visit or earlier date of the Visit 0"
                },
                visitPriceLabel: "The price of the visit",
                showAllSlots: "show more hours",
                hideSlots: "collapse",
                nextLabel: "Next",
                validationErrorHeader: "To go to the summary, select:",
                ok: "Ok",
                validationError: {
                    slot: "Date",
                    location: "Location",
                    doctor: "Doctor",
                    service: "Visit type"
                },
                chooseLabel: "Choose",
                doctorMissingLabel: "Doctor",
                locationMissingLabel: "Visit type",
                andLabel: "and",
                unavailableDoctors: "Doctors unavailable for the selected date, type and / or type of visit",
                ADULT: "Adult",
                CHILD: "Child",
                visitNotAvailable: "We are sorry, but the date you have selected <b>{visitName}</b> is not available. Go back and choose a different date",
                visitNotSaved: "The visit has not been registered yet. Go back to the summary to complete the appointment booking process."
            },
            summary: {
                "title": "Summary",
                "grossPrice": "Total Amount",
                "availableOnlinePayments": "We accept online payments",
                "transfer": "Bank Transfer",
                "installments": "0% Installments",
                "payment": "Payment",
                "paymentDescription": "To proceed with the payment, please provide your email address. We will send you a summary and a payment link.",
                "descriptionExistingAccount": "If you have an account in Neuroterminal, please provide the email address you use for login.",
                "makeReservations": "Book Appointments",
                "makeReservation": "Book Appointment",
                "unexpectedError": "An unexpected error occurred while trying to schedule the appointment. Please try again."
            },
            thanku: {
                "title": "Thank You!",
                "emailHasBeenSent": "We have sent a message with a summary and a payment link to the provided email address:",
                "finalizeAppointment": "Go to your email and <b>complete the appointment scheduling process</b> by clicking on the sent payment link. You have until <b>{deadline}</b> to complete the appointment scheduling process.",
                "resendEmail": "Didn't receive the email? Check your Spam folder or",
                "resendEmailLink": "Resend Email",
                "resendEmailDone": "Email has been resent",
                "backToNeurosphera": "Back to neurosphera.pl"
            },
            exitPoll: {
                title: "Why do you want to leave?",
                description: "Your reply is very important to us! Thanks to it, we will be able to improve our services.",
                SERVICE: "I have not found the service that interests me",
                DATE: "No suitable visit dates",
                PRICE: "I don't like the price of the service",
                OTHER: "Other",
                sendResponse: "Send a reply",
                back: "Return to the site",
                whatService: "What service were you looking for?",
                enterMessage: "Please enter your message",
                reasonMissing: "Please provide a reason for your resignation"
            },
            main: {
                exitPopup: {
                    description: "Are you sure you want to terminate the appointment process? Your selections will not be saved.",
                    confirm: "Yes, I want to leave",
                    decline: "No, go back to making an appointment"
                }
            }
        },
        server: {
            episode: {
                description: "Seizure episode added during visit"
            },
            generatedepisode: {
                description: "Seizure episode added during visit"
            }
        },

        dates: {
            today: 'today',
            tomorrow: 'tomorrow'
        },

        enums: Enums.en,
        serverErrors: ServerErrors.en
    },

    formats: {
        currency: {
            style: 'currency', currency: 'USD'
        }
    }
}
